
import { useContext } from 'react';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import InputGroup from 'react-bootstrap/InputGroup';
import RowContext from '../RowContext';
const classNames = require('classnames');

interface ISelectCellProps extends IContentCellProps {
	selectOptions?: Record<string, string>[];
	hasAll?: boolean;
}

class SelectCellProps extends ContentCellProps implements ISelectCellProps {
	selectOptions?: Record<string, string>[];
	hasAll: boolean;

	constructor(options:ISelectCellProps) {
		super(options);
		this.component = SelectCell;
		this.selectOptions = options.selectOptions;
		this.hasAll = options.hasAll ?? false;
	}
}

interface ISelectCellReactProps extends IContentCellReactProps<SelectCellProps> {
	fetchedData?: any,
}

export function SelectCell({cellProps, values, onChange, includeLabel, fetchedData}:ISelectCellReactProps) {
	const rowContext = useContext(RowContext);
	const selectOptions = cellProps.selectOptions ?? fetchedData?.selectOptions[cellProps.tag];
	const realRow = rowContext["id"] === values["id"] ? rowContext : values;

	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					{cellProps.readOnly &&
						<p>
							{selectOptions?.find((element:any) => element.tag.toString() === realRow[cellProps.tag].toString())?.value}
						</p>}
					{!cellProps.readOnly &&
						<select
							className="form-control w-100"
							name={cellProps.tag}
							value={values[cellProps.tag]}
							onChange={onChange}
						>
							{cellProps.hasAll &&
								<option value="">All</option>
							}
							{selectOptions?.map((option:any) => {
								return (
									<option key={option.tag} value={option.tag}>
										{option.value}
									</option>
								);
							})}
						</select>}
				</InputGroup>
			</div>
		</div>
	);
}

export default SelectCellProps;
