
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import IdCellProps from 'TableCard/cells/IdCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import SelectCellProps from 'TableCard/cells/SelectCell';
import ServerRoutes from 'serverRoutes';
import FormatUtils from 'utils/formatUtils';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "serviceorder-list",
	//title: "Select Service Order",
	serverRoute: ServerRoutes.SERVICE_ORDER_LIST,
	schema: Schema.ServiceOrder,
	readOnly: true,
	canAdd: false,
	reverse: true,
	leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	primaryKey: Schema.ServiceOrder.ID,
	payloadTags: [ // For "delete".
		Schema.ServiceOrder.ID,
	],
	toImportFormatter: {
		[Schema.ServiceOrder.UPDATED]: (value:string) => FormatUtils.formatDateMMDDYYYY(value),
		[Schema.ServiceOrder.DUE_DATE]: (value:string) => FormatUtils.formatDateTimeForHtml(value),
		[Schema.ServiceOrder.WARNING]: (value:string) => value ? "warning.svg" : "",
	},
	sortKey: Schema.ServiceOrder.UPDATED,
	sortDirection: "DESC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.ServiceOrder.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrder.COMPLEX_NAME,
							label: Schema.ServiceOrder.COMPLEX_NAME_LABEL,
							badges: [
								{
									tag: Schema.ServiceOrder.CALLED_STATUS,
									translate: (value:string) => value === '1' ? "Needs Call" : "",
								},
								{
									tag: Schema.ServiceOrder.PRIORITY,
									filter: (value:string) => value !== "100",
									translate: (value:string) => Schema.ServiceOrder.VERBOSE_PRIORITIES_MAP[value],
									badgeStyle: (value:string) => value === '90' ? "info" : "danger",
								}
							],
							percentWidth: 100,
						}),
					],
					[
						new SelectCellProps({
							tag: Schema.ServiceOrder.MASTER_STATUS,
							label: Schema.ServiceOrder.MASTER_STATUS_LABEL,
							percentWidth: 100,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrder.UPDATED,
							label: Schema.ServiceOrder.UPDATED_LABEL,
							fixedWidth: TableConfig.DATE_WIDTH_WITH_WARNING,
							image: Schema.ServiceOrder.WARNING,
							cellContainerClasses: {"align-right": true},
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.ServiceOrder.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrder.COMPLEX_NAME,
							label: Schema.ServiceOrder.COMPLEX_NAME_LABEL,
							badges: [
								{
									tag: Schema.ServiceOrder.CALLED_STATUS,
									translate: (value:string) => value === '1' ? "Needs Call" : "",
								},
								{
									tag: Schema.ServiceOrder.PRIORITY,
									filter: (value:string) => value !== "100",
									translate: (value:string) => Schema.ServiceOrder.VERBOSE_PRIORITIES_MAP[value],
									badgeStyle: (value:string) => value === '90' ? "info" : "danger",
								}
							],
							percentWidth: 100,
						}),
						new SelectCellProps({
							tag: Schema.ServiceOrder.MASTER_STATUS,
							label: Schema.ServiceOrder.MASTER_STATUS_LABEL,
							fixedWidth: 125,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrder.UPDATED,
							label: Schema.ServiceOrder.UPDATED_LABEL,
							fixedWidth: TableConfig.DATE_WIDTH_WITH_WARNING,
							image: Schema.ServiceOrder.WARNING,
							cellContainerClasses: {"align-right": true},
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
