
import TableConfig from 'TableCard/classes/tableConfig';
import FormatUtils from 'utils/formatUtils';
import { ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema');

export enum SubCellType {
	RADIO,
	CHECKBOX,
	STATIC,
	IMAGE,
}

enum StaticCheckMark {
	NONE,
	RED,
	GREEN,
}

interface SubCellOptions {
	type?: SubCellType;
	label?: string;
	classes?: Record<string, boolean>;
	styles?: Record<string, string>;
	resource?: string;
	staticCheckMark?: (serviceOrder: ImportRow) => StaticCheckMark;
	labelFn?: (serviceOrder: ImportRow) => string;
	visibleFn?: (serviceOrder: ImportRow) => boolean;
	tag?: string;
	value?: string | number;
}

export class SubCell {
	type: SubCellType;
	label: string;
	classes: Record<string, boolean>;
	styles: Record<string, string>;
	resource: string;
	staticCheckMark: (serviceOrder: ImportRow) => StaticCheckMark;
	labelFn?: (serviceOrder: ImportRow) => string;
	visibleFn?: (serviceOrder: ImportRow) => boolean;
	tag: string;
	value: string | number;

	constructor(options:SubCellOptions) {
		this.type = options.type ?? SubCellType.STATIC;
		this.label = options.label ?? "";
		this.classes = options.classes ?? {};
		this.styles = options.styles ?? {};
		this.resource = options.resource ?? "";
		this.staticCheckMark = options.staticCheckMark ?? (() => StaticCheckMark.NONE);
		this.labelFn = options.labelFn;
		this.visibleFn = options.visibleFn;
		this.tag = options.tag ?? "";
		this.value = options.value ?? "";
	}

	setType(type:SubCellType) {
		this.type = type;
	}

	setLabel(label:string) {
		this.label = label;
	}

	setResource(resource:string) {
		this.resource = resource;
	}

	addClass(c:string) {
		this.classes[c] = true;
	}

	setStyle(style:string, value:string) {
		this.styles[style] = value;
	}

}

interface ProgressCellOptions {
	classes?: Record<string, boolean>;
	styles?: Record<string, string>;
	subCells?: SubCell[];
	prerequisite?: (serviceOrder:ImportRow) => boolean;
}

export class ProgressCell {
	classes: Record<string, boolean>;
	styles: Record<string, string>;
	subCells: SubCell[];
	prerequisite?: (serviceOrder:ImportRow) => boolean;

	constructor(options:ProgressCellOptions) {
		this.classes = options.classes || {};
		this.styles = options.styles || {};
		this.subCells = options.subCells || [];
		this.prerequisite = options.prerequisite;
	}

	pushSubCell(subCell:SubCell) {
		this.subCells.push(subCell);
	}

	addClass(c:string) {
		this.classes[c] = true;
	}

	setStyle(style:string, value:string) {
		this.styles[style] = value;
	}
}

const ARROW_WIDTH = 40;

function cellWidth(cellsPerRow: number): string {
	return `calc((100% - ${(cellsPerRow - 1) * ARROW_WIDTH}px - ${2 * TableConfig.NARROW_EDGE_WIDTH}px) / ${cellsPerRow})`;
}

// Gets an array of raw cells from which the page will be constructed.
function getProgressCells(masterStatus:string):ProgressCell[] {
	return [
		new ProgressCell({
			subCells: [
				new SubCell({
					type: SubCellType.RADIO,
					label: "Have All Parts",
					tag: Schema.ServiceOrder.PART_ORDER_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.HAVE_ALL_PARTS,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Need To Order Parts",
					tag: Schema.ServiceOrder.PART_ORDER_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.NEED_PARTS,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Parts Ordered",
					tag: Schema.ServiceOrder.PART_ORDER_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.PARTS_ORDERED,
				}),
			],
		}),
		new ProgressCell({
			subCells: [
				new SubCell({
					type: SubCellType.CHECKBOX,
					label: "Work Finished",
					tag: Schema.ServiceOrder.WORK_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.WORK_INCOMPLETE,
				}),
			],
		}),
		new ProgressCell({
			subCells: [
				new SubCell({
					type: SubCellType.RADIO,
					label: "Free",
					tag: Schema.ServiceOrder.PAYMENT_EXPECTATION,
					value: Schema.ServiceOrder.ProgressConstants.FREE,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Paid Work",
					tag: Schema.ServiceOrder.PAYMENT_EXPECTATION,
					value: Schema.ServiceOrder.ProgressConstants.CHARGED,
				}),
			],
		}),
		new ProgressCell({
			prerequisite:
				function(serviceOrder:ImportRow) {
					return serviceOrder[Schema.ServiceOrder.PAYMENT_EXPECTATION]?.toString() === Schema.ServiceOrder.ProgressConstants.CHARGED;
				},
			subCells: [
				new SubCell({
					label: "Invoiced",
					staticCheckMark:
						function(serviceOrder:ImportRow) {
							return serviceOrder[Schema.ServiceOrder.TOTAL]?.toString() !== "0.00" ? StaticCheckMark.GREEN : StaticCheckMark.RED;
						},
					value: Schema.ServiceOrder.ProgressConstants.UNBILLED,
				}),
				new SubCell({
					visibleFn:
						function(serviceOrder:ImportRow) {
							return serviceOrder[Schema.ServiceOrder.TOTAL]?.toString() !== "0.00";
						},
					labelFn:
						function(serviceOrder:ImportRow) {
							return "Total: " + FormatUtils.formatDollars(serviceOrder[Schema.ServiceOrder.TOTAL]);
						},
				}),
			],
		}),
		new ProgressCell({
			prerequisite:
				function(serviceOrder:ImportRow) {
					return serviceOrder[Schema.ServiceOrder.PAYMENT_EXPECTATION]?.toString() === Schema.ServiceOrder.ProgressConstants.CHARGED
						&& masterStatus !== Schema.ServiceOrder.ProgressConstants.REFUND_DUE;
				},
			subCells: [
				new SubCell({
					label: "Paid",
					staticCheckMark:
						function(serviceOrder:ImportRow) {
							return serviceOrder[Schema.ServiceOrder.TOTAL] !== "0.00"
								&& serviceOrder[Schema.ServiceOrder.BALANCE] === "0.00" ? StaticCheckMark.GREEN : StaticCheckMark.RED;
						},
					value: Schema.ServiceOrder.ProgressConstants.UNPAID,
				}),
				new SubCell({
					visibleFn:
						function(serviceOrder:ImportRow) {
							return serviceOrder[Schema.ServiceOrder.BALANCE] !== "0.00";
						},
					labelFn:
						function(serviceOrder:ImportRow) {
							return "Balance: $" + serviceOrder[Schema.ServiceOrder.BALANCE];
						},
				}),
			],
		}),
		new ProgressCell({
			prerequisite:
				function(serviceOrder:ImportRow) {
					return serviceOrder[Schema.ServiceOrder.PAYMENT_EXPECTATION]?.toString() !== Schema.ServiceOrder.ProgressConstants.CHARGED
						&& masterStatus === Schema.ServiceOrder.ProgressConstants.REFUND_DUE;
				},
			subCells: [
				new SubCell({
					tag: Schema.ServiceOrder.MASTER_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.REFUND_DUE,
					labelFn:
						function(serviceOrder:ImportRow) {
							return "Refund: " + FormatUtils.formatDollars(serviceOrder[Schema.ServiceOrder.PAYMENTS]);
						},
				}),
			],
		}),
		new ProgressCell({
			prerequisite:
				function(serviceOrder:ImportRow) {
					return serviceOrder[Schema.ServiceOrder.PAYMENT_EXPECTATION]?.toString() === Schema.ServiceOrder.ProgressConstants.CHARGED
						&& masterStatus === Schema.ServiceOrder.ProgressConstants.REFUND_DUE;
				},
			subCells: [
				new SubCell({
					tag: Schema.ServiceOrder.MASTER_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.REFUND_DUE,
					labelFn:
						function(serviceOrder:ImportRow) {
							return "Refund: " + FormatUtils.formatDollars(serviceOrder[Schema.ServiceOrder.REFUND]);
						},
				}),
			],
		}),
		new ProgressCell({
			subCells: [
				new SubCell({
					type: SubCellType.RADIO,
					label: "Not Picked Up",
					tag: Schema.ServiceOrder.ITEM_LOCATION_STATUS,
					value: Schema.ServiceOrder.ProgressConstants.IN_STORE,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Picked Up",
					tag: Schema.ServiceOrder.ITEM_LOCATION_STATUS,
					value: 1,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Nothing To Pick Up",
					tag: Schema.ServiceOrder.ITEM_LOCATION_STATUS,
					value: 2,
				}),
				new SubCell({
					type: SubCellType.RADIO,
					label: "Recycled",
					tag: Schema.ServiceOrder.ITEM_LOCATION_STATUS,
					value: 3,
				}),
			],
		}),
		new ProgressCell({
			subCells: [
				new SubCell({
					type: SubCellType.CHECKBOX,
					label: "Cancelled",
					tag: Schema.ServiceOrder.CANCELLED,
					value: Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED,
				}),
			],
		}),
		new ProgressCell({
			prerequisite:
				function(serviceOrder:ImportRow) {
					return serviceOrder[Schema.ServiceOrder.CANCELLED]?.toString() !== '1';
				},
			subCells: [
				new SubCell({
					label: "Completed",
					staticCheckMark:
						function(serviceOrder:ImportRow) {
							return masterStatus === Schema.ServiceOrder.ProgressConstants.COMPLETE ? StaticCheckMark.GREEN : StaticCheckMark.RED;
						},
					value: Schema.ServiceOrder.ProgressConstants.COMPLETE,
				}),
			],
		}),
	];
}

// Breaks the cells up into rows, does internal styling of each cell.
function createPreliminaryRows(serviceOrder:ImportRow, masterStatus:string, cellsPerRow:number) {
	const allProgressCells = getProgressCells(masterStatus);
	let index = 0;
	let added = 0;
	let rowProgressCells:ProgressCell[] = [];
	let progressRows:ProgressCell[][] = [];
	let isOkay = true; // This is used to partition cells between those that appear before a cell that blocks progress, and after, so I can style "completed cells differently."
	for (;;) {
		if (index === allProgressCells.length) {
			progressRows.push(rowProgressCells);
			break;
		}
		let progressCell = allProgressCells[index++];
		if (!progressCell.prerequisite || progressCell.prerequisite(serviceOrder)) {
			if (added++ % cellsPerRow === 0 && rowProgressCells.length) {
				progressRows.push(rowProgressCells);
				rowProgressCells = [];
			}
			progressCell.setStyle("width", cellWidth(cellsPerRow));
			progressCell.addClass("progress-panel__cell");
			progressCell.addClass("my-2");
			// Internal styling.
			let colorClass;
			for (const subCell of progressCell.subCells) {
				if (subCell.visibleFn && !subCell.visibleFn(serviceOrder)) {
					continue;
				}
				if (subCell.labelFn) {
					subCell.label = subCell.labelFn(serviceOrder);
				}
				if (subCell.type === SubCellType.STATIC) {
					subCell.addClass("progress-panel--static");
					switch (subCell.staticCheckMark(serviceOrder)) {
						case StaticCheckMark.GREEN:
							subCell.addClass("progress-panel--indicator-on");
							break;
						case StaticCheckMark.RED:
							subCell.addClass("progress-panel--indicator-off");
							break;
						default:
							break;
					}
				}
				if (subCell.value.toString() === masterStatus) {
					switch (subCell.value) {
						case Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED:
							colorClass = "progress-panel--cancelled";
							break;
						case Schema.ServiceOrder.ProgressConstants.COMPLETE:
							colorClass = "progress-panel--finished";
							break;
						default:
							colorClass = "progress-panel--stuck";
							isOkay = false;
							break;
					}
				}
			}
			if (colorClass) {
				progressCell.addClass(colorClass);
			} else if (isOkay) {
				progressCell.addClass("progress-panel--okay");
			}
			// Internal styling over.
			rowProgressCells.push(progressCell);
		}
	}
	return progressRows;
}

// Deals with the arrow crap, and also snakes the cells (they go left to right, and then on the next row right to left).
function createFinalRows(progressRows:ProgressCell[][], serviceOrder:ImportRow, masterStatus:string, cellsPerRow:number) {
	let finalRows:ProgressCell[][] = [];
	for (let rowIndex = 0; rowIndex < progressRows.length; rowIndex++) {
		let cells = progressRows[rowIndex];
		let newCells = [];
		for (let colIndex = 0; colIndex < cellsPerRow; colIndex++) {
			if (colIndex < cells.length) {
				let subCells = [];
				let width;
				if (colIndex !== 0) {
					subCells.push(new SubCell({
						type: SubCellType.IMAGE,
						resource: `/images/${(rowIndex & 1) ? 'left' : 'right'}.svg`,
					}));
					width = ARROW_WIDTH;
				} else {
					if (rowIndex & 1 || rowIndex !== 0) {
						subCells.push(new SubCell({
							type: SubCellType.IMAGE,
							resource: `/images/${(rowIndex & 1) ? 'right' : 'left'}-up.svg`,
						}));
					}
					width = TableConfig.NARROW_EDGE_WIDTH;
				}
				const progressCell = new ProgressCell({
					classes: {
						"progress-panel__arrow": true,
					},
					styles: {
						width: `${width}px`,
					},
					subCells: subCells,
				});
				if (rowIndex & 1) { // Snakiness.
					newCells.unshift(progressCell);
					newCells.unshift(cells[colIndex]);
				} else {
					newCells.push(progressCell);
					newCells.push(cells[colIndex]);
				}
			} else {
				const arrow = new ProgressCell({
					styles: {
						width: `${ARROW_WIDTH}px`,
					},
				});
				const blank = new ProgressCell({
					styles: {
						width: cellWidth(cellsPerRow),
					},
				})
				if (rowIndex & 1) { // Snakiness.
					newCells.unshift(arrow);
					newCells.unshift(blank);
				} else {
					newCells.push(arrow);
					newCells.push(blank);
				}
			}
		}
		let subCells = [];
		if (rowIndex < progressRows.length - 1) {
			subCells.push(new SubCell({
				type: SubCellType.IMAGE,
				resource: `/images/${(rowIndex & 1) ? 'left' : 'right'}-down.svg`,
			}));
		}
		const progressCell = new ProgressCell({
			styles: {
				width: `${TableConfig.NARROW_EDGE_WIDTH}px`,
			},
			subCells: subCells,
		});
		if (rowIndex & 1) { // Snakiness.
			newCells.unshift(progressCell);
		} else {
			newCells.push(progressCell);
		}
		finalRows.push(newCells);
	}
	return finalRows;
}

//const util = require('util');

function getProgressRows(serviceOrder:ImportRow, masterStatus:string, breakpoint:string) {
	const cellsPerRow = {xs: 1, sm: 1, md: 2, lg: 3, xl: 3}[breakpoint] ?? 1;
	const progressRows = createPreliminaryRows(serviceOrder, masterStatus, cellsPerRow);
	const finalRows = createFinalRows(progressRows, serviceOrder, masterStatus, cellsPerRow);
	return finalRows;
}

export default getProgressRows;
