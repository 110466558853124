
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import CellProps from 'TableCard/classes/cellProps';
import { ImportRow } from 'hooks/useFieldManager';

export abstract class ContentCellProps extends CellProps implements IContentCellProps {
	tag: string;
	label?: string;
	required?: boolean;
	placeholder?: string;
	tooltip?: string;
	component?: any;
	isStatic?: boolean;
	rotated: boolean;
	default?: any;
	type: "left-edge" | "right-edge" | "cell";

	constructor(options:IContentCellProps) {
		super(options);
		this.tag = options.tag;
		this.label = options.label;
		this.required = options.required ?? false;
		this.placeholder = options.placeholder || (options.required ? "Required" : undefined);
		this.tooltip = options.tooltip;
		this.component = options.component;
		this.isStatic = options.readOnly;
		this.rotated = options.rotated ?? false;
		this.default = options.default;
		this.type = "cell";
	}

}

export default ContentCellProps;
