
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import DatetimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import FormatUtils from 'utils/formatUtils';
const classNames = require('classnames');

interface IDatetimePickerCellProps extends IContentCellProps {
}

export class DatetimePickerCellProps extends ContentCellProps implements IDatetimePickerCellProps {
	constructor(options:IDatetimePickerCellProps) {
		super(options);
		this.component = DatetimePickerCell;
	}
}

interface IDatetimePickerCellReactProps extends IContentCellReactProps<IDatetimePickerCellProps> {
}

export function DatetimePickerCell({cellProps, values, onChange, includeLabel}:IDatetimePickerCellReactProps) {
	function onDateChanged(value:any) {
		onChange({target: {name: cellProps.tag, value: FormatUtils.dateToHtml(value)}})
	}

	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					<DatetimePicker
						className="form-control"
						name={cellProps.tag}
						value={values[cellProps.tag] ? new Date(values[cellProps.tag]) : ""}
						onChange={onDateChanged}
					/>
				</InputGroup>
			</div>
		</div>
	);
}

export default DatetimePickerCellProps;
