
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

interface IRadioCellProps extends IContentCellProps {
	options: {value:string, label:string}[];
}
class RadioCellProps extends ContentCellProps {
	options: {value:string, label:string}[];

	constructor(options:IRadioCellProps) {
		super(options);
		this.component = RadioCell;
		this.options = options.options;
	}
}

interface IRadioCellReactProps extends IContentCellReactProps<IRadioCellProps> {
}

export function RadioCell({cellProps, values, onChange, includeLabel}:IRadioCellReactProps) {
	function onRadioChange(event:React.ChangeEvent<HTMLInputElement>) {
		event.target.value = values[cellProps.tag].toString() !== '0' ? "0" : "1";
		onChange(event);
	}
	
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100 d-flex flex-row flex-grow-1">
				{cellProps.label && includeLabel &&
					cellProps.label
				}
				{cellProps.options.map((option:any, index:number) => {
					return (
						<div key={index} className={classNames("form-check-inline", {
							"ms-4": index === 0,
						})}>
							<label className="form-check-label">
								<input
									className="form-check-input"
									type="radio"
									name={cellProps.tag}
									value={option.value}
									checked={values[cellProps.tag] === option.value}
									onChange={onChange}
								/>
								<span className="ms-1">{option.label}</span>
							</label>
						</div>
					);
				})}
			</div>
		</div>
	)
}

export default RadioCellProps;
