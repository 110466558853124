
import React, { useState, useEffect, useMemo } from 'react';
import TableCard from 'TableCard';
import StatusBlock from 'views/components/StatusBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import 'bootstrap-daterangepicker/daterangepicker.css';
import useFetch, { FetchedSelectOptions, FetchOptions, FetchMode } from 'hooks/useFetch';
import ServiceOrderItemListTableConfig from './serviceOrderItemListTableConfig';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import ServerRoutes from 'serverRoutes';
import { importSelectOptions } from 'hooks/useFieldManager'
const Schema = require('portal-schema');

interface ServiceOrderItemListProps {
	onRowClick: any;
}

function ServiceOrderItemList({onRowClick}:ServiceOrderItemListProps) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [fetchedData, setFetchedData] = useState({});
	const [hasAdvancedOptions, setHasAdvancedOptions] = useState(false);
	const [query, setQuery] = useState({
		[Schema.Item.ITEM_NAME]: "",
		[Schema.Item.RETAIL]: "",
		[Schema.Item.MODEL]: "",
		[Schema.Item.NOTES]: "",
		[Schema.Item.DESCRIPTION]: "",
		[Schema.Item.SUBCATEGORY]: "",
		[Schema.Item.SERIAL]: "",
	});

	const fetchOptions = useMemo(():FetchOptions => {
		return {
			url: ServerRoutes.SERVICE_ORDER_ITEM_LIST_INFO.getUrl(),
		}
	}, []);

	useEffect(() => {
		setFetchOptions(fetchOptions);
	}, [setFetchOptions, fetchOptions]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	interface FetchedServiceOrderListResponse {
		data: {
			selectOptions: FetchedSelectOptions;
		}
	}

	function responseCallback(response:FetchedServiceOrderListResponse) {
		//console.warn('ServiceOrderItemList responseCallback data=', response.data)
		if (!response.data.selectOptions) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		setFetchedData(response.data);
	}

	function pendingCallback(value:boolean) {
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function onChange(e:any) {
		setQuery(prevQuery => {
			return {...prevQuery, [e.target.name]: e.target.value};
		});
	}

	//console.warn(ServiceOrderItemListTableConfig);

	return (
		<>
			{!pending &&
				<Row>
					<Col lg={12}>
						<Card style={{marginBottom: "0.5rem"}}>
							<Card.Body>
								{!loadingMessage && fetchedData &&
									<>
										<Row>
											<Col md={12}>
												<div className="d-flex flex-row flex-grow-1">
													<h5>Filter Items and Services</h5>
													<button className="ms-auto btn btn-primary btn-sm" onClick={() => setHasAdvancedOptions((prev) => !prev)}>{hasAdvancedOptions ? "Hide" : "Show"}</button>
												</div>
											</Col>
										</Row>
										<Collapse in={hasAdvancedOptions}>
											<Row>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.ITEM_NAME,
															label: Schema.Item.ITEM_NAME_LABEL + " Contains",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.RETAIL,
															label: Schema.Item.RETAIL_LABEL + " Range",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.MODEL,
															label: Schema.Item.MODEL_LABEL + " Contains",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.NOTES,
															label: Schema.Item.NOTES_LABEL + " Contains",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.DESCRIPTION,
															label: Schema.Item.DESCRIPTION_LABEL + " Contains",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<SelectCell
														cellProps={new SelectCellProps({
															label: "Category",
															tag: Schema.Item.SUBCATEGORY,
															hasAll: true,
														})}
														values={query}
														onChange={onChange}
														fetchedData={fetchedData}
														includeLabel={true}
													/>
												</Col>
												<Col lg={6}>
													<InputCell
														cellProps={new InputCellProps({
															tag: Schema.Item.SERIAL,
															label: Schema.Item.SERIAL_LABEL + " Contains",
															percentWidth: 100,
														})}
														onChange={onChange}
														values={query}
														includeLabel={true}
													/>
												</Col>
											</Row>
										</Collapse>
									</>}
								<StatusBlock id={16} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
							</Card.Body>
						</Card>
					</Col>
				</Row>}
			<TableCard
				className="arrow-list"
				tableConfig={ServiceOrderItemListTableConfig}
				onRowClick={onRowClick}
				query={query}
			/>
		</>
	);
}

export default ServiceOrderItemList;
