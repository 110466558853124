
import React, { Fragment, useContext, useState } from 'react';
import InvoicePaymentList from './invoice/InvoicePaymentList';
import InvoiceItemList from './invoice/InvoiceItemList';
import ServiceOrderContext from './ServiceOrderContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import api from 'api';
import ServerRoutes from 'serverRoutes';
import AuthStorage from 'authStorage';
import useResizeObserver from "use-resize-observer";

import invoiceStaticOneTableConfig from 'views/ServiceOrder/invoice/invoiceStaticOneTableConfig';
import invoiceStaticTwoTableConfig from 'views/ServiceOrder/invoice/invoiceStaticTwoTableConfig';
import invoiceStaticThreeTableConfig from 'views/ServiceOrder/invoice/invoiceStaticThreeTableConfig';
import StaticTableRow from 'TableCard/StaticTableRow';
import DebugStorage from 'debugStorage';

const Schema = require('portal-schema')

interface InvoicePageProps {
	serviceOrderFields: any;
	onServiceOrderChanged: any;
	onFetchSaveInvoice: any;
	onFetchLoadInvoice: any;
	refetchCounter: number;
}

/*const STATIC_CONFIG:ContentCellProps[] = [
	new InputTooltipCellProps({
		tag: Schema.ServiceOrder.DISCOUNT,
		postfix: '%',
		tooltip: "Percent discount applied to the whole order.",
	}),
	new InputCellProps({
		tag: Schema.ServiceOrder.TAX_RATE,
		postfix: '%',
	}),
]*/

function InvoicePage({serviceOrderFields, onServiceOrderChanged, onFetchSaveInvoice, onFetchLoadInvoice, refetchCounter}:InvoicePageProps) {
	const {serviceOrderContext, } = useContext(ServiceOrderContext);
	const [breakpoint, setBreakpoint] = useState("sm");

	// https://stackoverflow.com/questions/58798867/react-js-pdfkit
	const onClickPdf = async (e:any) => {
		const authStorage = AuthStorage.get();
		const rs = await api.post(ServerRoutes.REFRESH_TOKEN.getUrl(), {
			[Schema.RefreshToken.REFRESH_TOKEN]: authStorage.refreshToken,
			[Schema.RefreshToken.EMPLOYEE_ID]: authStorage.user?.[Schema.Employee.ID]
		});
		const { jwtToken } = rs.data;
		AuthStorage.set({...authStorage, jwtToken: jwtToken});
		window.open(`http://localhost:8080${ServerRoutes.SERVICE_ORDER_INVOICE_PDF.getUrl()}/${serviceOrderFields[Schema.ServiceOrder.ID]}?jwt=${jwtToken}`, "_blank", "noreferrer");
	};

	////useEffect(() => { console.log('invoicepage mounted')}, [])
	////console.log('InvoicePage render');

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ width, height }) => {
			width = width ?? 0;
			let breakpoint;
			if (width >= 1200) {
				breakpoint = "xl";
			} else if (width >= 992) {
				breakpoint = "lg";
			} else if (width >= 768) {
				breakpoint = "md";
			} else if (width >= 576) {
				breakpoint = "sm";
			} else {
				breakpoint = "xs";
			}
			setBreakpoint(breakpoint);
		},
	});
	
	return (
		<Fragment>
			{DebugStorage.hasShowBreakpoints() &&
				<p>{`Size = ${breakpoint}`}</p>
			}
			<Row ref={ref}>
				<Col lg={12} className="d-flex">
					<Button className="ms-auto w-auto ms-2 my-auto" variant="primary" onClick={onClickPdf}>
						Open Invoice PDF
					</Button>
				</Col>
			</Row>
			<InvoiceItemList serviceOrderId={serviceOrderContext.loadedServiceOrderId} onSaved={onFetchSaveInvoice} onFetched={onFetchLoadInvoice} refetchCounter={refetchCounter} />
			<StaticTableRow
				tableConfig={invoiceStaticOneTableConfig}
				row={serviceOrderFields}
				onChange={onServiceOrderChanged}
				breakpoint={breakpoint}
				classes={{
					"search-results": true,
					"invoice-rows": true,
				}}
			/>
			<InvoicePaymentList serviceOrderId={serviceOrderContext.loadedServiceOrderId} onSaved={onFetchSaveInvoice} onFetched={onFetchLoadInvoice} />
			<StaticTableRow
				tableConfig={invoiceStaticTwoTableConfig}
				row={serviceOrderFields}
				onChange={onServiceOrderChanged}
				breakpoint={breakpoint}
				classes={{
					"search-results": true,
					"invoice-rows": true,
				}}
			/>
			{serviceOrderFields[Schema.ServiceOrder.REFUND] !== "0.00" &&
				<StaticTableRow
					tableConfig={invoiceStaticThreeTableConfig}
					row={serviceOrderFields}
					onChange={onServiceOrderChanged}
					breakpoint={breakpoint}
					classes={{
						"search-results": true,
						"invoice-rows": true,
					}}
				/>
			}
		</Fragment>
	)
}

export default InvoicePage;
