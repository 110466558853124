
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import TickboxCellProps from 'TableCard/cells/TickboxCell';
import IdCellProps from 'TableCard/cells/IdCell';
import ServerRoutes from 'serverRoutes';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "locations",
	title: "Locations",
	serverRoute: ServerRoutes.LOCATIONS,
	schema: Schema.Location,
	readOnly: false,
	canAdd: true,
	primaryKey: "id",
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return importRow({
			[Schema.Location.ID]: null,
			[Schema.Location.TAG]: null,
			[Schema.Location.NAME]: null,
			[Schema.Location.ADDRESS1]: null,
			[Schema.Location.ADDRESS2]: null,
			[Schema.Location.CITY]: null,
			[Schema.Location.STATE]: null,
			[Schema.Location.ZIP]: null,
			[Schema.Location.PHONE]: null,
			[Schema.Location.IS_DEFAULT]: 0,
		}, TABLE_CONFIG.toImportFormatter);
	},
	payloadTags: [
		Schema.Location.ID,
		Schema.Location.TAG,
		Schema.Location.NAME,
		Schema.Location.ADDRESS1,
		Schema.Location.ADDRESS2,
		Schema.Location.CITY,
		Schema.Location.STATE,
		Schema.Location.ZIP,
		Schema.Location.PHONE,
		Schema.Location.IS_DEFAULT,
	],
	sortKey: Schema.Location.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Location.ID,
						}),
						new InputCellProps({
							tag: Schema.Location.TAG,
							label: Schema.Location.TAG_LABEL,
							percentWidth: 40,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.NAME,
							label: Schema.Location.NAME_LABEL,
							percentWidth: 60,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.ADDRESS1,
							label: Schema.Location.ADDRESS1_LABEL,
							percentWidth: 60,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS2,
							label: Schema.Location.ADDRESS2_LABEL,
							percentWidth: 40,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.CITY,
							label: Schema.Location.CITY_LABEL,
							percentWidth: 65,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.STATE,
							label: Schema.Location.STATE_LABEL,
							percentWidth: 35,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.ZIP,
							label: Schema.Location.ZIP_LABEL,
							percentWidth: 35,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.PHONE,
							label: Schema.Location.PHONE_LABEL,
							percentWidth: 65,
							required: true,
						}),
						new TickboxCellProps({
							tag: Schema.Location.IS_DEFAULT,
							label: Schema.Location.IS_DEFAULT_LABEL,
							fixedWidth: TableConfig.CHECK_COL_WIDTH,
							rotated: true,
							cellContainerClasses: {"align-right" : true},
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Location.ID,
						}),
						new InputCellProps({
							tag: Schema.Location.TAG,
							label: Schema.Location.TAG_LABEL,
							percentWidth: 40,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.NAME,
							label: Schema.Location.NAME_LABEL,
							percentWidth: 60,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.ADDRESS1,
							label: Schema.Location.ADDRESS1_LABEL,
							percentWidth: 37,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS2,
							label: Schema.Location.ADDRESS2_LABEL,
							percentWidth: 26,
						}),
						new InputCellProps({
							tag: Schema.Location.CITY,
							label: Schema.Location.CITY_LABEL,
							percentWidth: 37,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.STATE,
							label: Schema.Location.STATE_LABEL,
							percentWidth: 28,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ZIP,
							label: Schema.Location.ZIP_LABEL,
							percentWidth: 28,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.PHONE,
							label: Schema.Location.PHONE_LABEL,
							percentWidth: 44,
							required: true,
						}),
						new TickboxCellProps({
							tag: Schema.Location.IS_DEFAULT,
							label: Schema.Location.IS_DEFAULT_LABEL,
							fixedWidth: TableConfig.CHECK_COL_WIDTH,
							rotated: true,
							cellContainerClasses: {"align-right" : true},
						}),
					],
				]),
			"lg": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Location.ID,
						}),
						new InputCellProps({
							tag: Schema.Location.TAG,
							label: Schema.Location.TAG_LABEL,
							percentWidth: 20,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.NAME,
							label: Schema.Location.NAME_LABEL,
							percentWidth: 30,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS1,
							label: Schema.Location.ADDRESS1_LABEL,
							percentWidth: 30,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS2,
							label: Schema.Location.ADDRESS2_LABEL,
							percentWidth: 20,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Location.CITY,
							label: Schema.Location.CITY_LABEL,
							percentWidth: 30,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.STATE,
							label: Schema.Location.STATE_LABEL,
							percentWidth: 20,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ZIP,
							label: Schema.Location.ZIP_LABEL,
							percentWidth: 20,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.PHONE,
							label: Schema.Location.PHONE_LABEL,
							percentWidth: 30,
							required: true,
						}),
						new TickboxCellProps({
							tag: Schema.Location.IS_DEFAULT,
							label: Schema.Location.IS_DEFAULT_LABEL,
							fixedWidth: TableConfig.CHECK_COL_WIDTH,
							rotated: true,
							cellContainerClasses: {"align-right" : true},
						}),
					],
				]),
			"xl": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Location.ID,
						}),
						new InputCellProps({
							tag: Schema.Location.TAG,
							label: Schema.Location.TAG_LABEL,
							percentWidth: 10,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.NAME,
							label: Schema.Location.NAME_LABEL,
							percentWidth: 15,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS1,
							label: Schema.Location.ADDRESS1_LABEL,
							percentWidth: 15,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ADDRESS2,
							label: Schema.Location.ADDRESS2_LABEL,
							percentWidth: 10,
						}),
						new InputCellProps({
							tag: Schema.Location.CITY,
							label: Schema.Location.CITY_LABEL,
							percentWidth: 15,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.STATE,
							label: Schema.Location.STATE_LABEL,
							percentWidth: 10,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.ZIP,
							label: Schema.Location.ZIP_LABEL,
							percentWidth: 10,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.Location.PHONE,
							label: Schema.Location.PHONE_LABEL,
							percentWidth: 15,
							required: true,
						}),
						new TickboxCellProps({
							tag: Schema.Location.IS_DEFAULT,
							label: Schema.Location.IS_DEFAULT_LABEL,
							fixedWidth: TableConfig.CHECK_COL_WIDTH,
							rotated: true,
							cellContainerClasses: {"align-right" : true},
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
