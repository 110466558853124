
import React, { Fragment, useState,  useEffect, useContext } from 'react';
import CancelButton from 'assets/cancel.png';
import ClearButton from 'assets/clear.png';
import DeleteButton from 'assets/delete.png';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import LeftEdge from 'TableCard/cells/LeftEdge';
import RightEdge from 'TableCard/cells/RightEdge';
import useFetch from 'hooks/useFetch';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { IdCell } from 'TableCard/cells/IdCell';
import useFieldManager, { importRow, exportRow, ImportRow, ExportRow } from 'hooks/useFieldManager';
import TableConfig from 'TableCard/classes/tableConfig';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import { IElementsCellProps } from 'TableCard/interfaces/IElementsCellProps';
import RowContext from './RowContext';
const classNames = require('classnames');

interface TableRowProps {
	tableConfig: TableConfig,
	row: ImportRow,
	isAddRow?: boolean,
	toggleAddRow?: any,
	intercell?: boolean,
	fetchedData?: any,
	fetch?: any,
	onRowClick?: any,
	getAddedRowClasses: (row:Record<string, string>) => Record<string, boolean>,
	onSaved?: any,
	onDeleted?: any,
	isLast?: boolean,
	first?: boolean,
	breakpoint: any,
	refetchCounter?: number,
}

function TableRow({
	tableConfig,
	row,
	isAddRow,
	toggleAddRow,
	intercell,
	fetchedData,
	fetch,
	onRowClick,
	getAddedRowClasses,
	onSaved,
	onDeleted,
	isLast,
	first,
	breakpoint,
	refetchCounter,
}:TableRowProps) {
	const [savedRefetchCounter, setSavedRefetchCounter] = useState(refetchCounter);
	// As currently implemented, this is the row that was originally passed into here, which is a blank row if this is the add row.
	const [originalRow, ] = useState(row);
	const [setFieldManagerActions, fields, setFields, changed] = useFieldManager(row);
	const [isRowOpen, setIsRowOpen] = useState((isAddRow && isLast) || tableConfig.tableRowMultiMode !== TableConfig.TableRowMultiMode.CLOSED);
	const [updatingMessage, setUpdatingMessage] = useState("");
	const [savedMessage, setSavedMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [setSaveOptions] = useFetch(savedCallback, pendingCallback, savingCallback, errorMessageCallback);
	const [setDeleteOptions] = useFetch(deletedCallback, pendingCallback, deletingCallback, errorMessageCallback);
	const [setAddOptions] = useFetch(addedCallback, pendingCallback, addingCallback, errorMessageCallback);
	const [showDeleteModal, setDeleteModalShow] = useState(false);
	const [file, setFile] = useState({});
	const rowContext = useContext(RowContext);

	// This forces a refresh from props.row after the TableRow data has been refreshed.
	useEffect(() => {
		if (savedRefetchCounter !== refetchCounter) {
			setFields(row);
			setFieldManagerActions({accept: true});
			setSavedRefetchCounter(refetchCounter);
			setFile({});
			setErrorMessage("");
		}
	}, [refetchCounter, row, savedRefetchCounter, setFieldManagerActions, setFields]);

	interface FetchedTableRowSaveResponse {
		data: {
			row: ExportRow;
		}
	}

	//console.count(`TableRow ${tableConfig.tag}`);
	function savedCallback(response:FetchedTableRowSaveResponse) {
		if (!response.data || !response.data.row) {
			console.log('Malformed saved data: ', response);
			throw new Error("Malformed data returned from server after save.");
		}
		onSaved && onSaved(response.data);
		setFields(importRow(response.data.row, tableConfig.toImportFormatter));
		//console.log('savedCallback Fetch =', response.data);
		setFieldManagerActions({accept: true});
		
		setSavedMessage('Saved.');
		const timer = setTimeout(() => {
			setSavedMessage("");
			clearTimeout(timer);
		}, 1500);
		setFile({});
	}

	function pendingCallback(value:boolean) {
	}

	function savingCallback(value:boolean) {
		setUpdatingMessage(value ? 'Saving...' : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value)
	}

	interface FetchedTableRowDeletedResponse {
		data: any;
	}

	function deletedCallback(response:FetchedTableRowDeletedResponse, config:any) {
		if (!response.data) {
			console.log('Malformed deleted response data: ', response);
			throw new Error("Malformed data returned from server after delete.");
		}
		if (!config.data) {
			console.log('Malformed delete config data: ', config);
			throw new Error("Malformed data returned from server after delete.");
		}
		//console.log('delete response', response, config);
		onDeleted && onDeleted(response.data, config.data);
		setSavedMessage("Deleted successfully, re-fetching.");
		const timer = setTimeout(() => {
			setSavedMessage("");
			clearTimeout(timer);
			fetch();
		}, 1500);
	}

	function deletingCallback(value:boolean) {
		setUpdatingMessage(value ? 'Deleting...' : "");
	}

	const handleDeleteModalClose = () => {
		setDeleteModalShow(false);
	};

	function onDelete() {
		setDeleteOptions({
			url: tableConfig.url,
			data: exportRow(fields, tableConfig.payloadTags, tableConfig.toExportFormatter),
			method: 'delete',
		});
		setDeleteModalShow(false);
	}

	interface FetchedTableRowAddedResponse {
		data: any;
	}

	function addedCallback(response:FetchedTableRowAddedResponse) {
		if (!response.data) {
			console.log('Malformed added data: ', response);
			throw new Error("Malformed data returned from server after add.");
		}
		onSaved && onSaved(response.data);
		setSavedMessage("Added successfully, re-fetching.");
		setFields(importRow(originalRow, tableConfig.toImportFormatter));
		setFieldManagerActions({accept: true});
		const timer = setTimeout(() => {
			setSavedMessage("");
			clearTimeout(timer);
			fetch();
			if (toggleAddRow) {
				toggleAddRow();
			}
		}, 1500);
		setFile({});
	}

	function addingCallback(value:boolean) {
		setUpdatingMessage(value ? 'Adding...' : "");
	}

	/*function onIdClick(e:any, value:boolean) {
		setIsRowOpen(value);
		e.stopPropagation();
	}*/

	function onChange(event:any) {
		setFields(prev => ({...prev, [event.target.name]: event.target.value}));
		setFieldManagerActions({update: {[event.target.name]: event.target.value}});
		setErrorMessage("");
		setUpdatingMessage("");
		setSavedMessage("");
		if (event.target.files?.length > 0) {
			setFile(prev => ({...prev, [event.target.name]: event.target.files[0]}));
		}
	}

	function onSave(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		const data = {...exportRow(fields, tableConfig.payloadTags, tableConfig.toExportFormatter), ...file};
		setSaveOptions({
			url: tableConfig.url,
			data: data,
			method: 'put',
			useMultiPart: Object.keys(file).length !== 0,
		});
	}

	function onAdd(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		const data = {...exportRow(fields, tableConfig.payloadTags, tableConfig.toExportFormatter), ...file};
		setAddOptions({
			url: tableConfig.url,
			data: data,
			method: 'post',
			useMultiPart: Object.keys(file).length !== 0,
		});
	}

	// This may not even work.
	function isClear() {
		for (const tag of tableConfig.clearTags) {
			if (fields[tag]) {
				return false;
			}
		}
		return true;
	}
	
	// This may not even work.
	function clearHandler(e:any) {
		e.stopPropagation();
		const newRow = {...fields};
		for (const tag of tableConfig.clearTags) {
			newRow[tag] = originalRow[tag];
		}
		setFields(newRow);
		setFieldManagerActions({update: newRow});
		setErrorMessage("");
		setUpdatingMessage("");
		setSavedMessage("");
	}

	function onCancel(e:any) {
		e.stopPropagation();
		setFieldManagerActions({revert: true});
		if (toggleAddRow) {
			toggleAddRow();
		}
		setErrorMessage("");
		setUpdatingMessage("");
		setSavedMessage("");
		setFile({});
	}

	function isColRowVisible(rowCellRow:ContentCellProps[]) {
		//console.log('isColRowVisible =', rowCellRow)
		for (const cellProps of rowCellRow) {
			if (cellProps.type === "cell") {
				if (!cellProps.isStatic) {
					//console.log('true 1');
					return true;
				}
				if (cellProps.tag && fields[cellProps.tag] !== "") {
					//console.log('true 2');
					return true;
				}
				if ((cellProps as IElementsCellProps).elements?.find((element) => fields[element.tag] !== "")) {
					//console.log('true 3');
					return true;
				}
			}
		}
		//console.log('false');
		return false;
	}

	function canRowToggle() {
		if (tableConfig.tableRowMultiMode === TableConfig.TableRowMultiMode.STATIC) {
			return false;
		}
		for (let i = 1; i < tableConfig.breakpoints[breakpoint].rowCellRows.length; i++) {
			const rowCellRow = tableConfig.breakpoints[breakpoint].rowCellRows[i];
			if (isAddRow || isColRowVisible(rowCellRow)) {
				return true;
			}
		}
		return false;
	}

	const canThisRowToggle = canRowToggle();

	return (
		<Form>
			{showDeleteModal &&
				<Modal centered show={showDeleteModal} onHide={handleDeleteModalClose}>
					<Modal.Header closeButton>
						<Modal.Title>{`Delete ${tableConfig.schema.THING}`}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{`Confirm deletion of ${tableConfig.schema.THING}, id = ${fields["id"]}`}</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleDeleteModalClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={onDelete}>
							Delete
						</Button>
					</Modal.Footer>
				</Modal>
			}
			{(errorMessage || savedMessage || updatingMessage) && !intercell &&
				<div className='d-flex'>
					<LeftEdge width={tableConfig.leftEdgeWidth} />
					<div className={classNames("cell-container", "p-row-gap", "w-100", {"p-intercell": !first})} />
					<RightEdge width={tableConfig.rightEdgeWidth} />
				</div>
			}
			<div
				className={classNames("data-row", {
					"has-col-rows" : tableConfig.breakpoints[breakpoint].rowCellRows.length > 1,
					"danger-shadow-box": errorMessage,
					"py-2": errorMessage,
					"listener-target--modified": changed !== 0,
					...(rowContext["id"] === fields["id"] ? getAddedRowClasses(fields) : {}),
				})}
				style={{cursor: onRowClick && "pointer"}}
				onClick={(e) => onRowClick && onRowClick(e, fields)}
			>
				<div className={classNames("d-flex", {
					"flex-row-reverse": tableConfig.reverse,
				})}>
					{tableConfig.breakpoints[breakpoint].rowCellRows[0].map((cellProps:ContentCellProps, index:number) => {
						return (
							<Fragment key={index}>
								{cellProps.type === "left-edge" &&
									<LeftEdge
										width={tableConfig.leftEdgeWidth}
										classes={{
											"p-can-delete": changed === 0 && fields["id"] && tableConfig.canDelete,
										}}
										onClick={(e:any) => { e.stopPropagation()}}
									>
										{changed !== 0 &&
											<button
												className="m-auto btn btn-primary btn-sm"
												onClick={(e) => fields["id"] ? onSave(e) : onAdd(e)}
												type="submit"
											>
												Save
											</button>
										}
										{fields["id"] && changed === 0 && tableConfig.canDelete &&
											<img
												alt=""
												className="m-auto p-button"
												src={DeleteButton}
												onClick={(fields["id"] && changed === 0 && tableConfig.canDelete) ? (e) => {
													e.stopPropagation();
													setDeleteModalShow(true);
												} : undefined}
											/>
										}
									</LeftEdge>}
								{cellProps.type === "right-edge" &&
									<RightEdge width={tableConfig.rightEdgeWidth}>
										{(!fields["id"] || changed !== 0) &&
											<img
												alt=""
												className="m-auto p-button"
												src={CancelButton}
												onClick={onCancel}
												style={{display: "inline"}}
											/>
										}
										{!changed && !tableConfig.readOnly && !isClear() &&
											<img
												alt=""
												className="m-auto p-button"
												src={ClearButton}
												onClick={clearHandler}
											/>
										}
									</RightEdge>}
								{cellProps.type === "cell" &&
									<Fragment>
										{cellProps.component === IdCell &&
											<IdCell
												cellProps={cellProps}
												values={fields}
												canRowToggle={canThisRowToggle}
												isRowOpen={isRowOpen}
											/>
										}
										{cellProps.component !== IdCell &&
											<cellProps.component
												tableConfig={tableConfig}
												cellProps={cellProps}
												values={fields}
												onChange={onChange}
												fetchedData={fetchedData}
												readOnly={tableConfig.readOnly}
												contentClasses={cellProps.contentClasses}
											/>
										}
									</Fragment>
								}
							</Fragment>
						)
					})}
				</div>
				{tableConfig.breakpoints[breakpoint].rowCellRows.slice(1).filter((rowCellRow:ContentCellProps[]) => isColRowVisible(rowCellRow)).map((rowCellRow:ContentCellProps[], index:number) => {
					return (
						<Collapse
							in={isRowOpen}
							key={index}
						>
							<div className="col-row">
								<div
									className={classNames("d-flex", {
										"flex-row-reverse" : tableConfig.reverse,
									})}
								>
									{rowCellRow.map((cellProps:ContentCellProps, index:number) => {
										return (
											<Fragment key={index}>
												{cellProps.type === "left-edge" &&
													<LeftEdge width={tableConfig.leftEdgeWidth} />
												}
												{cellProps.type === "right-edge" &&
													<RightEdge width={tableConfig.rightEdgeWidth} />
												}
												{cellProps.type === "cell" &&
													<cellProps.component
														tableConfig={tableConfig}
														cellProps={cellProps}
														values={fields}
														onChange={onChange}
														fetchedData={fetchedData}
														readOnly={tableConfig.readOnly}
														contentClasses={cellProps.contentClasses}
														includeLabel={true}
													/>
												}
											</Fragment>
										);
									})}
								</div>
							</div>
						</Collapse>
					);
				})}
				{(errorMessage || updatingMessage || savedMessage) &&
					<Fragment>
						<div className="col-row">
							<div className="d-flex">
								<LeftEdge width={tableConfig.leftEdgeWidth} />
								<div className="w-100 cell-container opaque">
									{errorMessage &&
										<Alert className="w-100 mb-0 py-1" variant="danger">
											{errorMessage}
										</Alert>}
									{!errorMessage && updatingMessage &&
										<Alert className="w-100 mb-0 py-1" variant="info">
											{updatingMessage}
										</Alert>}
									{!errorMessage && savedMessage &&
										<Alert className="w-100 mb-0 py-1" variant="success">
											{savedMessage}
										</Alert>}
								</div>
								<RightEdge width={tableConfig.rightEdgeWidth} />
							</div>
						</div>
						<div className='d-flex'>
							<LeftEdge classes="py-0" width={tableConfig.leftEdgeWidth} />
							<div className="p-intercell cell-container w-100 py-0" />
							<RightEdge classes="py-0"  width={tableConfig.rightEdgeWidth} />
						</div>
					</Fragment>}
			</div>
			{(errorMessage || savedMessage || updatingMessage) && !intercell &&
				<div className='d-flex'>
					<LeftEdge width={tableConfig.leftEdgeWidth} />
					<div className="p-intercell cell-container p-row-gap w-100" />
					<RightEdge width={tableConfig.rightEdgeWidth} />
				</div>
			}
			{intercell &&
				<div className='d-flex'>
					<LeftEdge width={tableConfig.leftEdgeWidth} />
					<div className="p-intercell cell-container p-row-gap w-100" />
					<RightEdge width={tableConfig.rightEdgeWidth} />
				</div>
			}
			{isLast && !intercell && !(errorMessage || savedMessage || updatingMessage) &&
				<div className='d-flex'>
					<LeftEdge classes="py-0" width={tableConfig.leftEdgeWidth} />
					<div className="p-intercell cell-container w-100 py-0" />
					<RightEdge classes="py-0" width={tableConfig.rightEdgeWidth} />
				</div>
			}
		</Form>
	);
}

export default TableRow;
