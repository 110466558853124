
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import IdCellProps from 'TableCard/cells/IdCell';
import ServerRoutes from 'serverRoutes';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "categories",
	title: "Categories",
	serverRoute: ServerRoutes.CATEGORIES,
	schema: Schema.Category,
	readOnly: false,
	canAdd: true,
	primaryKey: Schema.Category.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return importRow({
			[Schema.Category.ID]: null,
			[Schema.Category.NAME]: null,
		}, TABLE_CONFIG.toImportFormatter);
	},
	payloadTags: [
		Schema.Category.ID,
		Schema.Category.NAME,
	],
	sortKey: Schema.Category.NAME,
	sortDirection: "ASC",
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Category.ID,
						}),
						new InputCellProps({
							label: Schema.Category.NAME_LABEL,
							tag: Schema.Category.NAME,
							percentWidth: 100,
							required: true,
						}),
					],
				]),
		},
});

export default TABLE_CONFIG;
