
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import TextareaCellProps from 'TableCard/cells/TextareaCell';
import StaticPreCellProps from 'TableCard/cells/StaticPre';
import DatetimeLocalCellProps from 'TableCard/cells/DatetimeLocalCell';
import IdCellProps from 'TableCard/cells/IdCell';
import PickerCellProps from 'TableCard/cells/PickerCell';
import { importRow, ImportRow } from 'hooks/useFieldManager';
import ServerRoutes from 'serverRoutes';
const Schema = require('portal-schema')

/*
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+
| Field         | Type         | Null | Key | Default           | Extra                                         |
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+
| id            | int          | NO   | PRI | NULL              | auto_increment                                |
| techorder     | int          | NO   | MUL | NULL              |                                               |
| employee      | int          | NO   | MUL | NULL              |                                               |
| notes         | text         | YES  |     | NULL              |                                               |
| updated       | timestamp    | NO   |     | CURRENT_TIMESTAMP | DEFAULT_GENERATED on update CURRENT_TIMESTAMP |
| filename      | varchar(255) | YES  |     | NULL              |                                               |
| original_name | varchar(255) | YES  |     | NULL              |                                               |
| created       | timestamp    | YES  |     | now()             | DEFAULT_GENERATED                             |
| updater       | int          | NO   | MUL | NULL              |                                               |
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+*/

const _NOTIFIED = "_notified";
const _UNNOTIFIED = "_unnotified";
const ASSIGNED_EMPLOYEE_IDS = 'assigned_employee_ids'; // Assigned employee ids in the same order as ASSIGNED_EMPLOYEE_NAMES
const ASSIGNED_EMPLOYEE_NAMES = 'assigned_employee_names'; // Assigned employee names, in alphabetical order.

const TABLE_CONFIG = new TableConfig({
	tag: "serviceorder-notes",
	title: "Service Order Notes",
	serverRoute: ServerRoutes.SERVICE_ORDER_APPOINTMENT,
	schema: Schema.Appointment,
	readOnly: false,
	canAdd: true,
	primaryKey: Schema.Appointment.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		const row = importRow({
			[Schema.Appointment.ID]: null,
			[Schema.Appointment.SERVICE_ORDER]: null,
			[Schema.Appointment.NOTES]: null,
			[Schema.Appointment.SCHEDULED_TIME]: null,
			[_NOTIFIED]: null,
			[_UNNOTIFIED]: null,
			[ASSIGNED_EMPLOYEE_NAMES]: null,
			[ASSIGNED_EMPLOYEE_IDS]: null,
		}, TABLE_CONFIG.toImportFormatter);
		row[Schema.Appointment.SERVICE_ORDER] = query?.[Schema.Appointment.SERVICE_ORDER] ?? "";
		return row;
	},
	payloadTags: [
		Schema.Appointment.ID,
		Schema.Appointment.SERVICE_ORDER,
		Schema.Appointment.NOTES,
		Schema.Appointment.SCHEDULED_TIME,
		ASSIGNED_EMPLOYEE_IDS,
	],
	sortKey: Schema.Appointment.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Appointment.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new DatetimeLocalCellProps({
							tag: Schema.Appointment.SCHEDULED_TIME,
							percentWidth: 100,
						}),
					],
					[
						new TextareaCellProps({
							tag: Schema.Appointment.NOTES,
							label: Schema.Appointment.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new PickerCellProps({
							tag: "",
							label: Schema.Appointment.ASSIGNED_EMPLOYEE_LABEL,
							employeeNameTextTag: ASSIGNED_EMPLOYEE_NAMES,
							employeeIdsTag: ASSIGNED_EMPLOYEE_IDS,
							percentWidth: 100,
							dialogTitle: "Choose Employees",
							dialogText: "Choose employees for this job. Employees whose names are red will not be notified of appointments, because they don't have their notification option set up.",
						}),
					],
					[
						new StaticPreCellProps({
							tag: _NOTIFIED,
							label: "Notified Employees",
							percentWidth: 50,
						}),
						new StaticPreCellProps({
							tag: _UNNOTIFIED,
							label: "Unnotified Employees (as of now)",
							percentWidth: 50,
						}),
					],
			]),
		}
});

export default TABLE_CONFIG;
