
import React, { Fragment } from 'react';
import LeftEdge from 'TableCard/cells/LeftEdge';
import RightEdge from 'TableCard/cells/RightEdge';
import { IdCell } from 'TableCard/cells/IdCell';
import TableConfig from 'TableCard/classes/tableConfig';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import { ImportRow } from 'hooks/useFieldManager';
const classNames = require('classnames');

interface StaticTableRowProps {
	tableConfig: TableConfig,
	row: ImportRow,
	fetchedData?: any,
	breakpoint: string,
	onChange: any,
	classes: any,
}

function StaticTableRow({
	tableConfig,
	row,
	fetchedData,
	breakpoint,
	onChange,
	classes,
}:StaticTableRowProps) {
	return (
		<Fragment>
			<div
				className={classNames("data-row", classes, {
					"has-col-rows" : tableConfig.breakpoints[breakpoint].rowCellRows.length > 1,
				})}
			>
				<div className={classNames("d-flex", {})}>
					{tableConfig.breakpoints[breakpoint].rowCellRows[0].map((cellProps:ContentCellProps, index:number) => {
						return (
							<Fragment key={index}>
								{cellProps.type === "left-edge" &&
									<LeftEdge width={tableConfig.leftEdgeWidth} />
								}
								{cellProps.type === "right-edge" &&
									<RightEdge width={tableConfig.rightEdgeWidth} />
								}
								{cellProps.type === "cell" &&
									<Fragment>
										{cellProps.component === IdCell &&
											<IdCell
												cellProps={cellProps}
												values={row}
											/>
										}
										{cellProps.component !== IdCell &&
											<cellProps.component
												tableConfig={tableConfig}
												cellProps={cellProps}
												values={row}
												onChange={onChange}
												fetchedData={fetchedData}
												contentClasses={cellProps.contentClasses}
											/>
										}
									</Fragment>
								}
							</Fragment>
						)
					})}
				</div>
				{tableConfig.breakpoints[breakpoint].rowCellRows.slice(1).map((rowCellRow:ContentCellProps[], index:number) => {
					return (
						<div key={index} className="col-row">
							<div className="d-flex">
								{rowCellRow.map((cellProps:ContentCellProps, index:number) => {
									return (
										<Fragment key={index}>
											{cellProps.type === "left-edge" &&
												<LeftEdge width={tableConfig.leftEdgeWidth} />
											}
											{cellProps.type === "right-edge" &&
												<RightEdge width={tableConfig.rightEdgeWidth} />
											}
											{cellProps.type === "cell" &&
												<cellProps.component
													tableConfig={tableConfig}
													cellProps={cellProps}
													values={row}
													onChange={onChange}
													fetchedData={fetchedData}
													contentClasses={cellProps.contentClasses}
													includeLabel={true}
												/>
											}
										</Fragment>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
			<div className='d-flex'>
				<LeftEdge classes="py-0" width={tableConfig.leftEdgeWidth} />
				<div className="p-intercell cell-container w-100 py-0" />
				<RightEdge classes="py-0" width={tableConfig.rightEdgeWidth} />
			</div>
		</Fragment>
	);
}

export default StaticTableRow;
