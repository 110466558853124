
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import IdCellProps from 'TableCard/cells/IdCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import InputCellProps from 'TableCard/cells/InputCell';
import StaticPreCellProps from 'TableCard/cells/StaticPre';
import ServerRoutes from 'serverRoutes';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "serviceorder-item-list",
	title: "Select Item or Service",
	serverRoute: ServerRoutes.SERVICE_ORDER_ITEM_LIST,
	schema: Schema.Item,
	readOnly: true,
	canAdd: false,
	canDelete: false,
	reverse: true,
	leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	primaryKey: Schema.Item.ID,
	sortKey: Schema.Item.NAME,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Item.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.Item.ITEM_NAME,
							label: Schema.Item.ITEM_NAME_LABEL,
							percentWidth: 100,
							readOnly: true,
						}),
					],
					[
						new StaticPreCellProps({
							tag: "",
							elements: [
								{
									tag: Schema.Item.RETAIL,
									label: Schema.Item.RETAIL_LABEL,
								},
								{
									tag: Schema.Item.DESCRIPTION,
									label: Schema.Item.DESCRIPTION_LABEL,
								},
								{
									tag: Schema.Item.NOTES,
									label: Schema.Item.NOTES_LABEL,
								},
								{
									tag: Schema.Item.MODEL,
									label: Schema.Item.MODEL_LABEL,
								},
								{
									tag: Schema.Item.SERIAL,
									label: Schema.Item.SERIAL_LABEL,
								}
							],
							percentWidth: 100,
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Item.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.Item.ITEM_NAME,
							label: Schema.Item.ITEM_NAME_LABEL,
							percentWidth: 100,
							image: Schema.Subcategory.ICON,
							readOnly: true,
						}),
						new StaticCellProps({
							tag: Schema.Item.RETAIL,
							label: Schema.Item.RETAIL_LABEL,
							fixedWidth: TableConfig.RETAIL_WIDTH,
							cellContainerClasses: { "align-right" : true, },
						}),
					],
					[
						new StaticPreCellProps({
							tag: Schema.Item.DESCRIPTION,
							label: Schema.Item.DESCRIPTION_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new StaticPreCellProps({
							tag: Schema.Item.NOTES,
							label: Schema.Item.NOTES_LABEL,
							image: Schema.Subcategory.ICON,
							percentWidth: 100,
						}),
					],
					[
						new StaticPreCellProps({
							tag: "",
							elements: [
								{
									tag: Schema.Item.MODEL,
									label: Schema.Item.MODEL_LABEL,
								},
								{
									tag: Schema.Item.SERIAL,
									label: Schema.Item.SERIAL_LABEL,
								}
							],
							percentWidth: 100,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
