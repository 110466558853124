
import React from 'react';
import TableCard from 'TableCard';
import ServiceOrderAppointmentsTableConfig from './serviceOrderAppointmentsTableConfig';
const Schema = require('portal-schema');

interface ServiceOrderAppointmentsProps {
	serviceOrderId: string;
	onFetched: any;
}

function ServiceOrderAppointments({serviceOrderId, onFetched}:ServiceOrderAppointmentsProps) {
	return (
		<TableCard
			tableConfig={ServiceOrderAppointmentsTableConfig}
			onFetched={onFetched}
			query={{
				[Schema.ServiceOrderNote.SERVICE_ORDER]: serviceOrderId,
			}}
		/>
	)
}

export default ServiceOrderAppointments;
