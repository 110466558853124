
import React, { useState, useEffect, useMemo } from 'react';
import TableCard from 'TableCard';
import SystemConfigTableConfig from './systemConfigTableConfig';
import StatusBlock from 'views/components/StatusBlock';
import useFetch, { FetchedSelectOptions, FetchOptions, FetchMode } from 'hooks/useFetch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import useFieldManager, { importRow, ImportRow } from 'hooks/useFieldManager'
import ServerRoutes from 'serverRoutes';
const Schema = require('portal-schema')
const classNames = require('classnames');

function SystemConfig({getDescription}:{getDescription:any}) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	//const [fields, setFields] = useState(Object.fromEntries(FIELDS.map((k) => [k, ""])));
	//const [savedFields, setSavedFields] = useState(Object.fromEntries(FIELDS.map((k) => [k, ""])));
	//const [changed, setChanged] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [savingMessage, setSavingMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [setOptions] = useFetch(savedCallback, pendingCallback, savingCallback, errorMessageCallback);
	const [setFieldManagerActions, fields, setFields, changed] = useFieldManager({});

	const fetchOptions =  useMemo(():FetchOptions => {
		return {
			url: ServerRoutes.SYSTEM_CONFIG.getUrl(),
		}
	}, []);

	useEffect(() => {
		setFetchOptions(fetchOptions);
	}, [setFetchOptions, fetchOptions]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	interface FetchedSystemConfigResponse {
		data: {
			selectOptions: FetchedSelectOptions;
			row: ImportRow;
		}
	}

	function responseCallback(response:FetchedSystemConfigResponse) {
		if (!response.data.row) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		setFields(importRow(response.data.row));
		setFieldManagerActions({accept: true});
	}

	function pendingCallback(value:boolean) {
		//console.log(`pendingCallback value=${value}`)
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		//console.log(`waitingCallback value=${value}`)
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function savingCallback(value:boolean) {
		setSavingMessage(value ? "Saving..." : "");
	}

	function savedCallback(value:any) {
		setFieldManagerActions({accept: true});
		setSuccessMessage("Information saved.");
		const timer = setTimeout(() => {
			setSuccessMessage("");
			clearTimeout(timer);
		}, 1500);
	}

	function onSave(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setOptions({url: ServerRoutes.SYSTEM_CONFIG.getUrl(), data: fields, method: 'post'});
	}

	function onReset(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setFieldManagerActions({revert: true});
		setErrorMessage("");
		setSavingMessage("");
	}

	function onChange(e:any) {
		setFields((prev:any) => ({...prev, [e.target.name]: e.target.value}));
		setFieldManagerActions({update: {[e.target.name]: e.target.value}});
		setErrorMessage("");
		setSavingMessage("");
	}

	useEffect(() => { getDescription("System Config")}, [getDescription])

	return (
		<>
			{!pending &&
				<div className="row mb-3">
					<Col md={12}>
						<Card className="w-100">
							<Card.Body>
								<div>
									<Col md={12}>
										{!loadingMessage &&
											<>
												<Card className={classNames("w-100", "mb-4", {
													"form-page--modified": changed !== 0
												})}>
													<Card.Body>
														<Card.Title>
															<h5>Login Config</h5>
														</Card.Title>
														<Row>
															<Col lg={4}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.MAX_ATTEMPTS,
																		label: Schema.SystemConfig.MAX_ATTEMPTS_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
															<Col lg={4}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.TIME_WINDOW_IN_MINUTES_LABEL,
																		label: Schema.SystemConfig.TIME_WINDOW_IN_MINUTES_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
															<Col lg={4}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.ARCHIVE_WINDOW_IN_DAYS,
																		label: Schema.SystemConfig.ARCHIVE_WINDOW_IN_DAYS_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
														</Row>
														<Row>
															<Col lg={12}>
																<p>A user is allowed a specific number of failed login attempts within the time window. If they can't log in successfully they are logged out for the remainder of the time window. Login attempts are logged and the log is kept for a specified number of days.</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
												<Card className={classNames("w-100", "mb-4", {
													"form-page--modified": changed !== 0
												})}>
													<Card.Body>
														<Card.Title>
															<h5>Mail Config</h5>
														</Card.Title>
														<Row>
															<Col lg={6}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.MAIL_SERVER,
																		label: Schema.SystemConfig.MAIL_SERVER_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
															<Col lg={6}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.MAIL_USER,
																		label: Schema.SystemConfig.MAIL_USER_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
															<Col lg={6}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.MAIL_PASSWORD,
																		label: Schema.SystemConfig.MAIL_PASSWORD_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
															<Col lg={6}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.MAIL_PORT,
																		label: Schema.SystemConfig.MAIL_PORT_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
														</Row>
														<Row>
															<Col lg={12}>
																<p>This is how the system sends email.</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
												<Card className={classNames("w-100", "mb-4", {
													"form-page--modified": changed !== 0
												})}>
													<Card.Body>
														<Card.Title>
															<h5>Untrusted IP Address</h5>
														</Card.Title>
														<Row>
															<Col lg={6}>
																<InputCell
																	cellProps={new InputCellProps({
																		tag: Schema.SystemConfig.UNTRUSTED_IP_TIMEOUT,
																		label: Schema.SystemConfig.UNTRUSTED_IP_TIMEOUT_LABEL,
																		percentWidth: 100,
																	})}
																	onChange={onChange}
																	values={fields}
																	includeLabel={true}
																/>
															</Col>
														</Row>
														<Row>
															<Col lg={12}>
																<p>Session duration for untrusted (typically off-site) IP addresses.</p>
															</Col>
														</Row>
													</Card.Body>
												</Card>
											</>}
										{loadingMessage &&
											<StatusBlock id={18} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
										}
										{!loadingMessage &&
											<StatusBlock id={19} errorMessage={errorMessage} infoMessage={savingMessage} successMessage={successMessage} disabled={changed === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
										}
									</Col>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</div>
			}
			<TableCard tableConfig={SystemConfigTableConfig} />
		</>
	);
}

export default SystemConfig;
