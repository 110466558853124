
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import IdCellProps from 'TableCard/cells/IdCell';
import SelectCellProps from 'TableCard/cells/SelectCell';
import ServerRoutes from 'serverRoutes';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "system-config-ip",
	title: "IP Table",
	serverRoute: ServerRoutes.IP,
	schema: Schema.Ip,
	readOnly: false,
	canAdd: true,
	primaryKey: Schema.Ip.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return importRow({
			[Schema.Ip.ID]: null,
			[Schema.Ip.IP]: null,
			[Schema.Ip.NOTES]: null,
			[Schema.Ip.STATUS]: 0,
		}, TABLE_CONFIG.toImportFormatter);
	},
	payloadTags: [
		Schema.Ip.ID,
		Schema.Ip.IP,
		Schema.Ip.NOTES,
		Schema.Ip.STATUS,
	],
	sortKey: Schema.Ip.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Ip.ID,
						}),
						new InputCellProps({
							tag: Schema.Ip.IP,
							label: Schema.Ip.IP_LABEL,
							percentWidth: 55,
						}),
						new SelectCellProps({
							tag: Schema.Ip.STATUS,
							label: Schema.Ip.STATUS_LABEL,
							percentWidth: 45,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.Ip.NOTES,
							label: Schema.Ip.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Ip.ID,
						}),
						new InputCellProps({
							tag: Schema.Ip.IP,
							label: Schema.Ip.IP_LABEL,
							percentWidth: 25,
						}),
						new InputCellProps({
							tag: Schema.Ip.NOTES,
							label: Schema.Ip.NOTES_LABEL,
							percentWidth: 50,
						}),
						new SelectCellProps({
							tag: Schema.Ip.STATUS,
							label: Schema.Ip.STATUS_LABEL,
							percentWidth: 25,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
