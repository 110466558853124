
import React, { useState, useEffect, useMemo } from 'react';
import StatusBlock from 'views/components/StatusBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import 'bootstrap-daterangepicker/daterangepicker.css';
import useFetch, { FetchOptions, FetchMode } from 'hooks/useFetch';
import ServerRoutes from 'serverRoutes';
import { importSelectOptions } from 'hooks/useFieldManager'

interface FetchedResponse {
	data: any;
}

function Dump({getDescription}:{getDescription:any}) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [url, setUrl] = useState(ServerRoutes.MY_INFORMATION.getUrl())
	const [response, setResponse] = useState<any>({data: {row: []}});
	const [query, setQuery] = useState({
		url: ServerRoutes.MY_INFORMATION.getUrl(),
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [selectOptions,] = useState<Record<string, {tag:string, value:string}[]>>(importSelectOptions({
		"url": [
			{tag: ServerRoutes.MY_INFORMATION.getUrl(), value: "My Information"},
			{tag: ServerRoutes.LOCATIONS.getUrl(), value: "Locations"},
			{tag: ServerRoutes.CATEGORIES.getUrl(), value: "Categories"},
			{tag: ServerRoutes.SYSTEM_CONFIG.getUrl(), value: "System Config"},
			{tag: ServerRoutes.SERVICE_ORDER_LIST_INFO.getUrl(), value: "Service Order List Info"},
			{tag: "/service_order/populate?selectedServiceOrderId=79&selectedClientId=", value: "Service Order"},
		]
	}));

	useEffect(() => { getDescription("Dump")}, [getDescription])

	const fetchOptions = useMemo(():FetchOptions => {
		return {
			url: url,
		}
	}, [url]);

	useEffect(() => {
		if (query["url"]) {
			setUrl(query["url"]);
		}
	}, [query])

	useEffect(() => {
		if (fetchOptions.url) {
			setFetchOptions(fetchOptions);
		}
	}, [setFetchOptions, fetchOptions]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	function responseCallback(response:FetchedResponse) {
		if (!response.data) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		console.log(response);
		setResponse(response);
	}

	function pendingCallback(value:boolean) {
		//console.log(`pendingCallback value=${value}`)
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		//console.log(`waitingCallback value=${value}`)
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function onChange(e:any) {
		setQuery(prevQuery => {
			return {...prevQuery, [e.target.name]: e.target.value};
		});
	}

	return (
		<>
			<Row>
				<Col lg={12}>
					<Card style={{marginBottom: "0.5rem"}}>
						<Card.Body>
							<Row>
								<Col lg={11}>
									<SelectCell
										cellProps={new SelectCellProps({
											label: "Components",
											tag: "url",
											selectOptions: selectOptions["url"],
										})}
										values={query}
										onChange={onChange}
										includeLabel={true}
									/>
								</Col>
								<Col lg={1}>
									<Button
										variant="primary"
										onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
											setUrl(query["url"]);
										}}>
										Go
									</Button>
								</Col>
							</Row>
							<StatusBlock id={4} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{[
				{label: "Service Order", data: response.data["serviceOrder"]},
				{label: "Client", data: response.data["client"]},
				{label: "Row", data: response.data["row"]}
			].filter((element:any) => element.data).map((element:any, index:number) => {
				return (
					<Card key={index} className="mb-4">
						<Card.Body>
							<h5>{element.label}</h5>
							{Object.keys(element.data).map((k, index) => {
								return (
									<Row key={index}>
										<Col lg={4}>
											<p>
												{k}
											</p>
										</Col>
										<Col lg={4}>
											<p>
												{typeof(element.data[k]) === "string" ? `"${element.data[k]}"` : element.data[k]}
											</p>
										</Col>
										<Col lg={4}>
											<p>
												{typeof(element.data[k])}
											</p>
										</Col>
									</Row>
								)
							})}
						</Card.Body>
					</Card>
				)
			})}
			{response?.data?.selectOptions && Object.keys(response.data.selectOptions).map((key:string, index:number) => {
				return (
					<Card key={index} className="mb-4">
						<Card.Body>
							<h5>{key}</h5>
							{response.data.selectOptions[key].map((element:any, index:number) => {
								return (
									<Row key={index}>
										<Col lg={3}>
											<p>
												{typeof(element.tag) === "string" ? `"${element.tag}"` : element.tag}
											</p>
										</Col>
										<Col lg={3}>
											<p>
												{typeof(element.tag)}
											</p>
										</Col>
										<Col lg={3}>
											<p>
												{typeof(element.value) === "string" ? `"${element.value}"` : element.value}
											</p>
										</Col>
										<Col lg={3}>
											<p>
												{typeof(element.value)}
											</p>
										</Col>
									</Row>
								)
							})}
						</Card.Body>
					</Card>
				)
			})}
		</>
	);
}

export default Dump;
