
import React, { useState, useEffect, useMemo, useContext, Fragment, useCallback } from 'react';
//import DateRangePicker from 'react-bootstrap-daterangepicker';
import TableCard from 'TableCard';
import StatusBlock from 'views/components/StatusBlock';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap-daterangepicker/daterangepicker.css';
import useFetch, { FetchOptions, FetchedSelectOptions, FetchMode } from 'hooks/useFetch';
import ServiceOrderTableConfig from './serviceOrderListTableConfig';
import Collapse from 'react-bootstrap/Collapse';
import ServiceOrderContext from '../ServiceOrderContext';
import AbandonModal from '../AbandonModal';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import RadioCellProps, { RadioCell } from 'TableCard/cells/RadioCell';
import CheckboxCellProps, { CheckboxCell } from 'TableCard/cells/CheckboxCell';
import DateRangeCellProps, { DateRangeCell } from 'TableCard/cells/DateRangeCell';
import ServerRoutes from 'serverRoutes';
import useFieldManager, { importSelectOptions, ExportRow, importRow, ImportRow } from 'hooks/useFieldManager'
import PickerCellProps, { PickerCell } from 'TableCard/cells/PickerCell';
const Schema = require('portal-schema');

const ASSIGNED_EMPLOYEE_IDS = 'assigned_employee_ids'; // Assigned employee ids in the same order as ASSIGNED_EMPLOYEE_NAMES
const ASSIGNED_EMPLOYEE_NAMES = 'assigned_employee_names'; // Assigned employee names, in alphabetical order.
const ASSIGNED_GROUP_IDS = "assigned_group_ids";
const ASSIGNED_GROUP_NAMES = "assigned_group_names";

const ANYONE = "anyone";
const ME = "me";
const LIST = "list";

const GROUP_SEARCH_MODE = "group_search_mode";

const MASTER_STATUS_CHECKBOX_ELEMENTS = [
	{tag: Schema.ServiceOrder.ProgressConstants.NEED_PARTS, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.NEED_PARTS, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.PARTS_ORDERED, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.PARTS_ORDERED, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.WORK_INCOMPLETE, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.WORK_INCOMPLETE, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.UNBILLED, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.UNBILLED, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.UNPAID, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.UNPAID, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.IN_STORE, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.IN_STORE, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.REFUND_DUE, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.REFUND_DUE, default: "1"},
	{tag: Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED, default: "0"},
	{tag: Schema.ServiceOrder.ProgressConstants.COMPLETE, decoratedTag: Schema.ServiceOrder.MASTER_STATUS + '-' + Schema.ServiceOrder.ProgressConstants.COMPLETE, default: "0"},
];

interface ServiceOrderListProps {
	unsaved: boolean;
	refetchCounter: number;
}

function ServiceOrderList({unsaved, refetchCounter}:ServiceOrderListProps) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [fetchedData, setFetchedData] = useState({});
	const [query, setQuery] = useState({
		[Schema.ServiceOrder.COMPLEX_NAME]: "",
		[Schema.ServiceOrder.SERVICE_LOCATION]: "",
		[Schema.ServiceOrderNote.NOTES]: "",
		[Schema.Client.MAIN_PHONE]: "",
		[Schema.Client.CELL_PHONE]: "",
		[Schema.Client.EMAIL]: "",
		[Schema.Client.WEB_PAGE]: "",
		[Schema.Client.ADDRESS1]: "",
		[Schema.Client.CITY]: "",
		[Schema.Client.STATE]: "",
		[Schema.Client.ZIP]: "",
		[Schema.ServiceOrder.CUSTOMER_COMMENTS]: "",
		[Schema.ServiceOrder.INV_EST_NO]: "",
		[Schema.ServiceOrder.EQUIPMENT_DESC]: "",
		[Schema.ServiceOrder.CREATED]: "",
		[Schema.ServiceOrder.UPDATED]: "",
		[Schema.ServiceOrder.ACCESSORIES]: "",
		[Schema.ServiceOrder.CALLED_STATUS]: "",
		[Schema.ServiceOrder.OVERDUE]: "",
		[Schema.ServiceOrder.PRIORITY]: "",
		[ASSIGNED_EMPLOYEE_IDS]: "",
		[ASSIGNED_EMPLOYEE_NAMES]: "",
		[ASSIGNED_GROUP_IDS]: "",
		[ASSIGNED_GROUP_NAMES]: "",
		[GROUP_SEARCH_MODE]: ANYONE,
		...MASTER_STATUS_CHECKBOX_ELEMENTS.reduce((acc, element) => ({...acc, [element.decoratedTag] : element.default}), {}),
	});

	const [showAbandonModal, setShowAbandonModal] = useState(false);
	const [proposedRowSelection, setProposedRowSelection] = useState<string | undefined>(undefined);
	const [rowSelection, setRowSelection] = useState<string | undefined>(undefined);

	const [hasAdvancedOptions, setHasAdvancedOptions] = useState(false);

	const {serviceOrderContext, setServiceOrderContext} = useContext(ServiceOrderContext);

	const fetchOptions = useMemo(():FetchOptions => {
		return {
			url: ServerRoutes.SERVICE_ORDER_LIST_INFO.getUrl(),
		}
	}, []);

	useEffect(() => {
		//console.log('ServiceOrder useFetch');
		setFetchOptions(fetchOptions);
	}, [setFetchOptions, fetchOptions]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	interface FetchedServiceOrderListResponse {
		data: {
			selectOptions: FetchedSelectOptions;
		}
	};

	function responseCallback(response:FetchedServiceOrderListResponse) {
		if (!response.data.selectOptions) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		setFetchedData(response.data);
	}

	function pendingCallback(value:boolean) {
		//console.log(`pendingCallback value=${value}`)
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		//console.log(`waitingCallback value=${value}`)
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		//console.log('service order list errorMessageCallback: ', value);
		setErrorMessage(value);
	}

	function onFetched(data:any) {
		if (serviceOrderContext.initialLoading) {
			if (data.rows.length !== 0) {
				setServiceOrderContext((prev:any) => ({...prev, loadingServiceOrderId: data.rows[0]["id"], loadingClientId: ""}));
			} else {				
				setServiceOrderContext((prev:any) => { return {...prev, initialLoading: false}});
			}
		}
	}

	function onDeleted(data:ExportRow, config:ExportRow) {
		console.log('ServiceOrderList: onDeleted', data, config, serviceOrderContext);
		const row = importRow(config);
		const id = row[Schema.ServiceOrder.ID];
		if (id === serviceOrderContext.loadedServiceOrderId) {
			setServiceOrderContext((prev:any) => ({...prev, loadingServiceOrderId: "", loadingClientId: ""/*, loadedServiceOrderId: "", loadedClientId: ""*/}));
		}
	}

	useEffect(() => {
		if (rowSelection) {
			setServiceOrderContext((prev:any) => { return {...prev, loadingServiceOrderId: rowSelection, loadingClientId: ""}});
			setRowSelection(undefined);
		}
	}, [rowSelection, setServiceOrderContext]);

	function onAbandon() {
		setRowSelection(proposedRowSelection);
		setProposedRowSelection(undefined);
	}

	const onRowClick = useCallback((e:any, row:ImportRow) => {
		if (unsaved) {
			setProposedRowSelection(row[Schema.ServiceOrder.ID]);
			setShowAbandonModal(true);
		} else {
			setRowSelection(row[Schema.ServiceOrder.ID]);
		}
		e.stopPropagation();
	}, [unsaved]);

	function onChange(e:any) {
		setQuery((prev:any) => ({...prev, [e.target.name]: e.target.value}));
	}

	function addSelectedRowClasses(row:Record<string, string>):Record<string, boolean> {
		return {"p-selected" : true}
	}

	function clearCheckboxes(event:any) {
		const fields:any = {}
		for (const element of MASTER_STATUS_CHECKBOX_ELEMENTS) {
			fields[element.decoratedTag] = "0";
		}
		setQuery((prev:any) => ({...prev, ...fields}));
	}

	function setCheckboxes(event:any) {
		const fields:any = {}
		for (const element of MASTER_STATUS_CHECKBOX_ELEMENTS) {
			fields[element.decoratedTag] = "1";
		}
		setQuery((prev:any) => ({...prev, ...fields}));
	}

	const [setFieldManagerActions, jump, setJump, changed] = useFieldManager({"jump": ""});

	const paginationChildren = useMemo(() => {
		function onJumpChanged(e:any) {
			console.log(e.target.name, e.target.value, typeof(e.target.value))
			setJump((prev:any) => ({...prev, [e.target.name]: e.target.value}));
			setFieldManagerActions({update: {[e.target.name]: e.target.value}});
		}
	
		return (
			<>
				<Form className="d-flex me-auto" onSubmit={(event) => {
					console.log("jump submit");
					onRowClick(event, {id: jump["jump"]})
					event.preventDefault();
				}}>
					<div className="cell-container ps-0">ID:</div>
					<Form.Control
						type="text"
						name="jump"
						value={jump["jump"]}
						onChange={onJumpChanged}
						style={{"width": 75}}
						className="ms-2"
					/>
					<Button
						className="btn btn-sm btn-primary ms-2"
						type="submit"
						disabled={changed === 0}
					>
						Jump
					</Button>
				</Form>
			</>
		)
	}, [jump, changed, onRowClick, setFieldManagerActions, setJump])

	return (
		<Fragment>
			{showAbandonModal &&
				<AbandonModal visible={showAbandonModal} setVisible={setShowAbandonModal} onAbandon={onAbandon}/>
			}
			{!pending &&
				<Row>
					<Col lg={12}>
						<Card style={{marginBottom: "0.5rem"}}>
							<Card.Body>
								{!loadingMessage && fetchedData &&
									<>
										<Row>
											<Col md={12}>
												<h5>Filter Service Orders</h5>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col lg={4}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.ServiceOrder.COMPLEX_NAME,
														label: Schema.ServiceOrder.COMPLEX_NAME_LABEL + " Contains",
														percentWidth: 100,
													})}
													onChange={onChange}
													values={query}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4}>
												<SelectCell
													cellProps={new SelectCellProps({
														label: Schema.ServiceOrder.SERVICE_LOCATION_LABEL,
														tag: Schema.ServiceOrder.SERVICE_LOCATION,
														hasAll: true,
													})}
													values={query}
													onChange={onChange}
													fetchedData={fetchedData}
													includeLabel={true}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={12}>
												<Card className="mb-4">
													<Card.Body>
														<Card.Title>
															<div className="d-flex flex-row">
																<h5>Service Order Status</h5>
																<Button className="ms-auto" onClick={e => clearCheckboxes(e)}>
																	Clear All
																</Button>
																<Button className="ms-2" onClick={e => setCheckboxes(e)}>
																	Set All
																</Button>
															</div>
														</Card.Title>
														<Row>
															{MASTER_STATUS_CHECKBOX_ELEMENTS.map((element, index) => {
																return (
																	<Col xl={3} lg={4} xs={6} key={index}>
																		<CheckboxCell
																			cellProps={new CheckboxCellProps({
																				tag: element.decoratedTag,
																				label: Schema.ServiceOrder.ProgressConstants.MAP[element.tag].name,
																				percentWidth: 100,
																			})}
																			onChange={onChange}
																			values={query}
																		/>
																	</Col>
																);
															})}
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Row>
										<Row>
											<Col lg={12}>
												<Card className="mb-4">
													<Card.Body>
														<Row>
															<RadioCell
																cellProps={new RadioCellProps({
																	tag: GROUP_SEARCH_MODE,
																	label: "Assigned To:",
																	percentWidth: 100,
																	options: [{value: ANYONE, label: "Anyone"}, {value: ME, label: "Me"}, {value: LIST, label: "List"}]
																})}
																onChange={onChange}
																values={query}
																includeLabel={true}
															/>
														</Row>
														<Row>
															<PickerCell
																cellProps={new PickerCellProps({
																	tag: "",
																	label: "Assigned Employees and Groups",
																	employeeNameTextTag: ASSIGNED_EMPLOYEE_NAMES,
																	employeeIdsTag: ASSIGNED_EMPLOYEE_IDS,
																	groupNameTextTag: ASSIGNED_GROUP_NAMES,
																	groupIdsTag: ASSIGNED_GROUP_IDS,
																	percentWidth: 100,
																	dialogTitle: "Filter by Assignment",
																	dialogText: "Select employees/groups to include in filter.",
																})}
																values={query}
																onChange={onChange}
																includeLabel={true}
															/>
														</Row>
													</Card.Body>
												</Card>
											</Col>
										</Row>
										<Row>
											<Col lg={12}>
												<Card style={{marginBottom: "0.5rem"}}>
													<Card.Body>
														<Row>
															<Col md={12}>
																<div className="d-flex flex-row flex-grow-1">
																	<h5>Advanced Filtering</h5>
																	<button className="ms-auto btn btn-primary btn-sm" onClick={() => setHasAdvancedOptions((prev) => !prev)}>{hasAdvancedOptions ? "Hide" : "Show"}</button>
																</div>
															</Col>
														</Row>
														<Collapse in={hasAdvancedOptions}>
															<Row className="mt-2">
																<Col md={12}>
																	<Card className="mb-3">
																		<Card.Body>
																			<Row>
																				<Col md={12}>
																					<div className="d-flex flex-row flex-grow-1">
																						<h5>Client Search</h5>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.MAIN_PHONE,
																							label: Schema.Client.MAIN_PHONE_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.CELL_PHONE,
																							label: Schema.Client.CELL_PHONE_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.EMAIL,
																							label: Schema.Client.EMAIL_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.WEB_PAGE,
																							label: Schema.Client.WEB_PAGE_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.ADDRESS1,
																							label: Schema.Client.ADDRESS1_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.CITY,
																							label: Schema.Client.CITY_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.STATE,
																							label: Schema.Client.STATE_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.Client.ZIP,
																							label: Schema.Client.ZIP_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Card>
																	<Card className="mb-3">
																		<Card.Body>
																			<Row>
																				<Col md={12}>
																					<div className="d-flex flex-row flex-grow-1">
																						<h5>Details Search</h5>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.ServiceOrder.CUSTOMER_COMMENTS,
																							label: Schema.ServiceOrder.CUSTOMER_COMMENTS_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.ServiceOrder.INV_EST_NO,
																							label: Schema.ServiceOrder.INV_EST_NO_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.ServiceOrder.EQUIPMENT_DESC,
																							label: Schema.ServiceOrder.EQUIPMENT_DESC_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.ServiceOrder.ACCESSORIES,
																							label: Schema.ServiceOrder.ACCESSORIES_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<DateRangeCell
																						cellProps={new DateRangeCellProps({
																							tag: Schema.ServiceOrder.CREATED,
																							label: Schema.ServiceOrder.CREATED_LABEL + " Date Range",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<DateRangeCell
																						cellProps={new DateRangeCellProps({
																							tag: Schema.ServiceOrder.UPDATED,
																							label: Schema.ServiceOrder.UPDATED_LABEL + " Date Range",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={4}>
																					<InputCell
																						cellProps={new InputCellProps({
																							tag: Schema.ServiceOrderNote.NOTES,
																							label: Schema.ServiceOrderNote.NOTES_LABEL + " Contains",
																							percentWidth: 100,
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Card>
																	<Card className="mb-3">
																		<Card.Body>
																			<Row>
																				<Col md={12}>
																					<div className="d-flex flex-row flex-grow-1">
																						<h5>Other Stuff</h5>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col lg={6}>
																					<RadioCell
																						cellProps={new RadioCellProps({
																							tag: Schema.ServiceOrder.CALLED_STATUS,
																							label: "Needs Call?",
																							percentWidth: 100,
																							options: [{value: "1", label: "Yes"}, {value: "", label: "Any"}]
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={6}>
																					<RadioCell
																						cellProps={new RadioCellProps({
																							tag: Schema.ServiceOrder.OVERDUE,
																							label: "Timeliness",
																							percentWidth: 100,
																							options: [{value: "1", label: "Overdue"}, {value: "", label: "Any"}]
																						})}
																						onChange={onChange}
																						values={query}
																						includeLabel={true}
																					/>
																				</Col>
																				<Col lg={6}>
																					<SelectCell
																						cellProps={new SelectCellProps({
																							label: Schema.ServiceOrder.PRIORITY_LABEL,
																							tag: Schema.ServiceOrder.PRIORITY,
																							hasAll: true,
																						})}
																						values={query}
																						onChange={onChange}
																						fetchedData={fetchedData}
																						includeLabel={true}
																					/>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Card>
																</Col>
															</Row>
														</Collapse>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</>}
								<StatusBlock id={17} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
							</Card.Body>
						</Card>
					</Col>
				</Row>}
			<TableCard
				className="arrow-list"
				tableConfig={ServiceOrderTableConfig}
				onRowClick={onRowClick}
				query={query}
				getAddedRowClasses={addSelectedRowClasses}
				onFetched={onFetched}
				onDeleted={onDeleted}
				paginationChildren={paginationChildren}
				refetchCounter={refetchCounter}
			/>
		</Fragment>
	);
}

export default ServiceOrderList;
