
import ICellProps from 'TableCard/interfaces/ICellProps';

abstract class CellProps implements ICellProps {
	styles: Record<string, string>;
	cellContainerClasses: Record<string, boolean>;
	contentContainerClasses: Record<string, boolean>;
	contentClasses: Record<string, boolean>;
	fixedWidth: number;
	percentWidth: number;
	abstract type: "left-edge" | "right-edge" | "cell";
	readOnly: boolean;
	customContentContainerClasses?: (values:Record<string, string>) => Record<string, boolean>;
	customContentClasses?: (values:Record<string, string>) => Record<string, boolean>;
		
	constructor(options:ICellProps) {
		this.styles = options.styles ?? {};
		this.cellContainerClasses = options.cellContainerClasses ?? {};
		this.contentClasses = options.contentClasses ?? {};
		this.contentContainerClasses = options.contentContainerClasses ?? {};
		this.fixedWidth = options.fixedWidth ?? 0;
		this.percentWidth = options.percentWidth ?? 0;
		this.readOnly = options.readOnly ?? false;
		this.customContentContainerClasses = options.customContentContainerClasses;
		this.customContentClasses = options.customContentClasses;
	}

	installWidthStyle(totalFixedWidth:number) {
		let expression = "";
		if (this.percentWidth) {
			expression += 'calc(';
		}
		if (this.fixedWidth) {
			expression += `${this.fixedWidth}px`;
		}
		if (this.percentWidth) {
			if (this.fixedWidth) {
				expression += ' + ';
			}
			expression += `${this.percentWidth / 100} * (100%`;
			if (totalFixedWidth) {
				expression += ` - ${totalFixedWidth}px`;
			}
			expression += '))';
		}
		if (expression) {
			this.styles = {...this.styles, "width": expression};
		}
	}
}

export default CellProps;

