
import React from 'react';
import TableCard from 'TableCard';
import ServiceOrderNotesTableConfig from './serviceOrderNotesTableConfig';
const Schema = require('portal-schema');

interface ServiceOrderNotesProps {
	serviceOrderId: string;
	onFetched: any;
}

function ServiceOrderNotes({serviceOrderId, onFetched}:ServiceOrderNotesProps) {
	return (
		<TableCard
			tableConfig={ServiceOrderNotesTableConfig}
			onFetched={onFetched}
			query={{
				[Schema.ServiceOrderNote.SERVICE_ORDER]: serviceOrderId,
			}}
		/>
	)
}

export default ServiceOrderNotes;
