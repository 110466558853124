
import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
const classNames = require('classnames');
const Schema = require('portal-schema')

interface ClientPageProps {
	changed: boolean,
	clientFields: any,
	serviceOrderFields: any,
	onClientChanged: any,
	onServiceOrderChanged: any,
}

function ClientPage({changed, clientFields, serviceOrderFields, onClientChanged, onServiceOrderChanged}:ClientPageProps) {
	return (
		<Fragment>
			<Card className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					<Card.Title>
						<h5>Client Information</h5>
					</Card.Title>
					<Row>
						<Col lg={8}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.COMPANY_NAME,
									label: Schema.Client.COMPANY_NAME_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={4}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.MAIN_PHONE,
									label: Schema.Client.MAIN_PHONE_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={8}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.FIRST_NAME,
									label: Schema.Client.FIRST_NAME_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={4}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.CELL_PHONE,
									label: Schema.Client.CELL_PHONE_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={8}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.LAST_NAME,
									label: Schema.Client.LAST_NAME_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={4}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.FAX,
									label: Schema.Client.FAX_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.EMAIL,
									label: Schema.Client.EMAIL_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.WEB_PAGE,
									label: Schema.Client.WEB_PAGE_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					<Card.Title>
						<h5>Billing Address</h5>
					</Card.Title>
					<Row>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.ADDRESS1,
									label: Schema.Client.ADDRESS1_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.ADDRESS2,
									label: Schema.Client.ADDRESS2_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.CITY_LABEL,
									label: Schema.Client.CITY,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.STATE,
									label: Schema.Client.STATE_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.Client.ZIP,
									label: Schema.Client.ZIP_LABEL,
									percentWidth: 100,
								})}
								onChange={onClientChanged}
								values={clientFields}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					<Card.Title>
						<h5>Shipping Address</h5>
					</Card.Title>
					<Row>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SHIPPING_ADDRESS1,
									label: Schema.ServiceOrder.SHIPPING_ADDRESS1_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SHIPPING_ADDRESS2,
									label: Schema.ServiceOrder.SHIPPING_ADDRESS2_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SHIPPING_CITY,
									label: Schema.ServiceOrder.SHIPPING_CITY_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SHIPPING_STATE,
									label: Schema.ServiceOrder.SHIPPING_STATE_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SHIPPING_ZIP,
									label: Schema.ServiceOrder.SHIPPING_ZIP_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Fragment>		
	)
}

export default ClientPage;
