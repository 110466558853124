
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

class TickboxCellProps extends ContentCellProps {
	constructor(options:IContentCellProps) {
		super(options);
		this.component = TickboxCell;
	}
}

interface ITickboxCellReactProps extends IContentCellReactProps<IContentCellProps> {
	readOnly?: boolean;
}

export function TickboxCell({cellProps, values, onChange, includeLabel}:ITickboxCellReactProps) {
	function onCheckboxChange(event:React.ChangeEvent<HTMLInputElement>) {
		event.target.value = values[cellProps.tag].toString() !== '0' ? "0" : "1";
		onChange(event);
	}
	
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<div className="form-check form-check-inline">
					{cellProps.readOnly &&
						<p>
							{values[cellProps.tag] !== '0' ? "Yes" : "No"}
						</p>}
					{!cellProps.readOnly &&
						<input
							className="form-check-input"
							type="checkbox"
							value="1"
							checked={values[cellProps.tag] !== '0'}
							name={cellProps.tag}
							data-toggle={cellProps.tooltip ? "tooltip" : ''}
							data-placement={cellProps.tooltip ? "top" : ''} 
							onChange={onCheckboxChange}
						/>}
				</div>
			</div>
		</div>
	)
}

export default TickboxCellProps;
