
import React from 'react';
import AychIcon from 'assets/aych.png';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IImageCellProps from 'TableCard/interfaces/IImageCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

interface IInputTooltipCellProps extends IContentCellProps, IImageCellProps {
	postfix?: string;
	prefix?: string;
	image?: string;
}

class InputTooltipCellProps extends ContentCellProps implements IInputTooltipCellProps {
	postfix?: string;
	prefix?: string;
	image?: string;

	constructor(options:IInputTooltipCellProps) {
		super(options);
		this.postfix = options.postfix;
		this.prefix = options.prefix;
		this.image = options.image;
		this.component = InputTooltipCell;
	}
}

interface IInputTooltipCellReactProps extends IContentCellReactProps<InputTooltipCellProps> {
	classes?: string | Record<string, any>;
}

function InputTooltipCell({cellProps, values, onChange, includeLabel, classes}:IInputTooltipCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<OverlayTrigger
					placement={"top"}
					overlay={
						<Tooltip>
							{cellProps.tooltip}
						</Tooltip>
					}
				>
					<InputGroup>
						{cellProps.prefix &&
							<InputGroup.Text>{cellProps.prefix}</InputGroup.Text>
						}
						{cellProps.image &&
							<InputGroup.Text>
								<img
									alt=""
									className="p-prepend-icon"
									src={(values[cellProps.image] && ("/images/" + values[cellProps.image])) || AychIcon}
								/>
							</InputGroup.Text>
						}
						<Form.Control
							className={classNames("form-control", classes)}
							type="text"
							name={cellProps.tag}
							value={values[cellProps.tag] || ""}
							required={cellProps.required}
							placeholder={cellProps.placeholder}
							readOnly={cellProps.readOnly}
							disabled={cellProps.readOnly}
							onChange={onChange}
						/>
						{cellProps.postfix &&
							<InputGroup.Text>{cellProps.postfix}</InputGroup.Text>
						}
					</InputGroup>
				</OverlayTrigger>
			</div>
		</div>
	);
}

export default InputTooltipCellProps;
