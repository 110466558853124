
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import StaticCellProps from 'TableCard/cells/StaticCell';
import TextareaCellprops from 'TableCard/cells/TextareaCell';
import IdCellProps from 'TableCard/cells/IdCell';
import { ServiceOrderNoteCustomCellProps } from './ServiceOrderNoteCustomComponent';
import { importRow, ImportRow } from 'hooks/useFieldManager';
import ServerRoutes from 'serverRoutes';
const Schema = require('portal-schema')

/*
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+
| Field         | Type         | Null | Key | Default           | Extra                                         |
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+
| id            | int          | NO   | PRI | NULL              | auto_increment                                |
| techorder     | int          | NO   | MUL | NULL              |                                               |
| employee      | int          | NO   | MUL | NULL              |                                               |
| notes         | text         | YES  |     | NULL              |                                               |
| updated       | timestamp    | NO   |     | CURRENT_TIMESTAMP | DEFAULT_GENERATED on update CURRENT_TIMESTAMP |
| filename      | varchar(255) | YES  |     | NULL              |                                               |
| original_name | varchar(255) | YES  |     | NULL              |                                               |
| created       | timestamp    | YES  |     | now()             | DEFAULT_GENERATED                             |
| updater       | int          | NO   | MUL | NULL              |                                               |
+---------------+--------------+------+-----+-------------------+-----------------------------------------------+*/

const TABLE_CONFIG = new TableConfig({
	tag: "serviceorder-notes",
	title: "Service Order Notes",
	serverRoute: ServerRoutes.SERVICE_ORDER_NOTE,
	schema: Schema.ServiceOrderNote,
	readOnly: false,
	canAdd: true,
	primaryKey: Schema.ServiceOrderNote.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		const row = importRow({
			[Schema.ServiceOrderNote.ID]: null,
			[Schema.ServiceOrderNote.SERVICE_ORDER]: null,
			[Schema.ServiceOrderNote.EMPLOYEE]: null,
			[Schema.ServiceOrderNote.NOTES]: null,
			[Schema.ServiceOrderNote.UPDATED]: null,
			[Schema.ServiceOrderNote.FILENAME]: null,
			[Schema.ServiceOrderNote.ORIGINAL_NAME]: null,
			[Schema.ServiceOrderNote.CREATED]: null,
			[Schema.ServiceOrderNote.UPDATER]: null,
			"_CHECKBOX": 0,
		}, TABLE_CONFIG.toImportFormatter);
		row[Schema.ServiceOrderNote.SERVICE_ORDER] = query?.[Schema.ServiceOrderNote.SERVICE_ORDER] ?? "";
		return row;
	},
	payloadTags: [
		Schema.ServiceOrderNote.ID,
		Schema.ServiceOrderNote.SERVICE_ORDER,
		Schema.ServiceOrderNote.EMPLOYEE,
		Schema.ServiceOrderNote.NOTES,
		Schema.ServiceOrderNote.FILENAME,
		Schema.ServiceOrderNote.ORIGINAL_NAME,
		Schema.ServiceOrderNote.UPDATER,
		"_CHECKBOX",
	],
	sortKey: Schema.ServiceOrderNote.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.ServiceOrderNote.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.EMPLOYEE_NAME,
							label: Schema.ServiceOrderNote.EMPLOYEE_NAME_LABEL,
							default: "You",
							percentWidth: 100,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.UPDATER_NAME,
							label: Schema.ServiceOrderNote.UPDATER_NAME_LABEL,
							default: "You",
							percentWidth: 100,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.CREATED,
							label: Schema.ServiceOrderNote.CREATED_LABEL,
							default: "Now",
							percentWidth: 100,
						}),
					],
					[
						new TextareaCellprops({
							tag: Schema.ServiceOrderNote.NOTES,
							label: Schema.ServiceOrderNote.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new ServiceOrderNoteCustomCellProps({
							tag: Schema.ServiceOrderNote.FILENAME,
							storeTag: Schema.ServiceOrderNote.ORIGINAL_NAME,
							checkboxTag: "_CHECKBOX",
							percentWidth: 100,
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.ServiceOrderNote.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.EMPLOYEE_NAME,
							label: Schema.ServiceOrderNote.EMPLOYEE_NAME_LABEL,
							default: "You",
							percentWidth: 37,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.UPDATER_NAME,
							label: Schema.ServiceOrderNote.UPDATER_NAME_LABEL,
							default: "You",
							percentWidth: 37,
						}),
						new StaticCellProps({
							tag: Schema.ServiceOrderNote.CREATED,
							label: Schema.ServiceOrderNote.CREATED_LABEL,
							default: "Now",
							percentWidth: 26,
						}),
					],
					[
						new TextareaCellprops({
							tag: Schema.ServiceOrderNote.NOTES,
							label: Schema.ServiceOrderNote.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new ServiceOrderNoteCustomCellProps({
							tag: Schema.ServiceOrderNote.FILENAME,
							storeTag: Schema.ServiceOrderNote.ORIGINAL_NAME,
							checkboxTag: "_CHECKBOX",
							percentWidth: 100,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
