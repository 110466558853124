
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import StaticPreCellProps from 'TableCard/cells/StaticPre';
import StaticCellProps from 'TableCard/cells/StaticCell';
import IdCellProps from 'TableCard/cells/IdCell';
import ServerRoutes from 'serverRoutes';
import FormatUtils from 'utils/formatUtils';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "log",
	title: "Log",
	serverRoute: ServerRoutes.LOG,
	schema: Schema.Log,
	readOnly: true,
	canAdd: false,
	canDelete: false,
	primaryKey: Schema.Log.ID,
	leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return importRow({
			[Schema.Log.ID]: null,
			[Schema.Log.USERNAME]: null,
			[Schema.Log.OPERATION]: null,
			[Schema.Log.AFFECTED_TABLE]: null,
			[Schema.Log.AFFECTED_ID]: null,
			[Schema.Log.CREATED]: null,
			[Schema.Log.NOTES]: null,
		}, TABLE_CONFIG.toImportFormatter);
	},
	payloadTags: [
	],
	toImportFormatter: {
		[Schema.LoginLog.CREATED]: (value:string) => FormatUtils.formatDateTime(value), // Input: 2023-04-21T11:37:28.000Z
	},
	sortKey: Schema.Log.CREATED,
	sortDirection: "DESC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			xs: new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Log.ID,
						}),
						new StaticCellProps({
							tag: Schema.Log.USERNAME,
							label: Schema.Log.USERNAME_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.Log.OPERATION,
							label: Schema.Log.OPERATION_LABEL,
							percentWidth: 50,
						}),
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_TABLE,
							label: Schema.Log.AFFECTED_TABLE_LABEL,
							percentWidth: 50,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_ID,
							label: Schema.Log.AFFECTED_ID_LABEL,
							cellContainerClasses: { "align-right" : true, },
							percentWidth: 100,
						}),
						new StaticCellProps({
							tag: Schema.Log.CREATED,
							label: Schema.Log.CREATED_LABEL,
							fixedWidth: TableConfig.DATE_TIME_WIDTH,
						}),
					],
					[
						new StaticPreCellProps({
							tag: Schema.Log.NOTES,
							label: Schema.Log.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
				]),
			md: new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Log.ID,
						}),
						new StaticCellProps({
							tag: Schema.Log.USERNAME,
							label: Schema.Log.USERNAME_LABEL,
							percentWidth: 60,
						}),
						new StaticCellProps({
							tag: Schema.Log.OPERATION,
							label: Schema.Log.OPERATION_LABEL,
							percentWidth: 40,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_TABLE,
							label: Schema.Log.AFFECTED_TABLE_LABEL,
							percentWidth: 55,
						}),
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_ID,
							label: Schema.Log.AFFECTED_ID_LABEL,
							cellContainerClasses: { "align-right" : true, },
							percentWidth: 45,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.Log.CREATED,
							label: Schema.Log.CREATED_LABEL,
							fixedWidth: TableConfig.DATE_TIME_WIDTH,
						}),
						new StaticPreCellProps({
							tag: Schema.Log.NOTES,
							label: Schema.Log.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
				]),
			lg: new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Log.ID,
						}),
						new StaticCellProps({
							tag: Schema.Log.USERNAME,
							label: Schema.Log.USERNAME_LABEL,
							percentWidth: 25,
						}),
						new StaticCellProps({
							tag: Schema.Log.OPERATION,
							label: Schema.Log.OPERATION_LABEL,
							percentWidth: 15,
						}),
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_TABLE,
							label: Schema.Log.AFFECTED_TABLE_LABEL,
							percentWidth: 20,
						}),
						new StaticCellProps({
							tag: Schema.Log.AFFECTED_ID,
							label: Schema.Log.AFFECTED_ID_LABEL,
							cellContainerClasses: { "align-right" : true, },
							percentWidth: 15,
						}),
						new StaticCellProps({
							tag: Schema.Log.CREATED,
							label: Schema.Log.CREATED_LABEL,
							fixedWidth: TableConfig.DATE_TIME_WIDTH,
						}),
						new StaticPreCellProps({
							tag: Schema.Log.NOTES,
							label: Schema.Log.NOTES_LABEL,
							percentWidth: 25,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
