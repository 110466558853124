
import { useState, useEffect, useMemo, Fragment } from 'react';
import StatusBlock from 'views/components/StatusBlock';
import useFetch, { FetchedResponse, FetchedSelectOptions, FetchOptions, FetchMode } from 'hooks/useFetch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import DatePickerCellProps, { DatePickerCell } from 'TableCard/cells/DatePickerCell';
import useFieldManager, { importRow, exportRow, importSelectOptions, ExportRow, ToExportFormatter } from 'hooks/useFieldManager'
import "react-datepicker/dist/react-datepicker.css";
import ServerRoutes from 'serverRoutes';
const classNames = require('classnames');
const Schema = require('portal-schema');

const PASSWORD_FIELDS = [
	"old",
	"new1",
	"new2",
]

const employeePayload = [
	Schema.Employee.ID,
	Schema.Employee.FIRST_NAME,
	Schema.Employee.LAST_NAME,
	Schema.Employee.PHONE,
	Schema.Employee.BIRTHDAY,
	Schema.Employee.ADDRESS,
	Schema.Employee.CITY,
	Schema.Employee.STATE,
	Schema.Employee.ZIP,
	Schema.Employee.HOME_LOCATION,
	Schema.Employee.EMAIL,
	Schema.Employee.APPOINTMENT_NOTIFICATION,
	Schema.Employee.NOTIFICATION_MODE,
];

const employeeExportFormatter:ToExportFormatter = {
	[Schema.Employee.BIRTHDAY]: (value:string) => value.slice(0, 10),
}

// 	[Schema.Employee.BIRTHDAY]: employeeFields[Schema.Employee.BIRTHDAY].slice(0, 10),

function MyInformation({getDescription}:{getDescription:any}) {
	const [setFetchOptions] = useFetch(fetchResponseCallback, pendingCallback, employeeWaitingCallback, employeeErrorCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [fetchedData, setFetchedData] = useState({});

	const [setEmployeeFieldManagerActions, employeeFields, setEmployeeFields, employeeChanged] = useFieldManager({});
	//const [employeeFields, setEmployeeFields] = useState(Object.fromEntries(FIELDS.map((k) => [k, ""])));
	//const [savedEmployeeFields, setSavedEmployeeFields] = useState(Object.fromEntries(FIELDS.map((k) => [k, ""])));
	const [employeeErrorMessage, setEmployeeErrorMessage] = useState("");
	const [employeeSuccessMessage, setEmployeeSuccessMessage] = useState("");
	const [employeeSavingMessage, setEmployeeSavingMessage] = useState("");
	const [employeeLoadingMessage, setEmployeeLoadingMessage] = useState("");
	const [setEmployeeOptions] = useFetch(savedEmployeeCallback, pendingCallback, employeeSavingCallback, employeeErrorCallback);

	const [setPasswordFieldManagerActions, passwordFields, setPasswordFields, passwordChanged] = useFieldManager(Object.fromEntries(PASSWORD_FIELDS.map((k) => [k, ""])));
	//const [passwordFields, setPasswordFields] = useState(Object.fromEntries(PASSWORD_FIELDS.map((k) => [k, ""])));
	//const [savedPasswordFields, setSavedPasswordFields] = useState(Object.fromEntries(PASSWORD_FIELDS.map((k) => [k, ""])));
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
	const [passwordSuccessMessage, setPasswordSuccessMessage] = useState("");
	const [passwordSavingMessage, setPasswordSavingMessage] = useState("");
	const [setPasswordOptions] = useFetch(savedPasswordCallback, pendingCallback, passwordSavingCallback, passwordErrorCallback);

	const fetchOptions = useMemo(():FetchOptions => {
		return {
			url: ServerRoutes.MY_INFORMATION.getUrl(),
		}
	}, []);

	useEffect(() => {
		setFetchOptions(fetchOptions);
	}, [setFetchOptions, fetchOptions]);

	function onEmployeeRetry() {
		setPending(true);
		setEmployeeLoadingMessage("");
		setFetchOptions(fetchOptions);
	}

	interface FetchedMyInformationResponse {
		data: {
			row: ExportRow;
			selectOptions: FetchedSelectOptions;
		}
	}

	function fetchResponseCallback(response:FetchedMyInformationResponse) {
		if (!response.data.row || !response.data.selectOptions) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		console.log('MyInformation fetch response=', response.data);
		setEmployeeFields(importRow(response.data.row));
		setEmployeeFieldManagerActions({accept: true});
		response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		setFetchedData(response.data);
	}

	function pendingCallback(value:boolean) {
		setPending(value);
	}

	function employeeWaitingCallback(loading:boolean) {
		setEmployeeLoadingMessage(loading ? "Loading..." : "");
	}

	function employeeSavingCallback(saving:boolean) {
		setEmployeeSavingMessage(saving ? "Updating..." : "");
	}

	function passwordSavingCallback(saving:boolean) {
		setPasswordSavingMessage(saving ? "Updating..." : "");
	}

	function savedEmployeeCallback(response:FetchedResponse) {
		setEmployeeFieldManagerActions({accept: true});
		setEmployeeSuccessMessage("Information updated.");
		const timer = setTimeout(() => {
			setEmployeeSuccessMessage("");
			clearTimeout(timer);
		}, 1500);
	}

	function savedPasswordCallback(response:FetchedResponse) {
		setPasswordFieldManagerActions({accept: true});
		setPasswordSuccessMessage("Password changed.");
		const timer = setTimeout(() => {
			setPasswordSuccessMessage("");
			clearTimeout(timer);
		}, 1500);
	}

	function employeeErrorCallback(errorMessage:string) {
		setEmployeeErrorMessage(errorMessage);
	}

	function passwordErrorCallback(errorMessage:string) {
		setPasswordErrorMessage(errorMessage);
	}

	function onEmployeeSave() {
		setEmployeeOptions({
			url: ServerRoutes.MY_INFORMATION_UPDATE_EMPLOYEE.getUrl(),
			data: exportRow(employeeFields, employeePayload, employeeExportFormatter),
			method: 'post',
		});
		//console.log(employeeFields);
		//const processedEmployeeFields = {...employeeFields, [Schema.Employee.BIRTHDAY]: employeeFields[Schema.Employee.BIRTHDAY].slice(0, 10)};
		//setEmployeeOptions({url: ServerRoutes.MY_INFORMATION_UPDATE_EMPLOYEE.getUrl(), data: processedEmployeeFields, method: 'post', });
	}

	function onPasswordSave() {
		setPasswordOptions({url: ServerRoutes.MY_INFORMATION_UPDATE_ACCOUNT.getUrl(), data: passwordFields, method: 'post',});
	}

	function onEmployeeReset() {
		setEmployeeFieldManagerActions({revert: true});
		setEmployeeErrorMessage("");
		setEmployeeSavingMessage("");
	}

	function onPasswordReset() {
		setPasswordFieldManagerActions({revert: true});
		setPasswordErrorMessage("");
	}

	function onEmployeeChanged(e:any) {
		setEmployeeFields((prevFields:any) => {
			return {...prevFields, [e.target.name]: e.target.value};
		});
		setEmployeeFieldManagerActions({update: {[e.target.name]: e.target.value}});
		setEmployeeErrorMessage("");
		setEmployeeSavingMessage("");
	}

	function onPasswordChanged(e:any) {
		setPasswordFields((prevFields:any) => {
			return {...prevFields, [e.target.name]: e.target.value};
		});
		setPasswordFieldManagerActions({update: {[e.target.name]: e.target.value}});
		setPasswordErrorMessage("");
		setPasswordSavingMessage("");
	}

	useEffect(() => { getDescription("My Information")}, [getDescription])

	return (
		<>
			{!pending &&
				<div className="row mb-3">
					<Col md={12}>
						<Card className={classNames("w-100", "mb-3", {
							"form-page--modified": employeeChanged !== 0,
						})}>
							<Card.Body>
								<Card.Title>
									<h5>Employee Details</h5>	
								</Card.Title>
								<Row>
									{!employeeLoadingMessage &&
										<Fragment>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.FIRST_NAME,
														label: Schema.Employee.FIRST_NAME_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.LAST_NAME,
														label: Schema.Employee.LAST_NAME_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.PHONE,
														label: Schema.Employee.PHONE_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<DatePickerCell
													cellProps={new DatePickerCellProps({
														tag: Schema.Employee.BIRTHDAY,
														label: Schema.Employee.BIRTHDAY_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.ADDRESS,
														label: Schema.Employee.ADDRESS_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.CITY,
														label: Schema.Employee.CITY_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.STATE,
														label: Schema.Employee.STATE_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.ZIP,
														label: Schema.Employee.ZIP_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<SelectCell
													cellProps={new SelectCellProps({
														label: Schema.Employee.HOME_LOCATION_LABEL,
														tag: Schema.Employee.HOME_LOCATION,
													})}
													values={employeeFields}
													onChange={onEmployeeChanged}
													fetchedData={fetchedData}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<InputCell
													cellProps={new InputCellProps({
														tag: Schema.Employee.EMAIL,
														label: Schema.Employee.EMAIL_LABEL,
														percentWidth: 100,
													})}
													onChange={onEmployeeChanged}
													values={employeeFields}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<SelectCell
													cellProps={new SelectCellProps({
														label: Schema.Employee.APPOINTMENT_NOTIFICATION_LABEL,
														tag: Schema.Employee.APPOINTMENT_NOTIFICATION,
													})}
													values={employeeFields}
													onChange={onEmployeeChanged}
													fetchedData={fetchedData}
													includeLabel={true}
												/>
											</Col>
											<Col lg={4} md={6}>
												<SelectCell
													cellProps={new SelectCellProps({
														label: Schema.Employee.NOTIFICATION_MODE_LABEL,
														tag: Schema.Employee.NOTIFICATION_MODE,
													})}
													values={employeeFields}
													onChange={onEmployeeChanged}
													fetchedData={fetchedData}
													includeLabel={true}
												/>
											</Col>
										</Fragment>
									}
								</Row>
								{employeeLoadingMessage &&
									<StatusBlock id={5} errorMessage={employeeErrorMessage} infoMessage={employeeLoadingMessage} onRetry={employeeErrorMessage ? onEmployeeRetry : undefined} />}
								{!employeeLoadingMessage &&
									<StatusBlock id={6} errorMessage={employeeErrorMessage} infoMessage={employeeSavingMessage} successMessage={employeeSuccessMessage} disabled={employeeChanged === 0} onReset={onEmployeeReset} saveLabel="Update" onSave={onEmployeeSave} />}
									</Card.Body>
						</Card>
						<Card className={classNames("w-100", {
							"form-page--modified": passwordChanged !== 0,
						})}>
							<Card.Body>
								<Card.Title>
									<h5>Change Password</h5>
								</Card.Title>
								<Row>
									<Col lg={4} md={6}>
										<InputCell
											cellProps={new InputCellProps({
												tag: "old",
												label: "Old Password",
												percentWidth: 100,
												inputType: "password",
											})}
											onChange={onPasswordChanged}
											values={passwordFields}
											includeLabel={true}
										/>
									</Col>
									<Col lg={4} md={6}>
										<InputCell
											cellProps={new InputCellProps({
												tag: "new1",
												label: "New Password",
												percentWidth: 100,
												inputType: "password",
											})}
											onChange={onPasswordChanged}
											values={passwordFields}
											includeLabel={true}
										/>
									</Col>
									<Col lg={4} md={6}>
										<InputCell
											cellProps={new InputCellProps({
												tag: "new2",
												label: "Repeat New Password",
												percentWidth: 100,
												inputType: "password",
											})}
											onChange={onPasswordChanged}
											values={passwordFields}
											includeLabel={true}
										/>
									</Col>
								</Row>
								<StatusBlock id={7} errorMessage={passwordErrorMessage} infoMessage={passwordSavingMessage} successMessage={passwordSuccessMessage} disabled={passwordChanged === 0} onReset={onPasswordReset} saveLabel="Change" onSave={onPasswordSave} />
							</Card.Body>
						</Card>
					</Col>
				</div>}
		</>
	);
}

export default MyInformation;
