
import React, { useContext } from 'react';
import ServiceOrderContext from './ServiceOrderContext';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface DebugPaneProps {
	loadingMessage: string,
	pending: boolean,
	errorMessage: string,
}

function DebugPane({loadingMessage, pending, errorMessage}:DebugPaneProps) {
	const {serviceOrderContext, } = useContext(ServiceOrderContext);

	return (
		<div className="w-100">
			<Row>
				{[
					{label: "serviceOrderContext.loadingServiceOrderId", value: serviceOrderContext.loadingServiceOrderId},
					{label: "serviceOrderContext.loadingClientId", value: serviceOrderContext.loadingClientId},
					{label: "serviceOrderContext.loadedServiceOrderId", value: serviceOrderContext.loadedServiceOrderId},
					{label: "serviceOrderContext.loadedClientId", value: serviceOrderContext.loadedClientId},
					{label: "serviceOrderContext.initialLoading", value: serviceOrderContext.initialLoading},
					{label: "loadingMessage", value: loadingMessage},
					{label: "pending", value: pending},
					{label: "errorMessage", value: errorMessage},
				].map((element, index) => {
					return (
						<Col lg={3} key={index} className="pb-2">
							<Alert className="p-1 ps-3 my-auto" variant="warning">
								<p className="mb-0">{`${element.label} = ${element.value !== undefined ? `"${element.value}"` : "undefined"}`}</p>
							</Alert>
						</Col>
					);
				})}
			</Row>
		</div>
	)
}

export default DebugPane;
