
import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import TextareaCellProps, { TextareaCell } from 'TableCard/cells/TextareaCell';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import StaticCellProps, { StaticCell } from 'TableCard/cells/StaticCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
const classNames = require('classnames');
const Schema = require('portal-schema')

interface DetailsPageProps {
	changed: boolean,
	serviceOrderFields: any,
	onServiceOrderChanged: any,
	fetchedData: any,
}

function DetailsPage({changed, serviceOrderFields, onServiceOrderChanged, fetchedData}:DetailsPageProps) {
	return (
		<>
			<Card className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					<Card.Title>
						<h5>Customer/Transaction Information</h5>
					</Card.Title>
					<Row>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.REFERRED_BY,
									label: Schema.ServiceOrder.REFERRED_BY_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<StaticCell
								cellProps={new StaticCellProps({
									label: Schema.ServiceOrder.CREATED_LABEL,
									tag: Schema.ServiceOrder.CREATED,
									default: "Now",
								})}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<StaticCell
								cellProps={new StaticCellProps({
									label: Schema.ServiceOrder.UPDATED_LABEL,
									tag: Schema.ServiceOrder.UPDATED,
									default: "Now",
								})}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<SelectCell
								cellProps={new SelectCellProps({
									label: Schema.ServiceOrder.TAKEN_BY_LABEL,
									tag: Schema.ServiceOrder.TAKEN_BY,
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								fetchedData={fetchedData}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<SelectCell
								cellProps={new SelectCellProps({
									label: Schema.ServiceOrder.TAKEN_TYPE_LABEL,
									tag: Schema.ServiceOrder.TAKEN_TYPE,
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								fetchedData={fetchedData}
								includeLabel={true}
							/>
						</Col>
						<Col lg={3}>
							<SelectCell
								cellProps={new SelectCellProps({
									label: Schema.ServiceOrder.SERVICE_LOCATION_LABEL,
									tag: Schema.ServiceOrder.SERVICE_LOCATION,
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								fetchedData={fetchedData}
								includeLabel={true}
							/>
						</Col>
						<Col lg={12}>
							<TextareaCell
								cellProps={new TextareaCellProps({
									tag: Schema.ServiceOrder.CUSTOMER_COMMENTS,
									label: Schema.ServiceOrder.CUSTOMER_COMMENTS_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					<Card.Title>
						<h5>Equipment Information</h5>
					</Card.Title>
					<Row>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.COMPUTER_USERNAME,
									label: Schema.ServiceOrder.COMPUTER_USERNAME_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.COMPUTER_PASSWORD,
									label: Schema.ServiceOrder.COMPUTER_PASSWORD_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.INV_EST_NO,
									label: Schema.ServiceOrder.INV_EST_NO_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={6}>
							<InputCell
								cellProps={new InputCellProps({
									tag: Schema.ServiceOrder.SERIAL,
									label: Schema.ServiceOrder.SERIAL_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={12}>
							<TextareaCell
								cellProps={new TextareaCellProps({
									tag: Schema.ServiceOrder.EQUIPMENT_DESC,
									label: Schema.ServiceOrder.EQUIPMENT_DESC_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
						<Col lg={12}>
							<TextareaCell
								cellProps={new TextareaCellProps({
									tag: Schema.ServiceOrder.ACCESSORIES,
									label: Schema.ServiceOrder.ACCESSORIES_LABEL,
									percentWidth: 100,
								})}
								onChange={onServiceOrderChanged}
								values={serviceOrderFields}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>		
	)
}

export default DetailsPage;
