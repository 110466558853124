
import React, { useState, useMemo, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import useFetch, { FetchedResponse, FetchOptions, FetchMode } from 'hooks/useFetch';
import StatusBlock from 'views/components/StatusBlock';

interface LoadingPageModalProps {
	visible: any;
	setVisible: any;
	onSuccess: any;
	url: string;
	data: any;
}

function LoadingPageModal({visible, setVisible, onSuccess, url, data}:LoadingPageModalProps) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [infoMessage, setInfoMessage] = useState("");
	const [pending, setPending] = useState(true);
	const [waiting, setWaiting] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	
	const fetchOptions = useMemo(():FetchOptions => {
		return {
			url: url,
			data: {
				fields: {...data},
			},
			method: 'post',
		}
	}, [url, data]);

	useEffect(() => {
		if (visible) {
			setInfoMessage("Adding...");
			setFetchOptions(fetchOptions);
		}
	}, [setFetchOptions, fetchOptions, visible]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	function responseCallback(response:FetchedResponse) {
		setSuccess(true);
		setInfoMessage("Added.");
		onSuccess && onSuccess(response);
		const timer = setTimeout(() => {
			setVisible(false);
			clearTimeout(timer);
		}, 1500);
	}

	function pendingCallback(value:boolean) {
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		setWaiting(value);
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	return (
		<Modal centered show={visible} onHide={() => setVisible(false)}>
			<Modal.Header>
				<StatusBlock errorMessage={errorMessage} infoMessage={(pending || waiting || success) && infoMessage} onRetry={errorMessage ? onRetry : undefined} />
			</Modal.Header>
		</Modal>
	)
}

export default LoadingPageModal;
