
import React, { useState, useEffect, useMemo } from 'react';
import TableRow from 'TableCard/TableRow';
import HeaderRow from 'TableCard/HeaderRow';
import CardLoadingBlock from 'TableCard/CardLoadingBlock';
import PaginationRow from 'TableCard/PaginationRow';
import useFetch, { FetchedSelectOptions, FetchOptions, FetchMode } from 'hooks/useFetch'
import useResizeObserver from "use-resize-observer";
import Collapse from 'react-bootstrap/Collapse';
import TableConfig from 'TableCard/classes/tableConfig';
import { importRows, importSelectOptions, ImportRow, ExportRow } from 'hooks/useFieldManager';
import DebugStorage from 'debugStorage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

interface TableCardProps {
	tableConfig: TableConfig;
	query?: ImportRow;
	paginationChildren?: any;
	className?: string;
	onRowClick?: any;
	getAddedRowClasses?: (row:Record<string, string>) => Record<string, boolean>;
	onFetched?: ((data:any) => void),
	onSaved?: ((data:any) => void);
	onDeleted?: ((data:ExportRow, config:ExportRow) => void);
	refetchCounter?: number;
	cardless?: boolean;
}

// https://stackoverflow.com/questions/54491645/media-query-syntax-for-reactjs

function TableCard({
	tableConfig,
	query,
	paginationChildren,
	className,
	onRowClick,
	getAddedRowClasses,
	onFetched,
	onSaved,
	onDeleted,
	refetchCounter,
	cardless,
}:TableCardProps) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING, FetchMode.WAITING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [isAddRowOpen, setIsAddRowOpen] = useState(false);
	const [pages, setPages] = useState(0);
	const [results, setResults] = useState(0);
	const [fetchedData, setFetchedData] = useState<any>(undefined);
	const [errorMessage, setErrorMessage] = useState("");
	const [state, setState] = useState({
		sortDirection: tableConfig.sortDirection,
		sortKey: tableConfig.sortKey,
		pageSize: tableConfig.pageSize,
		page: 0,
		query: query,
		refetchCounter: refetchCounter,
	});
	const [rowRefetchCounter, setRowRefetchCounter] = useState(0);

	const [breakpoint, setBreakpoint] = useState("sm");
	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ width, height }) => {
			width = width ?? 0;
			let breakpoint;
			if (width >= 1200) {
				breakpoint = "xl";
			} else if (width >= 992) {
				breakpoint = "lg";
			} else if (width >= 768) {
				breakpoint = "md";
			} else if (width >= 576) {
				breakpoint = "sm";
			} else {
				breakpoint = "xs";
			}
			setBreakpoint(breakpoint);
		},
	});

	//console.log(`tableCardless tag=${tableConfig.tag}, breakpoint=${breakpoint}`);

	function onChangeSortKeyAndDirection(key:string, direction:string) {
		//console.log(`onChangeSortKeyAndDirection(key, direction) = [${key}, ${direction}]`)
		setState((prev:any) => {return {...prev, "page" : 0, "sortKey" : key, "sortDirection": direction}});
	}

	function onChangePage(value:string) {
		//console.log(`onChangePage(page) = ${value}`)
		setState((prev:any) => {return {...prev, "page" : value}});
	}

	function onChangePageSize(newPageSize:string) {
		const n = parseInt(newPageSize);
		if (!isNaN(n) && n >= 1) {
			setState((prev:any) => {return {...prev, "page" : 0, "pageSize" : n}});
		}
	}

	useEffect(() => {
		//console.log(`TableCard, useEffect, refetchCounter=${refetchCounter}, state.refetchCounter=${state.refetchCounter}`);
		//console.log(tableConfig.tag, refetchCounter);
		if (JSON.stringify(state.query) !== JSON.stringify(query) || refetchCounter !== state.refetchCounter) {
			setState((prev:any) => ({
				...prev,
				"page" : 0,
				"query": query,
				refetchCounter: refetchCounter
			}));
		}
	}, [query, state.query, refetchCounter, state.refetchCounter]);

	const fetchOptions = useMemo(():FetchOptions => {
		//console.log(`tableCard useMemo (${tableConfig.tag})`);
		return {
			url: tableConfig.url,
			query: {
				page: state.page,
				pageSize: state.pageSize,
				sortKey: state.sortKey,
				sortDirection: state.sortDirection,
				...state.query,
			},
		}
		// state.refetchCounter is necessary.
		// eslint-disable-next-line
	}, [state.page, state.pageSize, state.query, state.sortDirection, state.sortKey, tableConfig.url, state.refetchCounter]);

	useEffect(() => {
		//console.log(`tableCard setFetchOptions (${tableConfig.tag})`);
		setFetchOptions(fetchOptions);
	}, [fetchOptions, setFetchOptions]);

	function fetch() {
		//console.log(`tableCardless fetch query (${tableConfig.url})`);
		setFetchOptions(fetchOptions);
	}

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	interface FetchedTableCardResponse {
		data: {
			rows: ExportRow[];
			selectOptions?: FetchedSelectOptions;
			env?: any;
		}
	}

	function responseCallback(response:FetchedTableCardResponse) {
		//console.log('tableCard FETCH url = ', fetchOptions.url, ', response = ', response);
		if (!response.data.env || !response.data.rows) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		const env = response.data.env;
		delete response.data.env;
		//console.log(`TableCard import rows ${tableConfig.url}`)
		//console.log(response.data.rows);
		response.data.rows = importRows(response.data.rows, tableConfig.toImportFormatter);
		//console.log(response.data.rows);
		if (response.data.selectOptions) {
			response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		}
		//console.log('tableCard responseCallback onFetched = ', onFetched)
		onFetched && onFetched(response.data);
		setPages(env.pages);
		setResults(env.results);
		//console.log(`TableCard onresponseCallback, tableConfig=${tableConfig.tag}`)
		setFetchedData(response.data);
		setRowRefetchCounter(prev => prev + 1);
		if (response.data.rows.length === 0 && tableConfig.canAdd) {
			setIsAddRowOpen(true);
		}
	}

	function pendingCallback(value:boolean) {
		//setPending(value);
		if (!value) {
			setPending(false);
		}
	}

	function waitingCallback(value:boolean) {
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function toggleAddRow() {
		setIsAddRowOpen(false);
	}
	
	function onAddClick(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		//console.log('onAddClick');
		setIsAddRowOpen(previous => !previous);
	}

	function onHeaderClick(event:React.MouseEvent<HTMLDivElement, MouseEvent>, key:string, direction:string) {
		onChangeSortKeyAndDirection(key, direction);
	}

	function onRowDeleted(data:ExportRow, config:ExportRow) {
		onDeleted && onDeleted(data, config);
		setRowRefetchCounter(prev => prev + 1);
	}

	////useEffect(() => { console.log(`TableCard mounted ${tableConfig.url}`)}, [])
	////console.log(`TableCard render ${tableConfig.url} refetchCounter=${refetchCounter}`);

	function render() {
		return (
			<div className="search-results bordered" ref={ref}>
				{DebugStorage.hasShowBreakpoints() &&
					<p>{`Size = ${breakpoint}`}</p>
				}
				{tableConfig.hasPagination &&
					<PaginationRow
						onChangePageSize={onChangePageSize}
						onChangePage={onChangePage}
						page={state.page}
						pages={pages}
						results={results}
						pageSize={state.pageSize}
						tableConfig={tableConfig}
						loading={loadingMessage !== ""}
						onRefresh={(event:any) => fetch()}
					>
						{!results || loadingMessage ? undefined : paginationChildren}
					</PaginationRow>
				}
				<HeaderRow
					tableConfig={tableConfig}
					sortKey={state.sortKey}
					sortDirection={state.sortDirection}
					onHeaderClick={onHeaderClick}
					onAddClick={(!loadingMessage && !errorMessage && !isAddRowOpen && tableConfig.canAdd) ? onAddClick : undefined}
					breakpoint={breakpoint}
				/>
				{(loadingMessage || errorMessage || (!tableConfig.canAdd && fetchedData && fetchedData.rows.length === 0)) &&
					tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.TOP &&
					<CardLoadingBlock
						tableConfig={tableConfig}
						errorMessage={errorMessage}
						loadingMessage={loadingMessage}
						fetchedData={fetchedData}
						onRetry={onRetry}
					/>
				}
				{tableConfig.canAdd &&
					<Collapse in={isAddRowOpen}>
						<div className="add-row">
							<TableRow
								row={tableConfig.getDefaultImportRow(query)}
								tableConfig={tableConfig}
								isAddRow={true}
								toggleAddRow={toggleAddRow}
								fetch={fetch}
								fetchedData={fetchedData}
								intercell={tableConfig.breakpoints[breakpoint].rowCellRows.length > 1}
								isLast={fetchedData?.rows.length === 0}
								onSaved={onSaved}
								first={true}
								getAddedRowClasses={getAddedRowClasses ?? ((row:Record<string, string>) => ({}))}
								breakpoint={breakpoint}
							/>
						</div>
					</Collapse>}
				{fetchedData?.rows.map((row:any, index:number) => {
					return (
						<TableRow
							key={row["id"]}
							row={row}
							tableConfig={tableConfig}
							isAddRow={false}
							fetch={fetch}
							onRowClick={onRowClick}
							fetchedData={fetchedData}
							intercell={tableConfig.breakpoints[breakpoint].rowCellRows.length > 1 && index !== fetchedData.rows.length - 1}
							isLast={index === fetchedData.rows.length - 1}
							getAddedRowClasses={getAddedRowClasses ?? ((row:Record<string, string>) => ({}))}
							onSaved={onSaved}
							onDeleted={onRowDeleted}
							first={index === 0 && !isAddRowOpen}
							breakpoint={breakpoint}
							refetchCounter={rowRefetchCounter}
						/>
					)})}
				{(loadingMessage || errorMessage || (!tableConfig.canAdd && fetchedData && fetchedData.rows.length === 0)) &&
					tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.BOTTOM &&
					<CardLoadingBlock
						tableConfig={tableConfig}
						errorMessage={errorMessage}
						loadingMessage={loadingMessage}
						fetchedData={fetchedData}
						onRetry={onRetry}
						hasStuff={(tableConfig.canAdd && isAddRowOpen) || fetchedData?.rows.length}
					/>
				}
				{tableConfig.hasPagination && fetchedData?.rows.length > 0 &&
					<PaginationRow
						bottomRow={true}
						onChangePageSize={onChangePageSize}
						onChangePage={onChangePage}
						page={state.page}
						pages={pages}
						pageSize={state.pageSize}
						tableConfig={tableConfig}
					/>
				}
			</div>
		);
	}

	return (
		<>
			{cardless && !pending &&
				render()
			}
			{!cardless && !pending &&
				<Row className={className} ref={ref}>
					<Col lg={12}>
						<Card>
							<Card.Body className="p-0">
								{render()}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			}
		</>
	)
}

export default TableCard;
