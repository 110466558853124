
import CellProps from 'TableCard/classes/cellProps';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import ServerRoute from 'serverRoutes';
import { RightEdgeCellProps } from 'TableCard/cells/RightEdge';
import { LeftEdgeCellProps } from 'TableCard/cells/LeftEdge';
import { ImportRow, ToExportFormatter, ToImportFormatter } from 'hooks/useFieldManager';

export class Breakpoint {
	rowCellRows: ContentCellProps[][];

	constructor(rowCellRows: ContentCellProps[][]) {
		this.rowCellRows = rowCellRows;
	}
}

interface TableConfigOptions {
	tag?: string,
	loadingBlockPosition?: any,
	hasPagination?: boolean,
	title?: string,
	schema: any,
	breakpoints: Record<string, Breakpoint>;
	leftEdgeWidth?: number,
	rightEdgeWidth?: number,
	reverse?: boolean,
	clearTags?: string[],
	canAdd?: boolean,
	readOnly?: boolean,
	getDefaultImportRow?: (values?:ImportRow) => ImportRow,
	primaryKey?: string,
	payloadTags?: string[],
	toExportFormatter?: ToExportFormatter,
	toImportFormatter?: ToImportFormatter,
	sortKey: string,
	sortDirection?: "ASC" | "DESC",
	serverRoute: ServerRoute,
	pageSize?: number,
	canDelete?: boolean,
	tableRowMultiMode?: any;
}

class TableConfig implements TableConfigOptions {
	tag?: string;
	loadingBlockPosition: any;
	hasPagination: boolean;
	title?: string;
	schema: any;
	breakpoints: Record<string, Breakpoint>;
	leftEdgeWidth: number;
	rightEdgeWidth: number;
	reverse: boolean;
	clearTags: string[];
	canAdd: boolean;
	readOnly: boolean;
	getDefaultImportRow: (values?:ImportRow) => ImportRow;
	primaryKey: string;
	payloadTags: string[];
	toExportFormatter?: ToExportFormatter;
	toImportFormatter?: ToImportFormatter;
	sortKey: string;
	sortDirection: "ASC" | "DESC";
	serverRoute: ServerRoute;
	pageSize: number;
	canDelete: boolean;
	tableRowMultiMode: any;

	url: string;

	static TableRowMultiMode = {
		CLOSED: 1,
		OPEN: 2,
		STATIC: 3,
	};

	static LoadingBlockPosition = {
		TOP: 1,
		BOTTOM: 2,
	};

	constructor(options:TableConfigOptions) {
		this.tag = options.tag; // A means of referring to a TableConfig in diagnostics, etc.
		this.loadingBlockPosition = options.loadingBlockPosition ?? TableConfig.LoadingBlockPosition.TOP;
		this.hasPagination = options.hasPagination ?? true;
		this.title = options.title;
		this.schema = options.schema;
		this.leftEdgeWidth = options.leftEdgeWidth || TableConfig.WIDE_EDGE_WIDTH;
		this.rightEdgeWidth = options.rightEdgeWidth || TableConfig.NARROW_EDGE_WIDTH;
		this.reverse = options.reverse ?? false;
		this.clearTags = options.clearTags ?? [];
		this.canAdd = options.canAdd ?? false;
		this.readOnly = options.readOnly ?? false;
		this.getDefaultImportRow = options.getDefaultImportRow ?? ((query?:ImportRow) => { return {}});
		this.primaryKey = options.primaryKey ?? "id";
		this.payloadTags = options.payloadTags ?? [];
		this.toExportFormatter = options.toExportFormatter || {};
		this.toImportFormatter = options.toImportFormatter || {};
		this.sortKey = options.sortKey;
		this.sortDirection = options.sortDirection ?? "ASC";
		this.serverRoute = options.serverRoute;
		this.url = options.serverRoute.getUrl();
		this.pageSize = options.pageSize ?? 20;
		this.canDelete = options.canDelete ?? true;
		// Initialization.
		this.tableRowMultiMode = options.tableRowMultiMode ?? TableConfig.TableRowMultiMode.CLOSED;
		let breakpoint: Breakpoint | undefined;
		let isPrepped: boolean = false;
		for (const tag of ["xs", "sm", "md", "lg", "xl"]) {
			if (options.breakpoints[tag] !== undefined) {
				breakpoint = options.breakpoints[tag];
				delete options.breakpoints[tag];
				break;
			}
		}
		console.assert(breakpoint !== undefined, "Broken TableConfig");
		for (const tag of ["xs", "sm", "md", "lg", "xl"]) {
			if (options.breakpoints[tag] !== undefined) {
				breakpoint = options.breakpoints[tag];
				isPrepped = false;
			}
			if (!isPrepped) {
				for (const rowCellRow of (breakpoint as Breakpoint).rowCellRows) {
					this.prepRow(rowCellRow, this.readOnly, this.reverse);
				}
				isPrepped = true;
			}
			options.breakpoints[tag] = breakpoint as Breakpoint;
		}
		this.breakpoints = options.breakpoints;
	}

	prepRow(rowCellRow:CellProps[], readOnly:boolean, reverse:boolean) {
		for (const cellProps of rowCellRow) {
			cellProps.readOnly = readOnly || cellProps.readOnly;
			cellProps.cellContainerClasses["fwd"] = !reverse;
			cellProps.cellContainerClasses["rev"] = reverse;
		}
		rowCellRow.unshift(new LeftEdgeCellProps({
			fixedWidth: this.leftEdgeWidth,
		}));
		rowCellRow.push(new RightEdgeCellProps({
			fixedWidth: this.rightEdgeWidth,
		}));
		let totalFixedWidth = 0;
		for (const cellProps of rowCellRow) {
			totalFixedWidth += cellProps.fixedWidth;
		}
		for (const cellProps of rowCellRow) {
			cellProps.installWidthStyle(totalFixedWidth);
		}
		if (this.reverse) {
			rowCellRow.reverse();
		}
	}

	// ID column.
	static get NORMAL_ID_WIDTH() { return 64; }
	// ID column with col-rows.
	static get WIDE_ID_WIDTH() { return 80; }

	// Regular left/right edge.
	static get NARROW_EDGE_WIDTH() { return 32; }
	// Left edge with "Save" button.
	static get WIDE_EDGE_WIDTH() { return 72; }

	// Date: "2020/11/04", etc
	static get DATE_WIDTH() { return 110 }
	// Date: "2020/11/04", etc, with an optional warning image preceding
	static get DATE_WIDTH_WITH_WARNING() { return 125 }
	// Date + Time: "2020/11/01 15:01:39", etc.
	static get DATE_TIME_WIDTH() { return 110 }

	// Checkbox column.
	static get CHECK_COL_WIDTH() { return 60; }
	// A rational dollar amount.
	static get RETAIL_WIDTH() { return 140; }

}

export default TableConfig;
