
import InputGroup from 'react-bootstrap/InputGroup';
import AuthStorage from 'authStorage';
import ServerRoutes from 'serverRoutes';
import Button from 'react-bootstrap/Button';
import api from 'api';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
const classNames = require('classnames');
const Schema = require('portal-schema');

interface IServiceOrderNoteCustomCellProps extends IContentCellProps {
	checkboxTag: string;
	storeTag: string;
}

export class ServiceOrderNoteCustomCellProps extends ContentCellProps implements IServiceOrderNoteCustomCellProps {
	checkboxTag:string;
	storeTag:string;

	constructor(options:IServiceOrderNoteCustomCellProps) {
		super(options);
		this.checkboxTag = options.checkboxTag;
		this.storeTag = options.storeTag;
		this.component = ServiceOrderNoteCustomComponent;
		this.tag = options.tag;
	}
}

interface CustomCellProps {
	cellProps: ServiceOrderNoteCustomCellProps,
	values?: any,
	onChange?: any,
	classes?: any,
}

async function openFile(path:string) {
	const authStorage = AuthStorage.get();
	const rs = await api.post(ServerRoutes.REFRESH_TOKEN.getUrl(), {
		[Schema.RefreshToken.REFRESH_TOKEN]: authStorage.refreshToken,
		[Schema.RefreshToken.EMPLOYEE_ID]: authStorage.user?.[Schema.Employee.ID]
	});
	const { jwtToken } = rs.data;
	AuthStorage.set({...authStorage, jwtToken: jwtToken});
	window.open(`http://localhost:8080/uploads/${path}?jwt=${jwtToken}`, "_blank", "noreferrer");
}

function ServiceOrderNoteCustomComponent({cellProps, values, onChange, classes}:CustomCellProps) {
	function onCheckboxChange(event:React.ChangeEvent<HTMLInputElement>) {
		event.target.value = values[cellProps.checkboxTag].toString() !== '0' ? "0" : "1";
		onChange(event);
	}
	
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				<InputGroup>
					<div className="d-flex flex-values flex-grow-1">
						{values[cellProps.tag] &&
							<div className="my-auto">
								<p>Uploaded File:</p>
							</div>
						}
						<div>
							{values[cellProps.tag] &&
								<Button variant="link" onClick={e => openFile(values[cellProps.tag])}>
									{values[cellProps.storeTag]}
								</Button>
							}
							{!values[cellProps.tag] &&
								<input
									type="file"
									name={cellProps.storeTag}
									value={values[cellProps.storeTag]}
									onChange={onChange}
								/>
							}
						</div>
						{values[cellProps.tag] &&
							<div className="form-check form-check-inline ms-auto my-auto">
								<label className="form-check-label mb-0">
									<input
										className="form-check-input"
										type="checkbox"
										value="1"
										name={cellProps.checkboxTag}
										checked={values[cellProps.checkboxTag] !== "0"}
										onChange={onCheckboxChange}
									/>
									Delete File?
								</label>
							</div>
						}
					</div>
				</InputGroup>
			</div>
		</div>
	);
}

export default ServiceOrderNoteCustomComponent;
