
import TextareaAutosize from 'react-textarea-autosize';
import InputGroup from 'react-bootstrap/InputGroup';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import ContentCellProps from 'TableCard/classes/contentCellProps';
const classNames = require('classnames');

class TextareaCellprops extends ContentCellProps {
	constructor(options:IContentCellProps) {
		super(options);
		this.component = TextareaCellAutosizing;
	}
}

interface ITextareaCellReactProps extends IContentCellReactProps<IContentCellProps> {
	classes?: string | Record<string, any>;
}

function TextareaCellAutosizing({cellProps, values, onChange, includeLabel}:ITextareaCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					{cellProps.readOnly &&
						<p>
							{values[cellProps.tag] || ""}
						</p>}
					{!cellProps.readOnly &&
						<TextareaAutosize
							className="form-control"
							name={cellProps.tag}
							value={values[cellProps.tag] || ""}
							required={cellProps.required}
							placeholder={cellProps.placeholder}
							data-toggle={cellProps.tooltip ? "tooltip" : ''}
							data-placement={cellProps.tooltip ? "top" : ''} 
							title={cellProps.tooltip}
							onChange={onChange}
						/>}
				</InputGroup>
			</div>
		</div>
	);
}

export default TextareaCellprops;
