
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import ServerRoutes from 'serverRoutes';
import FormatUtils from 'utils/formatUtils';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "invoice-payment",
	hasPagination: false,
	serverRoute: ServerRoutes.SERVICE_ORDER_INVOICE_PAYMENT,
	schema: Schema.InvoicePayment,
	readOnly: false,
	canAdd: true,
	loadingBlockPosition: TableConfig.LoadingBlockPosition.BOTTOM,
	//leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	primaryKey: Schema.InvoicePayment.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		const row = importRow({
			[Schema.InvoicePayment.ID]: null,
			[Schema.InvoicePayment.SERVICE_ORDER]: null,
			[Schema.InvoicePayment.NOTES]: null,
			[Schema.InvoicePayment.AMOUNT]: 0,
			[Schema.InvoicePayment.UPDATED]: null,
		}, TABLE_CONFIG.toImportFormatter);
		row[Schema.InvoicePayment.SERVICE_ORDER] = query?.[Schema.InvoicePayment.SERVICE_ORDER] ?? "";
		return row;
	},
	payloadTags: [
		Schema.InvoicePayment.ID,
		Schema.InvoicePayment.SERVICE_ORDER,
		Schema.InvoicePayment.NOTES,
		Schema.InvoicePayment.AMOUNT,
	],
	toImportFormatter: {
		[Schema.InvoicePayment.AMOUNT]: (value:number) => FormatUtils.formatTwoDecimals(value),
		[Schema.InvoicePayment.UPDATED]: (value:string | null) => (value) ? FormatUtils.formatDateTime(value) : "",
	},
	sortKey: Schema.InvoicePayment.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new StaticCellProps({
							tag: Schema.InvoicePayment.UPDATED,
							label: Schema.InvoicePayment.UPDATED_LABEL,
							percentWidth: 100,
							default: "Now",
						}),
					],
					[
						new InputCellProps({
							tag: Schema.InvoicePayment.NOTES,
							label: Schema.InvoicePayment.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.InvoicePayment.AMOUNT,
							label: Schema.InvoicePayment.AMOUNT_LABEL,
							cellContainerClasses: { "align-right": true, },
							prefix: '$',
							percentWidth: 100,
							required: true,
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new StaticCellProps({
							tag: Schema.InvoicePayment.UPDATED,
							label: Schema.InvoicePayment.UPDATED_LABEL,
							fixedWidth: 170,
							default: "Now",
						}),
						new InputCellProps({
							tag: Schema.InvoicePayment.AMOUNT,
							label: Schema.InvoicePayment.AMOUNT_LABEL,
							cellContainerClasses: { "align-right": true, },
							prefix: '$',
							percentWidth: 100,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.InvoicePayment.NOTES,
							label: Schema.InvoicePayment.NOTES_LABEL,
							percentWidth: 100,
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new StaticCellProps({
							tag: Schema.InvoicePayment.UPDATED,
							label: Schema.InvoicePayment.UPDATED_LABEL,
							fixedWidth: 170,
							default: "Now",
						}),
						new InputCellProps({
							tag: Schema.InvoicePayment.NOTES,
							label: Schema.InvoicePayment.NOTES_LABEL,
							percentWidth: 100,
						}),
						new InputCellProps({
							tag: Schema.InvoicePayment.AMOUNT,
							label: Schema.InvoicePayment.AMOUNT_LABEL,
							cellContainerClasses: { "align-right": true, },
							prefix: '$',
							fixedWidth: TableConfig.RETAIL_WIDTH,
							required: true,
						}),
					],
				]),
			}
});

export default TABLE_CONFIG;
