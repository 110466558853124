
import axios from 'axios';
import AuthStorage from 'authStorage';
import ServerRoutes from 'serverRoutes';
import DebugStorage from 'debugStorage';
const Schema = require('portal-schema')

const instance = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? "http://localhost:8080" : "https://portal-server.aych.com",
	headers: {
		"content-type": "application/json"
	}
});

instance.interceptors.request.use(
	async (config) => {
		const authStorage = AuthStorage.get();
		const jwtToken = authStorage.jwtToken;
		if (jwtToken) {
			config.headers["x-access-token"] = jwtToken; // for Nodejs Express back-end
		}
		const latency:number = DebugStorage.getLatency()
		if (latency) {
			await new Promise(resolve => setTimeout(resolve, latency));
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (DebugStorage.hasFailAllFetches()) {
			throw new Error("Forced fetch fail");
		}
		return response;
	},
	async (error) => {
		console.log('axios interceptor error = ', error);
		const originalConfig = error.config;
		if (error.response && error.response.status === 401 && !originalConfig._retry) {
			originalConfig._retry = true;
			try {
				const authStorage = AuthStorage.get();
				const rs = await instance.post(ServerRoutes.REFRESH_TOKEN.getUrl(), {
					[Schema.RefreshToken.REFRESH_TOKEN]: authStorage.refreshToken,
					[Schema.RefreshToken.EMPLOYEE_ID]: authStorage.user?.[Schema.Employee.ID]
				});
				const { jwtToken } = rs.data;
				AuthStorage.set({...authStorage, jwtToken: jwtToken});
				return instance(originalConfig);
			} catch (_error) {
				return Promise.reject(_error);
			}
		}
		return Promise.reject(error);
	}
);

export default instance;
