import React, { Fragment } from 'react';
import LeftEdge from 'TableCard/cells/LeftEdge';
import RightEdge from 'TableCard/cells/RightEdge';
import TableConfig from 'TableCard/classes/tableConfig';
import CellProps from 'TableCard/classes/cellProps';
import ContentCellProps from 'TableCard/classes/contentCellProps';
const classNames = require('classnames');

interface HeaderRowProps {
	tableConfig: TableConfig;
	onAddClick?: React.MouseEventHandler<HTMLButtonElement>;
	sortKey?: string;
	sortDirection?: string;
	onHeaderClick?: (event:React.MouseEvent<HTMLDivElement, MouseEvent>, sortKey:string, sortDirection:string) => void;
	breakpoint: string;
}

function HeaderRow({tableConfig, onAddClick, sortKey, sortDirection, onHeaderClick, breakpoint}:HeaderRowProps) {
	return (
		<div className="header-row">
			<div className={classNames("d-flex", {
				"flex-row-reverse" : tableConfig.reverse,
			})}>
				{tableConfig.breakpoints[breakpoint].rowCellRows[0].map((cellProps:CellProps, index:number) => {
					return (
						<Fragment key={index}>
							{cellProps.type === "left-edge" &&
								<LeftEdge classes={{"mt-auto": true}} width={tableConfig.leftEdgeWidth}>
									{onAddClick &&
										!tableConfig.readOnly &&
										<button className="m-auto btn btn-primary btn-sm" onClick={onAddClick}>Add</button>}
								</LeftEdge>}
							{cellProps.type === "right-edge" &&
								<RightEdge width={tableConfig.rightEdgeWidth} />}
							{cellProps.type === "cell" &&
								<div
									className={classNames("mt-auto", "cell-container", "header-row--sort", "can-click", {...cellProps.cellContainerClasses,
										"header-row--rotated-text": (cellProps as ContentCellProps).rotated,
										"header-row--sort-asc": sortKey === (cellProps as ContentCellProps).tag && sortDirection === 'ASC',
										"header-row--sort-desc": sortKey === (cellProps as ContentCellProps).tag && sortDirection !== 'ASC',
									})}
									style={cellProps.styles}
									onClick={onHeaderClick && ((event) => {
										if (sortKey !== (cellProps as ContentCellProps).tag) {
											return onHeaderClick(event, (cellProps as ContentCellProps).tag, "ASC");
										}
										return onHeaderClick(event, (cellProps as ContentCellProps).tag, (sortDirection === 'ASC') ? 'DESC' : 'ASC');
									})}
								>
									{(cellProps as ContentCellProps).rotated &&
										<div className="checkbox-cell">
											<span>{(cellProps as ContentCellProps).label}</span>
										</div>}
									{!(cellProps as ContentCellProps).rotated &&
										<p>{(cellProps as ContentCellProps).label}</p>}
								</div>}
						</Fragment>
					)})}
			</div>
		</div>
	)
}

export default HeaderRow;
