
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import IdCellProps from 'TableCard/cells/IdCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import ServerRoutes from 'serverRoutes';
import FormatUtils from 'utils/formatUtils';
const Schema = require('portal-schema');

const TABLE_CONFIG = new TableConfig({
	tag: "login-log",
	title: "Login Log",
	serverRoute: ServerRoutes.LOGIN_LOG,
	schema: Schema.LoginLog,
	readOnly: true,
	canAdd: false,
	primaryKey: "id",
	canDelete: false,
	leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	payloadTags: [
	],
	toImportFormatter: {
		// Input: 2023-04-21T11:37:28.000Z
		[Schema.LoginLog.CREATED]: (value:string) => FormatUtils.formatDateTime(value),
	},
	sortKey: Schema.LoginLog.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.LoginLog.ID,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.USERNAME,
							label: Schema.LoginLog.USERNAME_LABEL,
							percentWidth: 67,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.IP,
							label: Schema.LoginLog.IP_LABEL,
							percentWidth: 33,
						}),
					],
					[
						new StaticCellProps({
							tag: Schema.LoginLog.STATUS,
							label: Schema.LoginLog.STATUS_LABEL,
							percentWidth: 50,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.IP_STATUS,
							label: Schema.LoginLog.IP_STATUS_LABEL,
							percentWidth: 50,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.CREATED,
							label: Schema.LoginLog.CREATED_LABEL,
							fixedWidth: TableConfig.DATE_TIME_WIDTH,
						}),
					],
				]),
			"lg": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.LoginLog.ID,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.USERNAME,
							label: Schema.LoginLog.USERNAME_LABEL,
							percentWidth: 25,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.IP,
							label: Schema.LoginLog.IP_LABEL,
							percentWidth: 15,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.STATUS,
							label: Schema.LoginLog.STATUS_LABEL,
							percentWidth: 30,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.IP_STATUS,
							label: Schema.LoginLog.IP_STATUS_LABEL,
							percentWidth: 30,
						}),
						new StaticCellProps({
							tag: Schema.LoginLog.CREATED,
							label: Schema.LoginLog.CREATED_LABEL,
							fixedWidth: TableConfig.DATE_TIME_WIDTH,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
