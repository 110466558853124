
import React, { Fragment } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import LeftEdge from 'TableCard/cells/LeftEdge';
import RightEdge from 'TableCard/cells/RightEdge';
import TableConfig from 'TableCard/classes/tableConfig';
const classNames = require('classnames');

interface CardLoadingBlockProps {
	tableConfig: TableConfig;
	errorMessage?: string;
	loadingMessage?: string;
	fetchedData?: any;
	onRetry?: React.MouseEventHandler<HTMLButtonElement>;
	type?: string;
	hasStuff?: boolean;
}

function CardLoadingBlock({tableConfig, errorMessage, loadingMessage, fetchedData, onRetry, type, hasStuff}:CardLoadingBlockProps) {
	return (
		<Fragment>
			{tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.BOTTOM && hasStuff &&
				<div className='d-flex'>
					<LeftEdge width={tableConfig.leftEdgeWidth} />
					<div className="p-intercell cell-container w-100" />
					<RightEdge width={tableConfig.rightEdgeWidth} />
					fart
				</div>
			}
			<div className="data-row">
				<div className="d-flex">
					<LeftEdge width={tableConfig.leftEdgeWidth} />
					<div className="w-100 cell-container opaque">
						{errorMessage &&
							<>
								<Alert className="w-100 mb-0 py-1" variant="danger">
									{errorMessage}
								</Alert>
								{onRetry &&
									<div className="form-group my-auto ms-2">
										<Button
											onClick={onRetry}
											variant="primary"
										>
											Retry
										</Button>
									</div>
								}
							</>
						}
						{!errorMessage && (loadingMessage || (!tableConfig.canAdd && fetchedData && fetchedData.rows.length === 0)) &&
							<Alert className="w-100 mb-0 py-1" variant="info">
								{loadingMessage || (!tableConfig.canAdd && fetchedData && fetchedData.rows.length === 0 && "No matching results found.")}
							</Alert>
						}
					</div>
					<RightEdge width={tableConfig.rightEdgeWidth} />
				</div>
			</div>
			<div className='d-flex'>
				<LeftEdge
					classes={classNames({
						"py-0": tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.BOTTOM,
					})}
					width={tableConfig.leftEdgeWidth}
				/>
				<div
					className={classNames("p-intercell", "cell-container", "w-100", {
						"py-0": tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.BOTTOM,
					})}
				/>
				<RightEdge
					width={tableConfig.rightEdgeWidth}
					classes={classNames({
						"py-0": tableConfig.loadingBlockPosition === TableConfig.LoadingBlockPosition.BOTTOM,
					})}
				/>
			</div>
		</Fragment>
	)
}

export default CardLoadingBlock;
