
import AychIcon from 'assets/aych.png';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IImageCellProps from 'TableCard/interfaces/IImageCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

interface IInputCellProps extends IContentCellProps, IImageCellProps {
	postfix?: string;
	prefix?: string;
	inputType?: "password" | "text";
}

class InputCellProps extends ContentCellProps implements IInputCellProps {
	postfix: string;
	prefix: string;
	inputType?: "password" | "text";

	constructor(options:IInputCellProps) {
		super(options);
		this.postfix = options.postfix ?? "";
		this.prefix = options.prefix ?? "";
		this.component = InputCell;
		this.inputType = options.inputType ?? "text";
	}
}

interface IInputCellReactProps extends IContentCellReactProps<IInputCellProps> {
}

export function InputCell({cellProps, values, onChange, includeLabel}:IInputCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup className={classNames(
					cellProps.contentContainerClasses,
					cellProps.customContentContainerClasses ? cellProps.customContentContainerClasses(values) : {},
				)}>
					{!cellProps.readOnly && cellProps.prefix &&
						<InputGroup.Text>{cellProps.prefix}</InputGroup.Text>
					}
					{cellProps.image &&
						<InputGroup.Text>
							<img
								alt=""
								className="p-prepend-icon"
								src={(values[cellProps.image] && ("/images/" + values[cellProps.image])) || AychIcon}
							/>
						</InputGroup.Text>
					}
					{cellProps.readOnly &&
						<p
							className={classNames(
								"form-control",
								cellProps.contentClasses,
								cellProps.customContentClasses ? cellProps.customContentClasses(values) : {},
							)}
						>
							{cellProps.prefix + (values[cellProps.tag] || "")}
						</p>
					}
					{!cellProps.readOnly &&
						<Form.Control
							className={classNames("form-control", cellProps.contentClasses)}
							type={cellProps.inputType}
							name={cellProps.tag}
							value={values[cellProps.tag] || ""}
							required={cellProps.required}
							placeholder={cellProps.placeholder}
							readOnly={cellProps.readOnly}
							disabled={cellProps.readOnly}
							onChange={onChange}
						/>
					}
					{cellProps.postfix &&
						<InputGroup.Text>{cellProps.postfix}</InputGroup.Text>
					}
				</InputGroup>
			</div>
		</div>
	);
}

export default InputCellProps;
