
import { ExportRow } from 'hooks/useFieldManager';

const key:string = "authStorage";

export interface AuthStoragePayload {
	user?: ExportRow;
	jwtToken?: string;
	refreshToken?: string;
	onSetAuthStorage?: any;
}

class AuthStorage {
	static get():AuthStoragePayload {
		const localContext = localStorage.getItem(key);
		return localContext ? JSON.parse(localContext) : {};
	}

	static set(authStorage:AuthStoragePayload):void {
		localStorage.setItem(key, JSON.stringify(authStorage));
	}

	static reset():void {
		localStorage.removeItem(key);
	}
}

export default AuthStorage;
