
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

// 2023-04-18 => 04/18/2023
// 0123456789
function formatDate(date:string) {
	if (!date) {
		return "";
	}
	return `${date.slice(5, 7)}/${date.slice(8, 10)}/${date.slice(0, 4)}`;
}

// 2017-06-01T08:30
// 0123456789012345
// 2023-04-18T06:19:24.000Z


interface IDatetimeLocalCellProps extends IContentCellProps {
}

class DatetimeLocalCellProps extends ContentCellProps implements IDatetimeLocalCellProps {
	constructor(options:IDatetimeLocalCellProps) {
		super(options);
		this.component = DatetimeLocalCell;
	}
}

interface IDatetimeLocalCellReactProps extends IContentCellReactProps<IDatetimeLocalCellProps> {
}

export function DatetimeLocalCell({cellProps, values, onChange, includeLabel}:IDatetimeLocalCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					{cellProps.readOnly &&
						<p>
							{formatDate(values[cellProps.tag])}
						</p>}
					{!cellProps.readOnly &&
						<input
							className="form-control"
							type="datetime-local"
							name={cellProps.tag}
							value={values[cellProps.tag] || ""}
							required={cellProps.required}
							placeholder={cellProps.placeholder}
							title={cellProps.tooltip}
							onChange={onChange}
						/>}
				</InputGroup>
			</div>
		</div>
	);
}

export default DatetimeLocalCellProps;
