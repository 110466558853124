import React, {useEffect, useState, useCallback} from 'react';
import { Routes, Route } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Container from 'react-bootstrap/Container';
import AuthStorage, { AuthStoragePayload } from './authStorage';
import ServerRoutes from './serverRoutes';
import "bootstrap/dist/css/bootstrap.min.css";
import EventBus from 'eventBus';
import api from 'api';
import "styles/main.scss";
import "styles/index.scss";
import "styles/div-search-results.scss";
import "styles/service-order.scss"
import "styles/invoice.scss"
import MyNavBar from 'MyNavBar'
import Login from 'login';
import Locations from 'views/Locations';
import Categories from 'views/Categories';
import LoginLog from 'views/LoginLog';
import Root from 'views/Root';
import SystemConfig from 'views/SystemConfig';
import MyInformation from 'views/MyInformation';
import Log from 'views/Log';
import ServiceOrder from 'views/ServiceOrder';
import Debug from 'views/Debug';
import Dump from 'views/Dump';

const Schema = require('portal-schema')


function App() {
	const [authStorage, setAuthStorage] = useState<AuthStoragePayload>(AuthStorage.get());
	const [showDebug, setShowDebug] = useState(false);
	const [description, setDescription] = useState("");

	const logout = useCallback(() => {
		api
			.post(ServerRoutes.LOGOUT.getUrl(), {
				[Schema.RefreshToken.REFRESH_TOKEN]: authStorage.refreshToken,
				[Schema.RefreshToken.EMPLOYEE_ID]: authStorage.user?.[Schema.Employee.ID]})
			.catch (error => {})
		AuthStorage.reset();
		setAuthStorage({});
	}, [authStorage])

	function onIdle() {
		logout();
	}

	// https://idletimer.dev/docs/features/idle-detection
	useIdleTimer({
		onIdle,
		//onActive,
		//onAction,
		//timeout: 10000, // 10 seconds
		timeout: 14400000, // 4 hours
		//timeout: 36000000, // 10 hours
		throttle: 500
	})

	useEffect(() => {
		EventBus.on("logout", () => {
			logout();
		});
		return () => {
			EventBus.remove("logout", () => {});
		};
	}, [logout]);

	function onSetAuthStorage(newAuthContext:AuthStoragePayload) {
		AuthStorage.set(newAuthContext);
		setAuthStorage(newAuthContext);
	}

	function getDescription(description:string) {
		setDescription(description);
	}

	return (
		<>
			<Debug showModal={showDebug} setShowModal={setShowDebug}/>
			{!authStorage.refreshToken &&
				<Login onSetAuthStorage={onSetAuthStorage}/>}
			{authStorage.refreshToken &&
				<Container fluid>
					<MyNavBar logOut={logout} description={description} setShowModal={setShowDebug}/>
					<Routes>
						<Route path={ServerRoutes.INDEX.getUrl()} element={<Root getDescription={getDescription} />} />
						<Route path={ServerRoutes.LOCATIONS.getUrl()} element={<Locations getDescription={getDescription} />} />
						<Route path={ServerRoutes.CATEGORIES.getUrl()} element={<Categories getDescription={getDescription} />} />
						<Route path={ServerRoutes.LOGIN_LOG.getUrl()} element={<LoginLog getDescription={getDescription} />} />
						<Route path={ServerRoutes.SYSTEM_CONFIG.getUrl()} element={<SystemConfig getDescription={getDescription} />} />
						<Route path={ServerRoutes.MY_INFORMATION.getUrl()} element={<MyInformation getDescription={getDescription} />} />
						<Route path={ServerRoutes.LOG.getUrl()} element={<Log getDescription={getDescription} />} />
						<Route path={ServerRoutes.SERVICE_ORDER.getUrl() + '/:id?'} element={<ServiceOrder getDescription={getDescription} />} />
						<Route path={"/dump"} element={<Dump getDescription={getDescription} />} />
					</Routes>
				</Container>}
			<div style={{height: "100px"}} />
		</>
	);
}

export default App;
