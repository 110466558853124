
import { useState, useEffect, useMemo, Fragment } from 'react';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import useFieldManager, { importRows, ImportRow } from 'hooks/useFieldManager';
import useFetch, { FetchMode } from 'hooks/useFetch';
import useResizeObserver from "use-resize-observer";
import ServerRoutes from '../../serverRoutes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import DebugStorage from 'debugStorage';
import TextareaAutosize from 'react-textarea-autosize';
import StatusBlock from 'views/components/StatusBlock';
const classNames = require('classnames');
const Schema = require('portal-schema')

interface ChunkOptions {
	tag?: string;
	title: string;
	marginTag?: string;
	fields: ImportRow;
	rows: ImportRow[];
	idTag: string;
	nameTag: string;
	onChange: any;
	idPrefix: string;
}

class Chunk implements ChunkOptions{
	tag?: string;
	title: string;
	marginTag: string;
	fields: ImportRow;
	rows: ImportRow[];
	idTag: string;
	nameTag: string;
	onChange: any;
	idPrefix: string;

	constructor(options:ChunkOptions) {
		this.tag = options.tag;
		this.title = options.title;
		this.marginTag = options.marginTag ?? "";
		this.fields = options.fields;
		this.rows = options.rows;
		this.idTag = options.idTag;
		this.nameTag = options.nameTag;
		this.onChange = options.onChange;
		this.idPrefix = options.idPrefix;
	}
}

interface IPickerCellProps extends IContentCellProps {
	employeeNameTextTag?: string;
	employeeIdsTag?: string;
	groupNameTextTag?: string;
	groupIdsTag?: string;
	dialogText?: string;
	dialogTitle?: string;
}

class PickerCellProps extends ContentCellProps implements IPickerCellProps {
	employeeNameTextTag?: string;
	employeeIdsTag?: string;
	groupNameTextTag?: string;
	groupIdsTag?: string;
	dialogText: string;
	dialogTitle: string;

	constructor(options:IPickerCellProps) {
		super(options);
		this.employeeNameTextTag = options.employeeNameTextTag;
		this.employeeIdsTag = options.employeeIdsTag;
		this.groupNameTextTag = options.groupNameTextTag;
		this.groupIdsTag = options.groupIdsTag;
		this.dialogText = options.dialogText ?? "";
		this.dialogTitle = options.dialogTitle ?? "";
		this.component = PickerCell;
	}
}

interface IPickerCellReactProps extends IContentCellReactProps<IPickerCellProps> {
}

const _VALID = "_valid";

export function PickerCell({cellProps, values, onChange, includeLabel}:IPickerCellReactProps) {
	const [showModal, setShowModal] = useState(false);
	const [columns, setColumns] = useState(1);
	const [width, setWidth] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [loadingMessage, setLoadingMessage] = useState("");
	const [pending, setPending] = useState(false);
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [setEmployeeFieldManagerActions, employeeFields, setEmployeeFields] = useFieldManager({});
	const [setGroupFieldManagerActions, groupFields, setGroupFields] = useFieldManager({});
	const [employeeRows, setEmployeeRows] = useState<ImportRow[]>([]);
	const [groupRows, setGroupRows] = useState<ImportRow[]>([]);

	//console.log('props');
	//console.log(cellProps);
	//console.log(values);

	function responseCallback(response:any) {
		if (!response.data.employees) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		console.log(response);
		setEmployeeRows(importRows(response.data.employees));
		setGroupRows(importRows(response.data.groups));
	}

	function pendingCallback(value:boolean) {
		//console.log(`pendingCallback value=${value}`)
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		//console.log(`waitingCallback value=${value}`)
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function onClick(event:React.MouseEvent<HTMLDivElement, MouseEvent>) {
		setShowModal(true);
		setFetchOptions({
			url: ServerRoutes.HYBRID_LIST.getUrl(),
			query: {
				pickerParam: "appointment",
			}
		});
		event.stopPropagation();
	}

	function onRetry() {
		setFetchOptions({
			url: ServerRoutes.HYBRID_LIST.getUrl(),
			query: {
				pickerParam: "appointment",
			}
		});
	}

	const employeeIds = values[cellProps.employeeIdsTag ?? ""];
	useEffect(() => {
		if (cellProps.employeeIdsTag) {
			setEmployeeFields({...employeeRows.reduce((acc, values) => ({...acc, [values[Schema.Employee.ID]]: "0"}), {}), ...employeeIds.split(' ').reduce((acc, id) => ({...acc, [id]: "1"}), {})});
			setEmployeeFieldManagerActions({accept: true});
		}
	}, [
		employeeRows,
		employeeIds,
		cellProps.employeeIdsTag,
		setEmployeeFieldManagerActions,
		setEmployeeFields,
	])

	const groupIds = values[cellProps.groupIdsTag ?? ""];
	useEffect(() => {
		if (cellProps.groupIdsTag) {
			setGroupFields({...groupRows.reduce((acc, values) => ({...acc, [values[Schema.Egroup.ID]]: "0"}), {}), ...groupIds.split(' ').reduce((acc, id) => ({...acc, [id]: "1"}), {})});
			setGroupFieldManagerActions({accept: true});
		}
	}, [
		groupRows,
		groupIds,
		cellProps.groupIdsTag,
		setGroupFieldManagerActions,
		setGroupFields,
	])

	function onAccept(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		const employeeIds = employeeRows.reduce((acc, values) => acc + (employeeFields[values[Schema.Employee.ID]] === '1' ? (acc ? ' ' : '') + values[Schema.Employee.ID] : ""), "");
		const employeeNameText = employeeRows.reduce((acc, values) => acc + (employeeFields[values[Schema.Employee.ID]] === '1' ? (acc ? ', ' : '') + values[Schema.Employee.NAME] : ""), "");
		onChange({target: {name: cellProps.employeeNameTextTag, value: employeeNameText}});
		onChange({target: {name: cellProps.employeeIdsTag, value: employeeIds}});
		const groupIds = groupRows.reduce((acc, values) => acc + (groupFields[values[Schema.Egroup.ID]] === '1' ? (acc ? ' ' : '') + values[Schema.Egroup.ID] : ""), "");
		const groupNameText = groupRows.reduce((acc, values) => acc + (groupFields[values[Schema.Egroup.ID]] === '1' ? (acc ? ', ' : '') + values[Schema.Egroup.NAME] : ""), "");
		onChange({target: {name: cellProps.groupNameTextTag, value: groupNameText}});
		onChange({target: {name: cellProps.groupIdsTag, value: groupIds}});
		setShowModal(false);
		event.stopPropagation();
	}

	function onCancel(event?:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event?.stopPropagation();
		setShowModal(false);
	};

	function onClear(event?:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setGroupFields({...groupRows.reduce((acc, values) => ({...acc, [values[Schema.Egroup.ID]]: "0"}))});
		setEmployeeFields({...groupRows.reduce((acc, values) => ({...acc, [values[Schema.Egroup.ID]]: "0"}))});
	}

	function onHide() {
		setShowModal(false);
	}

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ width, height }) => {
			width = width ?? 0;
			setWidth(width);
			if (width >= 1200) {
				setColumns(6);
			} else if (width >= 992) {
				setColumns(5);
			} else if (width >= 768) {
				setColumns(4);
			} else if (width >= 576) {
				setColumns(3);
			} else if (width >= 400) {
				setColumns(2);
			} else {
				setColumns(1);
			}
		},
	});

	const chunks = useMemo(() => {
		function onEmployeeCheckboxChange(event:React.ChangeEvent<HTMLInputElement>) {
			setEmployeeFields(prev => ({...prev, [event.target.name]: prev[event.target.name] === '1' ? '0' : '1'}))
		}
		
		function onGroupCheckboxChange(event:React.ChangeEvent<HTMLInputElement>) {
			setGroupFields(prev => ({...prev, [event.target.name]: prev[event.target.name] === '1' ? '0' : '1'}))
		}

		return [
			new Chunk({
				tag: cellProps.employeeIdsTag,
				title: "Employees",
				marginTag: cellProps.groupIdsTag,
				fields: employeeFields,
				rows: employeeRows,
				idTag: Schema.Employee.ID,
				nameTag: Schema.Employee.NAME,
				onChange: onEmployeeCheckboxChange,
				idPrefix: "emp_",
			}),
			new Chunk({
				tag: cellProps.groupIdsTag,
				title: "Groups",
				fields: groupFields,
				rows: groupRows,
				idTag: Schema.Egroup.ID,
				nameTag: Schema.Egroup.NAME,
				onChange: onGroupCheckboxChange,
				idPrefix: "grp_",
			}),
		]
	}, [
		cellProps.employeeIdsTag,
		setEmployeeFields,
		employeeFields,
		employeeRows,
		cellProps.groupIdsTag,
		setGroupFields,
		groupFields,
		groupRows,
	]);

	let text = "";
	if (cellProps.employeeNameTextTag  && values[cellProps.employeeNameTextTag]) {
		text += values[cellProps.employeeNameTextTag];
	}
	if (cellProps.groupNameTextTag && values[cellProps.groupNameTextTag]) {
		if (text) {
			text += ", ";
		}
		text += values[cellProps.groupNameTextTag];
	}

	// https://stackoverflow.com/a/64430497/9129018
	// The modal div is wrapped as per that.
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				"p-pointer",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
			onClick={onClick}
		>
			<div className="w-100">
				{showModal &&
					<div onClick={(event) => event.stopPropagation()}>
						<Modal centered show={showModal} onHide={onHide} className="employee-picker" size="xl">
							{!pending && !errorMessage && !loadingMessage &&
								<Fragment>
									<Modal.Header closeButton>
										<h5>{cellProps.dialogTitle}</h5>
									</Modal.Header>
									<Modal.Body>
										{DebugStorage.hasShowBreakpoints() &&
											<p>{`EmployeeColumns = ${columns}, width = ${width}`}</p>
										}
										<div ref={ref}>
											{chunks.filter(chunk => chunk.tag).map((chunk, index) => {
												return (
													<Card key={index} className={classNames({"mb-3": chunk.marginTag})}>
														<Card.Body>
															{cellProps.groupIdsTag && cellProps.employeeIdsTag && chunk.title &&
																<Card.Title>
																	<h5>{chunk.title}</h5>	
																</Card.Title>
															}
															<Row>
																{Array(columns).fill(0).map((value, colIndex) => {
																	const rowCount = Math.ceil(chunk.rows.length / columns);
																	const rowIndex = colIndex * rowCount;
																	return (
																		<Col key={colIndex}>
																			{chunk.rows.slice(rowIndex, rowIndex + rowCount).map((values, index) => {
																				return (
																					<div className="form-check" key={values[chunk.idTag]}>
																						<input
																							className="form-check-input mb-0"
																							type="checkbox"
																							value="1"
																							checked={chunk.fields[values[chunk.idTag]] === '1'}
																							id={chunk.idPrefix + values[chunk.idTag]}
																							name={values[chunk.idTag]}
																							onChange={chunk.onChange}
																						/>
																						<label
																							className={classNames(
																								"form-check-label mb-0",
																								{"employee-invalid": values[_VALID] === '0'}
																							)}
																							htmlFor={chunk.idPrefix + values[chunk.idTag]}
																						>
																							{values[chunk.nameTag]}
																						</label>
																					</div>
																				)
																			})}
																		</Col>
																	)
																})}
															</Row>
														</Card.Body>
													</Card>
												)
											})}
										</div>
										<p className="me-auto my-auto">{cellProps.dialogText}</p>
									</Modal.Body>
									<Modal.Footer>
										<Button
											variant="secondary"
											onClick={onClear}
											className="me-auto"
											disabled={Object.entries(employeeFields).find(([key, value]) => value === '1') === undefined &&
												Object.entries(groupFields).find(([key, value]) => value === '1') === undefined}
										>
											Clear
										</Button>
										<Button variant="secondary" onClick={onCancel}>
											Cancel
										</Button>
										<Button variant="primary" onClick={onAccept}>
											Accept
										</Button>
									</Modal.Footer>
								</Fragment>
							}
							{!pending && (errorMessage || loadingMessage) &&
								<StatusBlock classes="p-3" errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
							}
						</Modal>
					</div>
				}
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					<TextareaAutosize
						className="form-control"
						value={text ? text : "None selected."}
						readOnly={true}
						style={{resize: "none"}}
					/>
				</InputGroup>
			</div>
		</div>
	);
}

export default PickerCellProps;
