
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import DatePicker from 'react-datepicker';
const classNames = require('classnames');

interface IDatePickerCellProps extends IContentCellProps {
}

class DatePickerCellProps extends ContentCellProps implements IDatePickerCellProps {
	constructor(options:IDatePickerCellProps) {
		super(options);
		this.component = DatePickerCell;
	}
}

interface IDatePickerCellReactProps extends IContentCellReactProps<IDatePickerCellProps> {
}

export function DatePickerCell({cellProps, values, onChange, includeLabel}:IDatePickerCellReactProps) {
	function onDateChanged(value:Date) {
		onChange({target: {name: cellProps.tag, value: value.toISOString()}})
	}

	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					<DatePicker
						className="form-control"
						name={cellProps.tag}
						selected={values[cellProps.tag] ? new Date(values[cellProps.tag]) : undefined}
						onChange={onDateChanged}
					/>
				</InputGroup>
			</div>
		</div>
	);
}

export default DatePickerCellProps;
