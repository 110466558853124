import { Fragment } from 'react';
import LeftEdge from 'TableCard/cells/LeftEdge';
import RightEdge from 'TableCard/cells/RightEdge';
import Pagination from 'TableCard/subcomponents/Pagination';
import TableConfig from 'TableCard/classes/tableConfig';
import { Button } from 'react-bootstrap';
import RefreshButton from 'assets/refresh.svg';

function toPix(n:number) {
	return `${n}px`;
}

interface PaginationProps {
	results?: number,
	pageSize?: number,
	onChangePageSize?: any,
	onChangePage?: any,
	pages?: number,
	page?: number,
	tableConfig: TableConfig,
	children?: any,
	bottomRow?: boolean,
	loading?: boolean,
	onRefresh?: any;
}

function PaginationRow({results, pageSize, onChangePageSize, onChangePage, pages, page, tableConfig, children, bottomRow, loading, onRefresh}:PaginationProps) {
	return (
		<div className="pagination-row" id="service-order-list-pag-id">
			<LeftEdge width={0} classes={{"ms-3": true}}/>
			<div className="d-flex flex-row flex-grow-1 flex-wrap justify-content-end">
				{tableConfig.title && !bottomRow &&
					<div className="my-auto pl-0 me-auto">
						<div className="cell-container">
							<p className="h5">{tableConfig.title}</p>
						</div>
					</div>
				}
				{!bottomRow && children}
				{!loading && results !== undefined && results > 0 &&
					<>
						<div className="col-auto my-auto pe-0">
							<div className="cell-container pe-0">
								<p>
									{`${results} ${results !== 1 ? "results" : "result"}`}
								</p>
							</div>
						</div>
						<div className="col-auto my-auto pe-0 d-flex flex-row" style={{paddingLeft: toPix(30)}}>
							<div className="cell-container">
								<p>Show:</p>
							</div>
							<input
								className="form-control"
								type="text"
								value={pageSize}
								size={2}
								style={{width: "3.75rem"}}
								onChange={(e) => onChangePageSize(e.target.value)}
							/>
						</div>
					</>}
				{!loading &&
					<Pagination page={page} pages={pages} onChangePage={onChangePage} />
				}
				{!loading && !bottomRow &&
					<Button
						className="btn btn-sm btn-primary ms-3"
						onClick={onRefresh}
					>
						<img
							width={24}
							height={24}
							alt=""
							src={RefreshButton}
						/>
					</Button>
				}
			</div>
			<RightEdge width={tableConfig.rightEdgeWidth} />
		</div>
	)
}

/*
				<img
					alt=""
					className="my-auto ms-3 p-button"
					src={RefreshButton}
				/>

*/

export default PaginationRow;
