
import CellProps from 'TableCard/classes/cellProps';
import ICellProps from 'TableCard/interfaces/ICellProps';
const classNames = require('classnames');

interface ILeftEdgeCellProps extends ICellProps {
	fixedWidth: number,
}

export class LeftEdgeCellProps extends CellProps implements ILeftEdgeCellProps {
	type: "left-edge" | "right-edge" | "cell";

	constructor(options:ILeftEdgeCellProps) {
		super(options);
		this.type = "left-edge";
		this.fixedWidth = options.fixedWidth;
	}
}

function toPix(n:number) {
	return `${n}px`;
}

interface EdgeProps {
	children?: any,
	classes?: string | Record<string, any>,
	width: number,
	onClick?: React.MouseEventHandler<HTMLDivElement>,
}

function LeftEdge({children, classes, width, onClick}:EdgeProps) {
	return (
		<div
			className={classNames("d-flex", "left-edge", classes)}
			style={{width: toPix(width)}}
			onClick={onClick}
		>
			{children}
		</div>
	);
}

export default LeftEdge;
