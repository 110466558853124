
import { Fragment, useContext } from 'react';
import IImageCellProps from 'TableCard/interfaces/IImageCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps'
import ContentCellProps from 'TableCard/classes/contentCellProps'
import RowContext from '../RowContext';
import Badge from 'react-bootstrap/Badge';
const classNames = require('classnames');

interface IStaticCellProps extends IContentCellProps, IImageCellProps {
	badges?: Record<string, any>[];
}

class StaticCellProps extends ContentCellProps implements IStaticCellProps {
	badges: Record<string, any>[];
	image?: string;

	constructor(options:IStaticCellProps) {
		super(options);
		this.component = StaticCell;
		this.isStatic = true;
		this.badges = options.badges ?? [];
		this.image = options.image;
	}
}

interface StaticCellReactProps {
	values: Record<string, string>;
	cellProps: StaticCellProps;
	includeLabel: boolean;
}

export function StaticCell({values, cellProps, includeLabel}:StaticCellReactProps) {
	const rowContext = useContext(RowContext);
	const realRow = rowContext["id"] === values["id"] ? rowContext : values;
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<p className={classNames(cellProps.contentClasses)}>
					{cellProps.image && realRow[cellProps.image] &&
						<img alt="" className="p-prepend-icon" src={`/images/${realRow[cellProps.image]}`} />
					}
					<span>{realRow[cellProps.tag] || cellProps.default}</span>
					{
						cellProps.badges.filter((badge:Record<string, any>) => {
							return realRow[badge.tag] && (!badge.filter || badge.filter(realRow[badge.tag]));
						}).map((badge:any, index:number) => {
							const badgeStyle = (badge.badgeStyle && badge.badgeStyle(realRow[badge.tag])) || "primary";
							const value = badge.translate ? badge.translate(realRow[badge.tag]) : realRow[badge.tag];
							return (
								<Fragment key={index}>
									<span>&nbsp;</span>
									<Badge bg={badgeStyle} pill>
										{value}
									</Badge>
								</Fragment>
							)
						})
					}
				</p>
			</div>
		</div>
	);
}

export default StaticCellProps;
