
const key = "debugStorage";

class DebugStorage {
	static get():Record<string, string|number> {
		const localContext = localStorage.getItem(key);
		return localContext ? JSON.parse(localContext) : {
			serviceOrderDiagnostics: 0,
			statusBlockDiagnostics : 0,
			fetchPendingTimeoutOverride: 0,
			latency: 0,
			failAllFetches: 0,
			showBreakpoints: 0,
		};
	}

	static hasServiceOrderDiagnostics():boolean {
		const debugStorage = DebugStorage.get();
		return (debugStorage.serviceOrderDiagnostics as number) !== 0;
	}

	static hasStatusBlockDiagnostics():boolean {
		const debugStorage = DebugStorage.get();
		return (debugStorage.statusBlockDiagnostics as number) !== 0;
	}

	static getFetchPendingTimeoutOverride():number {
		const debugStorage = DebugStorage.get();
		return debugStorage.fetchPendingTimeoutOverride as number;
	}

	static getLatency():number {
		const debugStorage = DebugStorage.get();
		return debugStorage.latency as number;
	}

	static hasFailAllFetches():boolean {
		const debugStorage = DebugStorage.get();
		return (debugStorage.failAllFetches as number) !== 0;
	}

	static set(debugStorage:Record<string, string|number>):void {
		localStorage.setItem(key, JSON.stringify(debugStorage));
	}

	static hasShowBreakpoints():boolean {
		const debugStorage = DebugStorage.get();
		return (debugStorage.showBreakpoints as number) !== 0;
	}
}

export default DebugStorage;
