
import React, { useEffect, useState } from 'react';
import AychIcon from 'assets/aych.png';
import useFetch from 'hooks/useFetch';
import StatusBlock from 'views/components/StatusBlock';
import 'styles/login.scss';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ServerRoutes from 'serverRoutes';
import { AuthStoragePayload } from './authStorage';

interface LoginProps {
	onSetAuthStorage: (newAuthContext: AuthStoragePayload) => void;
}

interface FetchedUserResponse {
	data: {
		user: Record<string, any>;
		jwtToken: string;
		refreshToken: string;
	}
}

function Login({onSetAuthStorage}:LoginProps) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback);
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [loadingMessage, setLoadingMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		document.body.style.backgroundColor = "#3498db";
	}, []);
	
	function responseCallback(response:FetchedUserResponse) {
		console.log('got response');
		if (!response.data.user || !response.data.jwtToken || !response.data.refreshToken) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		onSetAuthStorage({
			user: response.data.user,
			jwtToken: response.data.jwtToken,
			refreshToken: response.data.refreshToken,
		});
		setErrorMessage("");
		document.body.style.backgroundColor = "var(--bs-light)";
	}

	function pendingCallback(value:boolean) {
	}

	function waitingCallback(value:boolean) {
		setLoadingMessage(value ? "Verifying credentials..." : "");
	}

	function errorMessageCallback(message:string) {
		//console.log(`errorMessageCallback message= ${message}`)
		setErrorMessage(message);
	}

	function onSubmit(event:any) {
		setFetchOptions({method: 'post', url: ServerRoutes.LOGIN.getUrl(), data: { username: name, password: password}});
		event.preventDefault();
	}

	return (
		<div className="container login-container">
			<Row>
				<div className="bounding-col mx-auto">
					<Card>
						<Card.Body>
							<form onSubmit={onSubmit}>
								<Row>
									<div className="image-col">
										<img
											alt=""
											className="login-image"
											src={AychIcon}>
										</img>
									</div>
									<div className="controls-col">
										<div className="form-group w-100">
											<input
												className="form-control mb-3"
												name="username"
												type="text"
												placeholder="Username"
												onChange={(e) => {
													setName(e.target.value);
													e.stopPropagation();
												}}
											/>
											<input
												className="form-control mb-3"
												name="password"
												type="password"
												placeholder="Password"
												onChange={(e) => {
													setPassword(e.target.value);
													e.stopPropagation();
												}}
											/>
											<input
												className="form-control btn btn-primary"
												type="submit"
												value="Sign in"
											/>
										</div>
										<StatusBlock id={1} errorMessage={errorMessage} infoMessage={loadingMessage} />
									</div>
								</Row>
							</form>
						</Card.Body>
					</Card>
				</div>
			</Row>
		</div>
	)	
}

export default Login;
