
import React, { Fragment, useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import DatetimePickerProps, { DatetimePickerCell } from 'TableCard/cells/DatetimePickerCell';
import getProgressRows, { SubCellType, SubCell, ProgressCell } from './progress/progressRows';
import useResizeObserver from "use-resize-observer";
import DebugStorage from 'debugStorage';
import PickerCellProps, { PickerCell } from 'TableCard/cells/PickerCell';
const classNames = require('classnames');
const Schema = require('portal-schema')

const ASSIGNED_EMPLOYEE_IDS = 'assigned_employee_ids'; // Assigned employee ids in the same order as ASSIGNED_EMPLOYEE_NAMES
const ASSIGNED_EMPLOYEE_NAMES = 'assigned_employee_names'; // Assigned employee names, in alphabetical order.
const ASSIGNED_GROUP_IDS = "assigned_group_ids";
const ASSIGNED_GROUP_NAMES = "assigned_group_names";

interface ProgressPageProps {
	changed: boolean;
	serviceOrderFields: any;
	onServiceOrderChanged: any;
}

function ProgressPage({changed, serviceOrderFields, onServiceOrderChanged}:ProgressPageProps) {
	const [progressRows, setProgressRows] = useState<any>([]);
	const [breakpoint, setBreakpoint] = useState("sm");

	const masterStatus = serviceOrderFields[Schema.ServiceOrder.MASTER_STATUS];
	useEffect(() => {
		setProgressRows(getProgressRows(serviceOrderFields, masterStatus, breakpoint));
	}, [serviceOrderFields, masterStatus, breakpoint])

	function onServiceOrderCheckboxChanged(event:any, value:any) {
		onServiceOrderChanged({target: {name: event.target.name, value: value.toString() !== '0' ? '0' : '1'}});
	}

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize: ({ width, height }) => {
			width = width ?? 0;
			let breakpoint;
			if (width >= 1200) {
				breakpoint = "xl";
			} else if (width >= 992) {
				breakpoint = "lg";
			} else if (width >= 768) {
				breakpoint = "md";
			} else if (width >= 576) {
				breakpoint = "sm";
			} else {
				breakpoint = "xs";
			}
			setBreakpoint(breakpoint);
		},
	});

	return (
		<Fragment>
			<Card ref={ref} className={classNames("mb-3", {
				"form-page--modified": changed
			})}>
				<Card.Body>
					{DebugStorage.hasShowBreakpoints() &&
						<p>{`Size = ${breakpoint}`}</p>
					}
					<div className="d-flex">
						<div className="progress-panel__arrow d-flex flex-column" style={{width: "32px"}} />
					</div>
					<Row>
						<Col lg={6}>
							<Form.Check
								inline
								id={`progress-call-checkbox`}
								type="checkbox"
								name={Schema.ServiceOrder.CALLED_STATUS}
								value="1"
								label="Needs Call?"
								checked={serviceOrderFields?.[Schema.ServiceOrder.CALLED_STATUS]?.toString() !== '0'}
								onChange={(e) => onServiceOrderCheckboxChanged(e, serviceOrderFields[Schema.ServiceOrder.CALLED_STATUS])}
							/>
						</Col>
					</Row>
					<hr />
					{progressRows.map((row:ProgressCell[], rowIndex:number) => {
						return (
							<div className="d-flex" key={rowIndex}>
								{row.map((progressCell:ProgressCell, progressCellIndex:number) => {
									return (
										<div
											key={progressCellIndex}
											className={classNames("d-flex", "flex-column", progressCell.classes)}
											style={progressCell.styles}
										>
											{progressCell.subCells.map((subCell:SubCell, subCellIndex:number) => {
												return (
													<Fragment key={subCellIndex}>
														{subCell.type === SubCellType.IMAGE &&
															<img alt="" className="w-100 h-100" src={subCell.resource} />}
													</Fragment>
												)
											})}
											<div className="m-auto">
												{progressCell.subCells.map((subCell:SubCell, subCellIndex:number) => {
													return (
														<Fragment key={subCellIndex}>
															{subCell.type === SubCellType.CHECKBOX &&
																<div
																	className={classNames("d-flex", "form-check", subCell.classes)}
																>
																	<Form.Check
																		inline
																		id={`service-order-checkbox-${subCell.tag}-${subCell.value}`}
																		type="checkbox"
																		name={subCell.tag}
																		value="1"
																		label={subCell.label}
																		checked={serviceOrderFields?.[subCell.tag]?.toString() !== '0'}
																		onChange={(e) => onServiceOrderCheckboxChanged(e, serviceOrderFields[subCell.tag])}
																	/>
																</div>}
															{subCell.type === SubCellType.RADIO &&
																<div className={classNames("d-flex", subCell.classes)}>
																	<Form.Check
																		inline
																		id={`service-order-radio-${subCell.tag}-${subCell.value}`}
																		type="radio"
																		name={subCell.tag}
																		value={subCell.value}
																		checked={serviceOrderFields?.[subCell.tag]?.toString() === subCell.value?.toString()}
																		label={subCell.label}
																		onChange={(e) => onServiceOrderChanged(e)}
																	/>
																</div>}
															{subCell.type === SubCellType.STATIC &&
																<div className={classNames("d-flex", subCell.classes)}>
																	<p className="m-auto">{subCell.label}</p>
																</div>}
														</Fragment>
													)
												})}
											</div>
										</div>
									)
								})}
							</div>
						)})}
					<hr />
					<Row>
						<Col xl={12}>
							<PickerCell
								cellProps={new PickerCellProps({
									tag: "",
									label: "Assigned Employees and Groups",
									employeeNameTextTag: ASSIGNED_EMPLOYEE_NAMES,
									employeeIdsTag: ASSIGNED_EMPLOYEE_IDS,
									groupNameTextTag: ASSIGNED_GROUP_NAMES,
									groupIdsTag: ASSIGNED_GROUP_IDS,
									percentWidth: 100,
									dialogTitle: "Assign This Job",
									dialogText: "Assign employees and/or groups of employees to this job.",
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								includeLabel={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col xl={4}>
							<SelectCell
								cellProps={new SelectCellProps({
									label: Schema.ServiceOrder.PRIORITY_LABEL,
									tag: Schema.ServiceOrder.PRIORITY,
									selectOptions: Schema.ServiceOrder.PRIORITIES,
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								includeLabel={true}
							/>
						</Col>
						<Col xl={8}>
							<DatetimePickerCell
								cellProps={new DatetimePickerProps({
									label: Schema.ServiceOrder.DUE_DATE_LABEL,
									tag: Schema.ServiceOrder.DUE_DATE,
								})}
								values={serviceOrderFields}
								onChange={onServiceOrderChanged}
								includeLabel={true}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Fragment>		
	)
}

export default ProgressPage;
