
import React from 'react';
import AychIcon from 'assets/aych.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ServerRoutes from 'serverRoutes';
import AuthStorage from 'authStorage';
import DebugStorage from 'debugStorage';
const Schema = require('portal-schema');

class Security {
	static canPass(req:any, accessTag:any, op:any) { return true; }
}

interface ElementOptions {
	label: string;
	url: string;
	accessTag: string;
	op: string;
	fn?: () => boolean;
}

class Element implements ElementOptions {
	label: string;
	url: string;
	accessTag: string;
	op: string;
	fn?: () => boolean;


	constructor(options:ElementOptions) {
		this.accessTag = options.accessTag;
		this.label = options.label;
		this.op = options.op ?? Schema.PrivilegeGroup.OP_VIEW;
		this.url = options.url;
		this.fn = options.fn;
	}    
}

interface NavBarCategoryOptions {
	title: string;
	elements: Element[];
}

class NavBarCategory implements NavBarCategoryOptions {
	title: string;
	elements: Element[];

	constructor(options:NavBarCategoryOptions) {
		this.title = options.title;
		this.elements = options.elements;
	}
}

const MAIN_CATEGORIES = [
	{
		title: "Contract Work",
		elements: [
			new Element({
				label: Schema.Client.THING_CAP_PLURAL,
				url: ServerRoutes.CLIENTS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.CLIENT,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Workorder.THING_CAP_PLURAL,
				url: ServerRoutes.WORKORDERS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.WORKORDER,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
		],
	},
	{
		title: "Retail Customers",
		elements: [
			new Element({
				label: Schema.ServiceOrder.THING_CAP_PLURAL,
				url: ServerRoutes.SERVICE_ORDER.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.SERVICE_ORDER,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Appointment.THING_CAP_PLURAL,
				url: ServerRoutes.APPOINTMENTS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.APPOINTMENT,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
		],
	},
	{
		title: "Inventory",
		elements: [
			new Element({
				label: Schema.Item.THING_CAP_PLURAL,
				url: ServerRoutes.ITEMS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.ITEM,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
		],
	},
	{
		title: "Employees",
		elements: [
			new Element({
				label: "Phone List",
				url: ServerRoutes.PHONE_LIST.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.EMPLOYEE,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Employee.THING_CAP_PLURAL,
				url: ServerRoutes.EMPLOYEES.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.EMPLOYEE,
				op: Schema.PrivilegeGroup.OP_UPDATE,
			}),
			new Element({
				label: Schema.TimesheetDay.THING_CAP_PLURAL,
				url: ServerRoutes.TIMESHEET_LIST.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.TIMESHEET_DAY,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Egroup.THING_CAP_PLURAL,
				url: ServerRoutes.GROUPS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.EGROUP,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
		],
	},
	{
		title: "Administrative",
		elements: [
			new Element({
				label: Schema.Category.THING_CAP_PLURAL,
				url: ServerRoutes.CATEGORIES.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.CATEGORY,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Location.THING_CAP_PLURAL,
				url: ServerRoutes.LOCATIONS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.LOCATION,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.SystemConfig.THING_CAP_PLURAL,
				url: ServerRoutes.SYSTEM_CONFIG.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.SYSTEM_CONFIG,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.LoginLog.THING_CAP_PLURAL,
				url: ServerRoutes.LOGIN_LOG.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.LOGIN_LOG,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.Log.THING_CAP_PLURAL,
				url: ServerRoutes.LOG.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.LOG,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
			new Element({
				label: Schema.PrivilegeGroup.THING_CAP_PLURAL,
				url: ServerRoutes.PRIVILEGE_GROUPS.getUrl(),
				accessTag: Schema.PrivilegeGroup.AccessTag.PRIVILEGE_GROUP,
				op: Schema.PrivilegeGroup.OP_VIEW,
			}),
		],
	},
];

class MyNavBar  {
	categories: NavBarCategory[];

	constructor(req:any, categories:{title: string, elements: Element[]}[]) {
		this.categories = [];
		for (const category of categories) {
			let elements:Element[] = [];
			for (const element of category.elements) {
				if ((!element.accessTag || Security.canPass(req, element.accessTag, element.op)) && (!element.fn || element.fn())) {
					elements.push(element);
				}
			}
			if (elements.length > 0) {
				this.categories.push(new NavBarCategory({
					title: category.title,
					elements: elements,
				}));
			}
		}
	}
	
	static getMain(req:any) {
		return new MyNavBar(req, MAIN_CATEGORIES);
	}

	static getPersonalMenu(req:any, authStorage:any) {
		return new MyNavBar(req, [
			{
				title: authStorage.user?.[Schema.Employee.USERNAME],
				elements: [
					{
						label: "My Information",
						url: ServerRoutes.MY_INFORMATION.getUrl(),
						accessTag: Schema.PrivilegeGroup.AccessTag.EMPLOYEE,
						op: Schema.PrivilegeGroup.OP_EDIT_MY,
					},
					{
						label: "My Appointments",
						url: ServerRoutes.MY_APPOINTMENTS.getUrl(),
						accessTag: Schema.PrivilegeGroup.AccessTag.APPOINTMENT,
						op: Schema.PrivilegeGroup.OP_VIEW,
					},
					{
						label: "My Timesheet",
						url: ServerRoutes.MY_TIMESHEET.getUrl(),
						accessTag: Schema.PrivilegeGroup.AccessTag.TIMESHEET_DAY,
						op: Schema.PrivilegeGroup.OP_EDIT_MY,
						fn:
							() => {
								return true // req.authStorage?.[Schema.Employee.HAS_TIMESHEET];
							}
					},
				],
			},
		]);
	}
}

interface NavBarComponentProps {
	logOut: any;
	description?: string;
	setShowModal: any;
}

function NavBarComponent({logOut, description, setShowModal}:NavBarComponentProps) {
	const authStorage = AuthStorage.get();
	const primary = MyNavBar.getMain(null);
	const secondary = MyNavBar.getPersonalMenu(null, authStorage);
	//console.warn('mynavbar, setShowModal =', setShowModal);
	return (
		<Navbar bg="light" expand="lg">
			<Navbar.Brand className="d-flex flex-row">
				<img className="my-auto" src={AychIcon} width="48" height="48" alt="" />
				<span className="navbar-text active ms-3 my-auto" style={{fontSize: "2.0rem"}}>
					{description}
				</span>
				{DebugStorage.hasShowBreakpoints() &&
					<>
						<span className="ms-3 my-auto d-block d-sm-none">XS</span>
						<span className="ms-3 my-auto d-none d-sm-block d-md-none">SM</span>
						<span className="ms-3 my-auto d-none d-md-block d-lg-none">MD</span>
						<span className="ms-3 my-auto d-none d-lg-block d-xl-none">LG</span>
						<span className="ms-3 my-auto d-none d-xl-block">XL</span>
					</>
				}
			</Navbar.Brand>
			<Navbar.Toggle className="ms-auto" aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav" className="ms-4">
				<Nav className="me-auto">
					{primary.categories.map((category:any, index:number) => {
						return (
							<NavDropdown key={index} title={
								<span style={{fontSize: "1.5rem"}}>
									{category.title}
								</span>
							}>
								{category.elements.map((element:any, innerIndex:number) => {
									return (
										<NavDropdown.Item key={innerIndex} href={element.url}>
											{element.label}
										</NavDropdown.Item>
									)
								})}
							</NavDropdown>
						)
					})}
				</Nav>
				<Nav className="container-fluid justify-content-end">
					{secondary.categories.map((category:any, index:number) => {
						return (
							<NavDropdown key={index} align="end" title={
								<span style={{fontSize: "1.5rem"}}>
									{category.title}
								</span>
							}>
								{category.elements.map((element:any, innerIndex:number) => {
									return (
										<NavDropdown.Item key={innerIndex} href={element.url}>
											{element.label}
										</NavDropdown.Item>
									)
								})}
								<NavDropdown.Item onClick={logOut}>
									Log Out
								</NavDropdown.Item>
								<NavDropdown.Item href="/test">
									Test
								</NavDropdown.Item>
								<NavDropdown.Item onClick={() => setShowModal(true)}>
									Debug
								</NavDropdown.Item>
							</NavDropdown>
						)
					})}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default NavBarComponent;
