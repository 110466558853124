
class FormatUtils {
	static zpad(i:number, width = 2):string {
		return ("0" + i).slice(-width);
	}

	static formatDateTime(value:string):string {
		try {
			const d = new Date(value);
			return FormatUtils.zpad(d.getMonth() + 1) + '/' +
				FormatUtils.zpad(d.getDate()) + '/' +
				FormatUtils.zpad(d.getFullYear(), 4) + ' ' +
				FormatUtils.zpad(d.getHours()) + ':' +
				FormatUtils.zpad(d.getMinutes()) + ':' +
				FormatUtils.zpad(d.getSeconds());
		} catch (error) {
			return "";
		}
	}

	static formatDateYYYYMMDD(value:string):string {
		try {
			const d = new Date(value);
			return FormatUtils.zpad(d.getFullYear(), 4) + '/' +
				FormatUtils.zpad(d.getMonth() + 1) + '/' +
				FormatUtils.zpad(d.getDate());
		} catch (error) {
			return "";
		}
	}

	static formatDateMMDDYY(value:string):string {
		try {
			const d = new Date(value);
			return FormatUtils.zpad(d.getMonth() + 1) + '/' +
				FormatUtils.zpad(d.getDate()) + '/' +
				FormatUtils.zpad(d.getFullYear() % 100);
		} catch (error) {
			return "";
		}
	}

	static formatDateMMDDYYYY(value:string):string {
		try {
			const d = new Date(value);
			return FormatUtils.zpad(d.getMonth() + 1) + '/' +
				FormatUtils.zpad(d.getDate()) + '/' +
				FormatUtils.zpad(d.getFullYear(), 4);
		} catch (error) {
			return "";
		}
	}

	static formatDollars(c:any):string {
		try {
			return c.toLocaleString('us-US', { style: 'currency', currency: 'USD' });
		} catch (error) {
			return "";
		}
	}

	static formatTwoDecimals(value:number):string {
		try { // Undone this is kind of stupid.
			const valueString = value.toString();
			const valueFloat = parseFloat(valueString);
			return valueFloat.toFixed(2);
		} catch (error) {
			return "";
		}
	}

	static formatZeroDecimals(value:number):string {
		try {
			const valueString = value.toString();
			const valueFloat = parseFloat(valueString);
			return valueFloat.toFixed(0);
		} catch (error) {
			return "";
		}
	}

	static formatDateTimeForHtml(value:string):string {
		try {
			const d = new Date(value);
			return FormatUtils.zpad(d.getFullYear(), 4) + '-' +
				FormatUtils.zpad(d.getMonth() + 1) + '-' +
				FormatUtils.zpad(d.getDate()) + 'T' +
				FormatUtils.zpad(d.getHours()) + ':' +
				FormatUtils.zpad(d.getMinutes());
		} catch (error) {
			console.log(`can't parse ${value}`);
			console.log(error);
			return "";
		}
	}
	static dateToHtml(d:Date):string {
		try {
			return FormatUtils.zpad(d.getFullYear(), 4) + '-' +
				FormatUtils.zpad(d.getMonth() + 1) + '-' +
				FormatUtils.zpad(d.getDate()) + 'T' +
				FormatUtils.zpad(d.getHours()) + ':' +
				FormatUtils.zpad(d.getMinutes());
		} catch (error) {
			return "";
		}
	}

	// -------- Currently unused below here. --------

	static formatTwoDecimalsCommas(value:number):string {
		try {
			return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, });
		} catch (error) {
			return "";
		}
	}

}

export default FormatUtils;
