
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import TableConfig from 'TableCard/classes/tableConfig';
const classNames = require('classnames');

class IdCellProps extends ContentCellProps {
	constructor(options:IContentCellProps) {
		super(options);
		this.component = IdCell;
		this.label = options.label ?? "ID";
		this.tag = options.tag ?? "id";
		this.fixedWidth = options.fixedWidth ?? TableConfig.NORMAL_ID_WIDTH;
		this.cellContainerClasses = {...options.cellContainerClasses, "id-cell": true, "align-right": true};
	}
}

interface IIdCellReactProps {
	cellProps: IdCellProps;
	values: Record<string, string>;
	isRowOpen?: boolean;
	canRowToggle?: boolean;
}

export function IdCell({cellProps, values, isRowOpen, canRowToggle}:IIdCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				<p className={classNames({
					"list-closed": canRowToggle && !isRowOpen,
					"list-open": canRowToggle && isRowOpen,
				})}>
					{values[cellProps.tag] || "New"}
				</p>
			</div>
		</div>
	);
}

export default IdCellProps;
