
import React, { useState, useMemo, useEffect, Fragment, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ServiceOrderContext from './ServiceOrderContext';
import ServiceOrderList from './serviceOrderList/ServiceOrderList';
import ServiceOrderItemList from './invoice/ServiceOrderItemList';
import ServiceOrderNotes from './notes/ServiceOrderNotes';
import ServiceOrderAppointments from './appointments/ServiceOrderAppointments';
import ClientList from './clientList/ClientList';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import StatusBlock from 'views/components/StatusBlock';
import useFetch, { FetchedResponse, FetchedSelectOptions, FetchMode } from 'hooks/useFetch';
import AbandonModal from './AbandonModal';
import LoadingPageModal from './invoice/LoadingPageModal';
import useFieldManager, { importRow, exportRow, importSelectOptions, ImportRow, ExportRow } from 'hooks/useFieldManager'
import RowContext from 'TableCard/RowContext';

import ClientPage from './ClientPage';
import DetailsPage from './DetailsPage';
import ProgressPage from './ProgressPage';
import DebugPane from './DebugPane';
import InvoicePage from './InvoicePage';
import ServerRoutes from 'serverRoutes';
import DebugStorage from 'debugStorage';
import FormatUtils from 'utils/formatUtils';

const classNames = require('classnames');
const Schema = require('portal-schema')

const formatServiceOrder:Record<any, (value: any) => string> = {
	[Schema.ServiceOrder.SUBTOTAL]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.SUBTOTAL_AFTER_DISCOUNT]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.TAX]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.TOTAL]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.PAYMENTS]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.BALANCE]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.REFUND]: (value:number) => FormatUtils.formatTwoDecimals(value),
	[Schema.ServiceOrder.DISCOUNT]: (value:number) => FormatUtils.formatZeroDecimals(value),
	[Schema.ServiceOrder.CREATED]: (value:string) => FormatUtils.formatDateTime(value),
	[Schema.ServiceOrder.UPDATED]: (value:string) => FormatUtils.formatDateTime(value),
	[Schema.ServiceOrder.DUE_DATE]: (value:string) => FormatUtils.formatDateTimeForHtml(value),
}

const ASSIGNED_EMPLOYEE_IDS = 'assigned_employee_ids'; // Assigned employee ids in the same order as ASSIGNED_EMPLOYEE_NAMES
const ASSIGNED_GROUP_IDS = "assigned_group_ids";

const serviceOrderPayloadTags:string[] = [
	Schema.ServiceOrder.ID,
	Schema.ServiceOrder.REFERRED_BY,
	Schema.ServiceOrder.CUSTOMER_COMMENTS,
	Schema.ServiceOrder.TAKEN_TYPE,
	Schema.ServiceOrder.COMPUTER_USERNAME,
	Schema.ServiceOrder.COMPUTER_PASSWORD,
	Schema.ServiceOrder.INV_EST_NO,
	Schema.ServiceOrder.EQUIPMENT_DESC,
	Schema.ServiceOrder.SERIAL,
	Schema.ServiceOrder.ACCESSORIES,
	Schema.ServiceOrder.SHIPPING_ADDRESS1,
	Schema.ServiceOrder.SHIPPING_ADDRESS2,
	Schema.ServiceOrder.SHIPPING_CITY,
	Schema.ServiceOrder.SHIPPING_STATE,
	Schema.ServiceOrder.SHIPPING_ZIP,
	Schema.ServiceOrder.CLIENT,
	Schema.ServiceOrder.TAKEN_BY,
	Schema.ServiceOrder.SERVICE_LOCATION,
	Schema.ServiceOrder.PRIORITY,
	Schema.ServiceOrder.TAX_RATE,
	Schema.ServiceOrder.DISCOUNT,
	Schema.ServiceOrder.CANCELLED,
	Schema.ServiceOrder.PART_ORDER_STATUS,
	Schema.ServiceOrder.PAYMENT_EXPECTATION,
	Schema.ServiceOrder.WORK_STATUS,
	Schema.ServiceOrder.ITEM_LOCATION_STATUS,
	Schema.ServiceOrder.CALLED_STATUS,
	Schema.ServiceOrder.DUE_DATE,
	ASSIGNED_EMPLOYEE_IDS,
	ASSIGNED_GROUP_IDS,
];

const formatClient:Record<any, (value: any) => string> = {
}

const clientPayloadTags:string[] = [
	Schema.Client.ID,
	Schema.Client.COMPANY_NAME,
	Schema.Client.FIRST_NAME,
	Schema.Client.LAST_NAME,
	Schema.Client.OVERVIEW,
	Schema.Client.NOTES,
	Schema.Client.MAIN_PHONE,
	Schema.Client.CELL_PHONE,
	Schema.Client.FAX,
	Schema.Client.EMAIL,
	Schema.Client.WEB_PAGE,
	Schema.Client.ADDRESS1,
	Schema.Client.ADDRESS2,
	Schema.Client.CITY,
	Schema.Client.STATE,
	Schema.Client.ZIP,
];

function ServiceOrder({getDescription}:{getDescription:any}) {
	const params = useParams();
	const navigate = useNavigate();
	// The invoice is not displayed until the tab control button is pressed. The idea is to allow useBreakpoint to measure
	// the embedded controls after they are displayed, otherwise they come across as zero size.
	// Various loading/loaded states used mainly to control bad-state display.
	const [serviceOrderContext, setServiceOrderContext] = useState({
		loadingServiceOrderId: params.id ?? "",
		loadingClientId: "",
		loadedServiceOrderId: "",
		loadedClientId: "",
		// First load is different because the client/details panel have to wait for the service order list to figure out if
		// it wants to display a default service order.
		initialLoading: params.id === undefined,
	})
	const underlyingServiceOrderContext = useMemo(
		() => ({
			serviceOrderContext,
			setServiceOrderContext
		}),
		[serviceOrderContext]);
	// Allows me to manually set selected tabs in tab controls.
	const [selectedOuterTab, setSelectedOuterTab] = useState<any>("serviceOrder");
	const [selectedInnerTab, setSelectedInnerTab] = useState<any>("details");

	const [setLoadFetchOptions] = useFetch(loadResponseCallback, pendingCallback, loadingCallback, errorMessageCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [fetchedData, setFetchedData] = useState({});

	const [setClientFieldManagerActions, clientFields, setClientFields, clientChanged] = useFieldManager({});
	const [setServiceOrderFieldManagerActions, serviceOrderFields, setServiceOrderFields, serviceOrderChanged] = useFieldManager({});

	const [errorMessage, setErrorMessage] = useState("");
	const [savingMessage, setSavingMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const [showAbandonModal, setShowAbandonModal] = useState(false);

	const [serviceOrderNotesCount, setServiceOrderNotesCount] = useState(0);
	const [appointmentsCount, setAppointmentsCount] = useState(0);

	const [setSaveFetchOptions] = useFetch(savedCallback, pendingCallback, savingCallback, errorMessageCallback);

	const [loadingPageModalId, setLoadingPageModalId] = useState(undefined);
	const [loadingPageModalIsVisible, setLoadingPageModalIsVisible] = useState(false);
	const [invoiceRefetchCounter, setInvoiceRefetchCounter] = useState(0);
	const [serviceOrderListRefetchCounter, setServiceOrderListRefetchCounter] = useState(0);
	const [clientListRefetchCounter, setClientListRefetchCounter] = useState(0);

	const [selectedServiceOrderRowFields, setSelectedServiceOrderRowFields] = useState({});
	const [selectedClientRowFields, setSelectedClientRowFields] = useState({});

	/*
	alter table techorder modify column master_status2 varchar(255) generated always as (
		if(`cancelled` <> 0,17000,
			if(`part_order_status` <> 30,`part_order_status`,
				if(`work_status` = 0,12000,
					if((`payment_expectation` <> 20) and (`total` = 0),13000,
						if(((`payment_expectation` = 20) and (`payments` <> 0)) or (`balance` < 0.00),14000,
							if((`payment_expectation` <> 20) and (`balance` <> 0),15000,
								if(`item_location_status` = 16000,16000,18000)))))))) STORED;
	*/

	const cancelled = serviceOrderFields[Schema.ServiceOrder.CANCELLED];
	const partOrderStatus = serviceOrderFields[Schema.ServiceOrder.PART_ORDER_STATUS];
	const workStatus = serviceOrderFields[Schema.ServiceOrder.WORK_STATUS];
	const paymentExpectation = serviceOrderFields[Schema.ServiceOrder.PAYMENT_EXPECTATION];
	const total = serviceOrderFields[Schema.ServiceOrder.TOTAL];
	const payments = serviceOrderFields[Schema.ServiceOrder.PAYMENTS];
	const balance = serviceOrderFields[Schema.ServiceOrder.BALANCE];
	const refund = serviceOrderFields[Schema.ServiceOrder.REFUND];
	const itemLocationStatus = serviceOrderFields[Schema.ServiceOrder.ITEM_LOCATION_STATUS];
	useEffect(() => {
		const floatBalance = isNaN(Number.parseFloat(balance)) ? 0.00 : Number.parseFloat(balance);
		const floatRefund = isNaN(Number.parseFloat(refund)) ? 0.00 : Number.parseFloat(refund);
		const floatPayments = isNaN(Number.parseFloat(payments)) ? 0.00 : Number.parseFloat(payments);
		const floatTotal = isNaN(Number.parseFloat(total)) ? 0.00 : Number.parseFloat(total);
		const calculatedMasterStatus =
			cancelled !== "0" ? Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED :
				partOrderStatus !== Schema.ServiceOrder.ProgressConstants.HAVE_ALL_PARTS ? partOrderStatus?.toString() :
					workStatus === "0" ? Schema.ServiceOrder.ProgressConstants.WORK_INCOMPLETE :
						paymentExpectation !== Schema.ServiceOrder.ProgressConstants.FREE && floatTotal === 0.00 ? Schema.ServiceOrder.ProgressConstants.UNBILLED :
							(paymentExpectation === Schema.ServiceOrder.ProgressConstants.FREE && floatPayments !== 0.00) || floatRefund > 0.00 ? Schema.ServiceOrder.ProgressConstants.REFUND_DUE :
								paymentExpectation !== Schema.ServiceOrder.ProgressConstants.FREE && floatBalance > 0.00 ? Schema.ServiceOrder.ProgressConstants.UNPAID :
									itemLocationStatus === Schema.ServiceOrder.ProgressConstants.IN_STORE ? Schema.ServiceOrder.ProgressConstants.IN_STORE : Schema.ServiceOrder.ProgressConstants.COMPLETE;
		setServiceOrderFields((prev:any) => ({...prev, [Schema.ServiceOrder.MASTER_STATUS]: calculatedMasterStatus}));
		setServiceOrderFieldManagerActions({overwrite: {[Schema.ServiceOrder.MASTER_STATUS]: calculatedMasterStatus}});
		setSelectedServiceOrderRowFields((prev:any) => ({
			...prev,
			[Schema.ServiceOrder.MASTER_STATUS]: calculatedMasterStatus,
		}));
	}, [
		setServiceOrderFields,
		setServiceOrderFieldManagerActions,
		cancelled,
		partOrderStatus,
		workStatus,
		paymentExpectation,
		total,
		payments,
		balance,
		refund,
		itemLocationStatus,
	]);

	const clientId = clientFields[Schema.Client.ID];
	const mainPhone = clientFields[Schema.Client.MAIN_PHONE];
	useEffect(() => {
		setSelectedClientRowFields((prev:any) => ({
			...prev,
			[Schema.Client.ID]: clientId,
			[Schema.Client.MAIN_PHONE]: mainPhone,
		}));
	}, [clientId, mainPhone])

	const serviceOrderId = serviceOrderFields[Schema.ServiceOrder.ID];
	const calledStatus = serviceOrderFields[Schema.ServiceOrder.CALLED_STATUS];
	const priority = serviceOrderFields[Schema.ServiceOrder.PRIORITY];
	const updated = serviceOrderFields[Schema.ServiceOrder.UPDATED];
	useEffect(() => {
		setSelectedServiceOrderRowFields((prev:any) => ({
			...prev,
			[Schema.ServiceOrder.CALLED_STATUS]: calledStatus,
			[Schema.ServiceOrder.PRIORITY]: priority,
			[Schema.ServiceOrder.UPDATED]: updated?.slice(0, 10),
			[Schema.ServiceOrder.ID]: serviceOrderId,
		}));
	}, [calledStatus, priority, updated, serviceOrderId])

	useEffect(() => { getDescription("Service Order" + (serviceOrderId ? " #" + serviceOrderId : "") )}, [getDescription, serviceOrderId])

	const masterStatus = serviceOrderFields[Schema.ServiceOrder.MASTER_STATUS];
	const dueDate = serviceOrderFields[Schema.ServiceOrder.DUE_DATE];
	useEffect(() => {
		setSelectedServiceOrderRowFields((prev:any) => ({
			...prev,
			[Schema.ServiceOrder.WARNING]:
				new Date() > new Date(dueDate) &&
					masterStatus !== Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED &&
					masterStatus !== Schema.ServiceOrder.ProgressConstants.COMPLETE ? "warning.svg" : "",
		}));
	}, [dueDate, masterStatus])

	const firstName = clientFields[Schema.Client.FIRST_NAME];
	const lastName = clientFields[Schema.Client.LAST_NAME];
	const companyName = clientFields[Schema.Client.COMPANY_NAME];
	useEffect(() => {
		let complexName:string;
		if (companyName) {
			complexName = companyName;
		} else {
			complexName = firstName ? firstName : "";
			if (lastName) {
				if (complexName) {
					complexName += ' ';
				}
				complexName += lastName;
			}
		}
		setClientFields((prev:any) => ({...prev, [Schema.Client.COMPLEX_NAME]: complexName}));
		setClientFieldManagerActions({overwrite: {[Schema.Client.COMPLEX_NAME]: complexName}});
		setSelectedServiceOrderRowFields((prev:any) => ({...prev, [Schema.Client.COMPLEX_NAME]: complexName}));
		setSelectedClientRowFields((prev:any) => ({...prev, [Schema.Client.COMPLEX_NAME]: complexName}));
	}, [
		setClientFields,
		setClientFieldManagerActions,
		firstName,
		lastName,
		companyName
	]);

	useEffect(() => {
		setLoadingMessage(serviceOrderContext.initialLoading ? "Loading..." : "");
	}, [serviceOrderContext.initialLoading])

	/*const fetchOptions = useMemo(():FetchOptions => {
		//console.warn('serviceOrder useMemo serviceOrderContext =', serviceOrderContext.loadingServiceOrderId, ',', serviceOrderContext.loadingClientId);
		return {
			url: ServerRoutes.SERVICE_ORDER_POPULATE.getUrl(),
			query: {
				selectedServiceOrderId: serviceOrderContext.loadingServiceOrderId,
				selectedClientId: serviceOrderContext.loadingClientId,
			},
		};
	}, [serviceOrderContext.loadingServiceOrderId, serviceOrderContext.loadingClientId]);*/

	//
	useEffect(() => {
		if (serviceOrderContext.loadingServiceOrderId) {
			setSelectedOuterTab("serviceOrder");
			navigate(ServerRoutes.SERVICE_ORDER.getUrl() + '/' + serviceOrderContext.loadingServiceOrderId);
		}
		// Kills current service order as far as the UI is concerned.
		setServiceOrderContext((prev) => {return {...prev, loadedServiceOrderId: "", loadedClientId: ""}});
		// Sets what is there to unmodified, because otherwise this can cause dumb effects if the fetch fails.
		setClientFieldManagerActions({revert: true});
		setServiceOrderFieldManagerActions({revert: true});
		// Fetch.
		setLoadFetchOptions({
			url: ServerRoutes.SERVICE_ORDER_POPULATE.getUrl(),
			query: {
				selectedServiceOrderId: serviceOrderContext.loadingServiceOrderId,
				selectedClientId: serviceOrderContext.loadingClientId,
			},
		});
	}, [
		setLoadFetchOptions,
		navigate,
		setClientFieldManagerActions,
		setServiceOrderFieldManagerActions,
		serviceOrderContext.loadingServiceOrderId,
		serviceOrderContext.loadingClientId
	]);

	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setLoadFetchOptions({
			url: ServerRoutes.SERVICE_ORDER_POPULATE.getUrl(),
			query: {
				selectedServiceOrderId: serviceOrderContext.loadingServiceOrderId,
				selectedClientId: serviceOrderContext.loadingClientId,
			},
		});
	}

	interface FetchedServiceOrderResponse {
		data: {
			client: ExportRow;
			serviceOrder: ExportRow;
			selectOptions: FetchedSelectOptions;
		}
	}

	function loadResponseCallback(response:FetchedServiceOrderResponse) {
		if (!response.data.client || !response.data.serviceOrder) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		console.log('ServiceOrder Fetch response =', response.data);
		const client = importRow(response.data.client, formatClient);
		setClientFields(client);
		const serviceOrder = importRow(response.data.serviceOrder, formatServiceOrder);
		setServiceOrderFields(serviceOrder);
		setClientFieldManagerActions({accept: true});
		setServiceOrderFieldManagerActions({accept: true});
		response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		setFetchedData(response.data);
		setSelectedInnerTab(!serviceOrderContext.loadingClientId && !serviceOrderContext.loadingServiceOrderId ? "client" : "details");
		if (serviceOrderContext.loadingServiceOrderId || serviceOrderContext.loadingClientId) {
			setServiceOrderContext((prev) => {return {...prev, initialLoading: false}});
			//setLoadingMessage("");
		}
		setServiceOrderContext((prev:any) => {return {
			...prev,
			loadedClientId: client[Schema.Client.ID],
			loadedServiceOrderId : serviceOrder[Schema.ServiceOrder.ID]
		}});
		setServiceOrderNotesCount(0);
		setAppointmentsCount(0);
	}

	function pendingCallback(value:boolean) {
		setPending(value);
	}

	function loadingCallback(value:boolean) {
		if (!value && serviceOrderContext.initialLoading) {
			return;
		}
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function savingCallback(value:boolean) {
		setSavingMessage(value ? "Saving..." : "");
	}

	function savedCallback(response:FetchedResponse) {
		if (!response.data || !response.data.client || !response.data.serviceOrder) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		const client = importRow(response.data.client, formatClient);
		const serviceOrder = importRow(response.data.serviceOrder, formatServiceOrder);
		setServiceOrderFields((prev:any) => ({...prev, ...serviceOrder}));
		setClientFields((prev:any) => ({...prev, ...client}));
		setServiceOrderFieldManagerActions({overwrite: serviceOrder, accept: true});
		setClientFieldManagerActions({overwrite: client, accept: true});
		setServiceOrderListRefetchCounter(prev => prev + 1);
		setClientListRefetchCounter(prev => prev + 1);
		setSuccessMessage("Information saved.");
		setServiceOrderContext((prev:any) => {return {
			...prev,
			loadedClientId: client[Schema.Client.ID],
			loadedServiceOrderId : serviceOrder[Schema.ServiceOrder.ID]
		}});
		const timer = setTimeout(() => {
			setSuccessMessage("");
			clearTimeout(timer);
		}, 1500);
	}

	function onSave(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		console.log('onSave');
		event.preventDefault();
		const data = {
			serviceOrder: exportRow(serviceOrderFields, serviceOrderPayloadTags),
			client: exportRow(clientFields, clientPayloadTags),
		}
		setSaveFetchOptions({url: ServerRoutes.SERVICE_ORDER.getUrl(), data: data, method: 'post'});
	}

	function onReset(e?:any) {
		console.log('onReset');
		if (e) {
			e.preventDefault();
		}
		setServiceOrderFieldManagerActions({revert: true});
		setClientFieldManagerActions({revert: true});
		setErrorMessage("");
	}

	function onServiceOrderChanged(e:any) {
		setServiceOrderFields((prevFields:ImportRow) => {
			return {...prevFields, [e.target.name]: e.target.value};
		});
		setServiceOrderFieldManagerActions({update: {[e.target.name]: e.target.value}});
		setSavingMessage("");
		setErrorMessage("");
	}

	function onClientChanged(e:any) {
		setClientFields((prevFields:any) => {
			return {...prevFields, [e.target.name]: e.target.value};
		});
		setClientFieldManagerActions({update: {[e.target.name]: e.target.value}});
		setSavingMessage("");
		setErrorMessage("");
	}

	function onServiceOrderNotesFetch(data:any) {
		setServiceOrderNotesCount(data.rows.length)
	}

	function onAppointmentsFetch(data:any) {
		setAppointmentsCount(data.rows.length)
	}

	function onAbandon() {
		onReset();
		setServiceOrderContext((prev:any) => { return {...prev, loadingServiceOrderId: "", loadingClientId: ""}});
	}

	function onClickNew(e:any) {
		if (serviceOrderChanged || clientChanged) {
			setShowAbandonModal(true);
		} else {
			onAbandon();
		}
		e.stopPropagation();
	}

	// I have no idea if useCallback is of any use here.

	const onFetchSaveInvoice = useCallback((data:any) => {
		if (data.invoiceFields) {
			const invoiceFields = importRow(data.invoiceFields, formatServiceOrder);
			setServiceOrderFields((prev) => {return {...prev, ...invoiceFields}});
			setServiceOrderFieldManagerActions({overwrite: invoiceFields});
		}
	}, [
		setServiceOrderFields,
		setServiceOrderFieldManagerActions,
	]);

	// I have no idea if useCallback is of any use here.
	
	const onFetchLoadInvoice = useCallback((data:any) => {
		//console.log('onFetchLoadInvoice data=', data);
		if (data.invoiceFields) {
			const invoiceFields = importRow(data.invoiceFields, formatServiceOrder);
			setServiceOrderFields((prev) => {return {...prev, ...invoiceFields}});
			setServiceOrderFieldManagerActions({overwrite: invoiceFields});
		}
	}, [
		setServiceOrderFieldManagerActions,
		setServiceOrderFields,
	]);

	function onServiceOrderItemListRowClick(e:any, row:any) {
		//console.log(row);
		e.stopPropagation();
		setLoadingPageModalId(row[Schema.Item.ID]);
		setLoadingPageModalIsVisible(true);
	}

	function onLoadingModalSuccess(response:FetchedResponse) {
		// If I ever use the response param, it isn't imported yet.
		setInvoiceRefetchCounter((prev:number) => prev + 1);
	};

	const dataMemo = useMemo(() => {
		return {
			[Schema.InvoiceItem.ITEM]: loadingPageModalId,
			[Schema.InvoiceItem.SERVICE_ORDER]: serviceOrderContext.loadedServiceOrderId,
		}
	}, [loadingPageModalId, serviceOrderContext.loadedServiceOrderId]);

	return (
		<ServiceOrderContext.Provider value={underlyingServiceOrderContext}>
			{DebugStorage.hasServiceOrderDiagnostics() &&
				<DebugPane
					loadingMessage={loadingMessage}
					pending={pending}
					errorMessage={errorMessage}
				/>
			}
			{loadingPageModalIsVisible &&
				<LoadingPageModal
					visible={loadingPageModalIsVisible}
					setVisible={setLoadingPageModalIsVisible}
					onSuccess={onLoadingModalSuccess}
					url={ServerRoutes.SERVICE_ORDER_INVOICE_LOAD_ITEM_FROM_LIST.getUrl()}
					data={dataMemo}
				/>
			}
			{showAbandonModal &&
				<AbandonModal
					visible={showAbandonModal}
					setVisible={setShowAbandonModal}
					onAbandon={onAbandon}
				/>
			}
			<Tab.Container defaultActiveKey="serviceOrder" activeKey={selectedOuterTab} onSelect={e => setSelectedOuterTab(e)}>
				<Nav variant="tabs">
					<Nav.Item>
						<Nav.Link eventKey="serviceOrder">
							<h5>Service Order</h5>
						</Nav.Link>
					</Nav.Item>
					{serviceOrderContext.loadedServiceOrderId &&
						<Nav.Item>
							<Nav.Link eventKey="invoice">
								<h5>Invoice</h5>
							</Nav.Link>
						</Nav.Item>
					}
				</Nav>
				<Tab.Content>
					<Tab.Pane eventKey="serviceOrder" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
						<Row>
							<Col lg={6} className="pb-4">
								<Card>
									<Card.Body>
										{(serviceOrderContext.loadedServiceOrderId !== "" || serviceOrderContext.loadedClientId !== "") &&
											<Fragment>
												<Row className="mb-4">
													<Col lg={12}>
														<h2>{clientFields[Schema.Client.COMPLEX_NAME] || "(New Client)"}</h2>
													</Col>
												</Row>
												<Tab.Container defaultActiveKey="details" activeKey={selectedInnerTab} onSelect={e => setSelectedInnerTab(e)}>
													<Nav variant="tabs">
														<Nav.Item>
															<Nav.Link eventKey="client">
																<h5>Client</h5>
															</Nav.Link>
														</Nav.Item>
														<Nav.Item>
															<Nav.Link eventKey="details">
																<h5>Details</h5>
															</Nav.Link>
														</Nav.Item>
														{serviceOrderContext.loadedServiceOrderId &&
															<Nav.Item>
																<Nav.Link eventKey="progress">
																	<h5>
																		Progress
																		{fetchedData &&
																			<Badge
																				bg={serviceOrderFields[Schema.ServiceOrder.MASTER_STATUS] === Schema.ServiceOrder.ProgressConstants.WORK_CANCELLED ||
																					serviceOrderFields[Schema.ServiceOrder.MASTER_STATUS] === Schema.ServiceOrder.ProgressConstants.COMPLETE ? "success" : "warning"}
																				pill
																				className="ms-1"
																			>
																				{Schema.ServiceOrder.ProgressConstants.MAP[serviceOrderFields[Schema.ServiceOrder.MASTER_STATUS]]?.name}
																			</Badge>
																		}
																	</h5>
																</Nav.Link>
															</Nav.Item>
														}
														{serviceOrderContext.loadedServiceOrderId &&
															<Nav.Item>
																<Nav.Link eventKey="notes">
																	<h5>
																		Notes
																		{serviceOrderNotesCount !== 0 &&
																			<Badge bg="primary" pill className="ms-1">{serviceOrderNotesCount}</Badge>}
																	</h5>
																</Nav.Link>
															</Nav.Item>
														}
														{serviceOrderContext.loadedServiceOrderId &&
															<Nav.Item>
																<Nav.Link eventKey="appointments">
																	<h5>
																		Appointments
																		{appointmentsCount !== 0 &&
																			<Badge bg="primary" pill className="ms-1">{appointmentsCount}</Badge>}
																	</h5>
																</Nav.Link>
															</Nav.Item>
														}
													</Nav>
													<Tab.Content>
														<Tab.Pane eventKey="client" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
															<Form>
																<ClientPage
																	changed={clientChanged !== 0 || serviceOrderChanged !== 0}
																	clientFields={clientFields}
																	serviceOrderFields={serviceOrderFields}
																	onClientChanged={onClientChanged}
																	onServiceOrderChanged={onServiceOrderChanged}
																/>
																<StatusBlock id={9} errorMessage={errorMessage} infoMessage={savingMessage} successMessage={successMessage} disabled={serviceOrderChanged === 0 && clientChanged === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
															</Form>
														</Tab.Pane>
														<Tab.Pane eventKey="details" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
															<Form>
																<DetailsPage
																	changed={clientChanged !== 0 || serviceOrderChanged !== 0}
																	serviceOrderFields={serviceOrderFields}
																	onServiceOrderChanged={onServiceOrderChanged}
																	fetchedData={fetchedData}
																/>
																<StatusBlock id={11} errorMessage={errorMessage} infoMessage={savingMessage} successMessage={successMessage} disabled={serviceOrderChanged === 0 && clientChanged === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
															</Form>
														</Tab.Pane>
														{serviceOrderContext.loadedServiceOrderId &&
															<Tab.Pane eventKey="progress" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
																<Form>
																	<ProgressPage
																		changed={clientChanged !== 0 || serviceOrderChanged !== 0}
																		serviceOrderFields={serviceOrderFields}
																		onServiceOrderChanged={onServiceOrderChanged}
																	/>
																	<StatusBlock id={13} errorMessage={errorMessage} infoMessage={savingMessage} successMessage={successMessage} disabled={serviceOrderChanged === 0 && clientChanged === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
																</Form>
															</Tab.Pane>
														}
														{serviceOrderContext.loadedServiceOrderId &&
															<Tab.Pane eventKey="notes" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
																<Row>
																	<Col lg={12}>
																		<ServiceOrderNotes serviceOrderId={serviceOrderContext.loadedServiceOrderId} onFetched={onServiceOrderNotesFetch} />
																	</Col>
																</Row>
															</Tab.Pane>}
														{serviceOrderContext.loadedServiceOrderId &&
															<Tab.Pane eventKey="appointments" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
																<Row>
																	<Col lg={12}>
																		<ServiceOrderAppointments serviceOrderId={serviceOrderContext.loadedServiceOrderId} onFetched={onAppointmentsFetch} />
																	</Col>
																</Row>
															</Tab.Pane>}
													</Tab.Content>
												</Tab.Container>
											</Fragment>
										}
										{!serviceOrderContext.loadedServiceOrderId && !serviceOrderContext.loadedClientId &&
											<StatusBlock id={14} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
										}
									</Card.Body>
								</Card>
							</Col>
							<Col lg={{span: 6, order: 'last'}} xs={{order: 'first'}} className="pb-4">
								<Tab.Container defaultActiveKey="active">
									<Nav variant="tabs">
										<Nav.Item>
											<Nav.Link eventKey="active">
												<h5>Active/Archived</h5>
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="new-from-client">
												<h5>New From Client</h5>
											</Nav.Link>
										</Nav.Item>
										<Nav.Item className="ms-2 my-auto">
											<Button
												variant="primary"
												onClick={onClickNew}
											>
												New Client & Service Order
											</Button>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="new-from-client" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
											<Row>
												<Col lg={12}>
													<RowContext.Provider value={selectedClientRowFields}>
														<ClientList
															unsaved={serviceOrderChanged !== 0 || clientChanged !== 0}
															refetchCounter={clientListRefetchCounter}
														/>
													</RowContext.Provider>
												</Col>
											</Row>
										</Tab.Pane>
										<Tab.Pane eventKey="active">
											<Row>
												<Col lg={12}>
													<RowContext.Provider value={selectedServiceOrderRowFields}>
														<ServiceOrderList
															unsaved={serviceOrderChanged !== 0 || clientChanged !== 0}
															refetchCounter={serviceOrderListRefetchCounter}
														/>
													</RowContext.Provider>
												</Col>
											</Row>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</Col>
						</Row>
					</Tab.Pane>
					{serviceOrderContext.loadedServiceOrderId &&
						<Tab.Pane eventKey="invoice" /*onEnter={() => window.dispatchEvent(new Event('resize'))}*/>
							<Row>
								<Col xl={8}>
									<Card className={classNames("mb-3", {
										"form-page--modified": clientChanged !== 0 || serviceOrderChanged !== 0
									})}>
										<Card.Body>
											<Row>
												<Col lg={12}>
													{!pending && !loadingMessage &&
														<InvoicePage
															serviceOrderFields={serviceOrderFields}
															onServiceOrderChanged={onServiceOrderChanged}
															onFetchSaveInvoice={onFetchSaveInvoice}
															onFetchLoadInvoice={onFetchLoadInvoice}
															refetchCounter={invoiceRefetchCounter}
														/>
													}
													<StatusBlock classes="mt-3" errorMessage={errorMessage} infoMessage={savingMessage} successMessage={successMessage} disabled={serviceOrderChanged === 0 && clientChanged === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col xl={4}>
									{!pending && !loadingMessage &&
										<ServiceOrderItemList onRowClick={onServiceOrderItemListRowClick} />
									}
								</Col>
							</Row>
						</Tab.Pane>}
				</Tab.Content>
			</Tab.Container>
		</ServiceOrderContext.Provider>
	);
}

export default ServiceOrder;
