
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import InputTooltipCellProps from 'TableCard/cells/InputTooltipCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import TickboxCellProps from 'TableCard/cells/TickboxCell';
import ServerRoutes from 'serverRoutes';
import FormatUtils from 'utils/formatUtils';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const QUANTITY_WIDTH = 80;
const DISCOUNT_WIDTH = 80;
const REBATE_WIDTH = 100;
const ITEM_ID_WIDTH = 72;

const TABLE_CONFIG = new TableConfig({
	tag: "invoice-item",
	//title: "Select Invoice Item",
	hasPagination: false,
	serverRoute: ServerRoutes.SERVICE_ORDER_INVOICE_ITEM,
	schema: Schema.InvoiceItem,
	readOnly: false,
	canAdd: true,
	loadingBlockPosition: TableConfig.LoadingBlockPosition.BOTTOM,
	//leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	primaryKey: Schema.InvoiceItem.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		const row = importRow({
			[Schema.InvoiceItem.ID]: null,
			[Schema.InvoiceItem.SERVICE_ORDER]: null,
			[Schema.InvoiceItem.ITEM]: null,
			[Schema.InvoiceItem.NAME]: null,
			[Schema.InvoiceItem.DISCOUNT]: 0,
			[Schema.InvoiceItem.UNIT_REBATE]: 0,
			[Schema.InvoiceItem.IS_TAXED]: 1,
			[Schema.InvoiceItem.QUANTITY]: 1,
			[Schema.InvoiceItem.UNIT_RETAIL]: 0,
		}, TABLE_CONFIG.toImportFormatter);
		row[Schema.InvoiceItem.SERVICE_ORDER] = query?.[Schema.InvoiceItem.SERVICE_ORDER] ?? "";
		return row;
	},
	payloadTags: [
		Schema.InvoiceItem.ID,
		Schema.InvoiceItem.SERVICE_ORDER,
		Schema.InvoiceItem.ITEM,
		Schema.InvoiceItem.NAME,
		Schema.InvoiceItem.DISCOUNT,
		Schema.InvoiceItem.UNIT_REBATE,
		Schema.InvoiceItem.IS_TAXED,
		Schema.InvoiceItem.QUANTITY,
		Schema.InvoiceItem.UNIT_RETAIL,
	],
	toImportFormatter: {
		[Schema.InvoiceItem.UNIT_RETAIL]: (value:number) => FormatUtils.formatTwoDecimals(value),
		[Schema.InvoiceItem.RETAIL]: (value:number) => FormatUtils.formatTwoDecimals(value),
		[Schema.InvoiceItem.DISCOUNT]: (value:number) => FormatUtils.formatZeroDecimals(value),
		[Schema.InvoiceItem.UNIT_REBATE]: (value:number) => FormatUtils.formatTwoDecimals(value),
		[Schema.InvoiceItem.REBATE]: (value:number) => FormatUtils.formatTwoDecimals(value),
	},
	sortKey: Schema.InvoiceItem.ID,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new InputCellProps({
							tag: Schema.InvoiceItem.NAME,
							label: Schema.InvoiceItem.NAME_LABEL,
							percentWidth: 100,
							required: true,
						}),
					],
					[
						new InputCellProps({
							tag: Schema.InvoiceItem.UNIT_RETAIL,
							label: Schema.InvoiceItem.UNIT_RETAIL_LABEL,
							cellContainerClasses: { "align-right": true, },
							default: "0.00",
							prefix: '$',
							percentWidth: 100,
							required: true,
						}),
					],
					[
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.DISCOUNT,
							label: Schema.InvoiceItem.DISCOUNT_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							percentWidth: 50,
							required: true,
							tooltip: "Percentage discount applied to this one row."
						}),
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.UNIT_REBATE,
							label: "Rebate",
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							percentWidth: 50,
							required: true,
							tooltip: "Manufacturer rebate taken at time of purchase. This is taxed."
						}),
					],
					[
						new TickboxCellProps({
							tag: Schema.InvoiceItem.IS_TAXED,
							label: Schema.InvoiceItem.IS_TAXED_LABEL,
							cellContainerClasses: { "align-right" : true },
							rotated: true,
							fixedWidth: 65,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.QUANTITY,
							label: Schema.InvoiceItem.QUANTITY_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							default: "1",
							percentWidth: 100,
							required: true,
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new StaticCellProps({
							tag: Schema.InvoiceItem.ITEM,
							label: Schema.InvoiceItem.ITEM_LABEL,
							cellContainerClasses: { "align-right": true, },
							fixedWidth: ITEM_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.NAME,
							label: Schema.InvoiceItem.NAME_LABEL,
							percentWidth: 100,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.UNIT_RETAIL,
							label: Schema.InvoiceItem.UNIT_RETAIL_LABEL,
							cellContainerClasses: { "align-right": true, },
							default: "0.00",
							prefix: '$',
							fixedWidth: TableConfig.RETAIL_WIDTH,
							required: true,
						}),
					],
					[
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.DISCOUNT,
							label: Schema.InvoiceItem.DISCOUNT_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							percentWidth: 33,
							required: true,
							tooltip: "Percentage discount applied to this one row."
						}),
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.UNIT_REBATE,
							label: "Rebate",
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							percentWidth: 33,
							required: true,
							tooltip: "Manufacturer rebate taken at time of purchase. This is taxed."
						}),
						new TickboxCellProps({
							tag: Schema.InvoiceItem.IS_TAXED,
							label: Schema.InvoiceItem.IS_TAXED_LABEL,
							cellContainerClasses: { "align-right" : true },
							rotated: true,
							fixedWidth: 65,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.QUANTITY,
							label: Schema.InvoiceItem.QUANTITY_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							default: "1",
							percentWidth: 34,
							required: true,
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new StaticCellProps({
							tag: Schema.InvoiceItem.ITEM,
							label: Schema.InvoiceItem.ITEM_LABEL,
							cellContainerClasses: { "align-right": true, },
							fixedWidth: ITEM_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.NAME,
							label: Schema.InvoiceItem.NAME_LABEL,
							percentWidth: 100,
							required: true,
						}),
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.DISCOUNT,
							label: Schema.InvoiceItem.DISCOUNT_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							fixedWidth: DISCOUNT_WIDTH,
							required: true,
							tooltip: "Percentage discount applied to this one row."
						}),
						new InputTooltipCellProps({
							tag: Schema.InvoiceItem.UNIT_REBATE,
							label: "Rebate",
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							fixedWidth: REBATE_WIDTH,
							required: true,
							tooltip: "Manufacturer rebate taken at time of purchase. This is taxed."
						}),
						new TickboxCellProps({
							tag: Schema.InvoiceItem.IS_TAXED,
							label: Schema.InvoiceItem.IS_TAXED_LABEL,
							cellContainerClasses: { "align-right" : true },
							rotated: true,
							fixedWidth: TableConfig.CHECK_COL_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.QUANTITY,
							label: Schema.InvoiceItem.QUANTITY_LABEL,
							cellContainerClasses: { "align-right" : true, },
							rotated: true,
							default: "1",
							fixedWidth: QUANTITY_WIDTH,
							required: true,
						}),
						new InputCellProps({
							tag: Schema.InvoiceItem.UNIT_RETAIL,
							label: Schema.InvoiceItem.UNIT_RETAIL_LABEL,
							cellContainerClasses: { "align-right": true, },
							default: "0.00",
							prefix: '$',
							fixedWidth: TableConfig.RETAIL_WIDTH,
							required: true,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
