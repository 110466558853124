
import CellProps from 'TableCard/classes/cellProps';
import ICellProps from 'TableCard/interfaces/ICellProps';
const classNames = require('classnames');

interface IRightEdgeCellProps extends ICellProps {
	fixedWidth: number,
}

export class RightEdgeCellProps extends CellProps implements IRightEdgeCellProps {
	type: "left-edge" | "right-edge" | "cell";

	constructor(options:IRightEdgeCellProps) {
		super(options);
		this.type = "right-edge";
		this.fixedWidth = options.fixedWidth;
	}
}

function toPix(n:number) {
	return `${n}px`;
}

interface EdgeProps {
	children?: any,
	classes?: string | Record<string, any>,
	width: number,
}

function RightEdge({classes, width, children}:EdgeProps) {
	return (
		<div
			className={classNames("d-flex", "right-edge", classes)}
			style={{width: toPix(width)}}
		>
			{children}
		</div>
	);
}

export default RightEdge;
