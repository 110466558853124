
import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import StaticCellProps, { StaticCell } from 'TableCard/cells/StaticCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import StatusBlock from 'views/components/StatusBlock';
import Modal from 'react-bootstrap/Modal';
import useFieldManager, { importRow, exportRow, importSelectOptions } from 'hooks/useFieldManager'
import DebugStorage from 'debugStorage';
const classNames = require('classnames');

interface DebugProps {
	showModal: any,
	setShowModal: any,
}

const YES_NO = [
	{tag: 0, value: "No"},
	{tag: 1, value: "Yes"},
];

function Debug({showModal, setShowModal}:DebugProps) {
	const [setFieldManagerActions, fields, setFields, changed] = useFieldManager({});
	const [errorMessage, ] = useState("");
	const [selectOptions, ] = useState<Record<string, Record<string, string>[]>>(importSelectOptions({
		"failAllFetches": YES_NO,
		"showBreakpoints": YES_NO,
		"serviceOrderDiagnostics": YES_NO,
		"statusBlockDiagnostics": YES_NO,
	}));

	function onChange(e:any) {
		setFields((prev:any) => ({...prev, [e.target.name]: e.target.value}));
		setFieldManagerActions({update: {[e.target.name]: e.target.value}});
	}

	function onSave(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		DebugStorage.set(exportRow(fields));
		setFieldManagerActions({accept: true});
	}

	function onReset(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setFieldManagerActions({revert: true});
	}

	useEffect(() => {
		const localContext = DebugStorage.get();
		setFields(importRow(localContext));
		setFieldManagerActions({accept: true});
	}, [setFieldManagerActions, setFields]);

	const handleModalClose = () => {
		setShowModal(false);
	};

	return (
		<Modal centered show={showModal} onHide={handleModalClose} className="modal-xl search-results">
			<Modal.Header closeButton>
				<Modal.Title>
					Debug Options
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tab.Container defaultActiveKey="options">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link eventKey="options">
								<h5>Options</h5>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="tokens">
								<h5>Tokens</h5>
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="options">
							<div className="row mb-3">
								<Col md={12}>
									<Card className={classNames("w-100", {
										"form-page--modified": changed !== 0
									})}>
										<Card.Body>
											<Row>
												<Col lg={4}>
													<InputCell
														cellProps={new InputCellProps({
															tag: "latency",
															label: "Latency",
															percentWidth: 100,
															contentClasses: {
																"bg-warning": fields["latency"] !== "0",
															},
														})}
														onChange={onChange}
														values={fields}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<InputCell
														cellProps={new InputCellProps({
															tag: "Fetch Pending Timeout Override",
															label: "fetchPendingTimeoutOverride",
															percentWidth: 100,
															contentClasses: {
																"bg-warning": fields["fetchPendingTimeoutOverride"] !== "0",
															},
														})}
														onChange={onChange}
														values={fields}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<SelectCell
														cellProps={new SelectCellProps({
															label: "Fail All Fetches",
															tag: "failAllFetches",
															contentClasses: {
																"bg-warning": fields["failAllFetches"] !== "0"
															},
															selectOptions: selectOptions["failAllFetches"],
														})}
														values={fields}
														onChange={onChange}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<SelectCell
														cellProps={new SelectCellProps({
															label: "Service Order Diagnostics",
															tag: "serviceOrderDiagnostics",
															contentClasses: {
																"bg-warning": fields["serviceOrderDiagnostics"] !== "0"
															},
															selectOptions: selectOptions["serviceOrderDiagnostics"],
														})}
														values={fields}
														onChange={onChange}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<SelectCell
														cellProps={new SelectCellProps({
															label: "Status Block Diagnostics",
															tag: "statusBlockDiagnostics",
															contentClasses: {
																"bg-warning": fields["statusBlockDiagnostics"] !== "0"
															},
															selectOptions: selectOptions["statusBlockDiagnostics"],
														})}
														values={fields}
														onChange={onChange}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<SelectCell
														cellProps={new SelectCellProps({
															label: "Show Breakpoints",
															tag: "showBreakpoints",
															contentClasses: {
																"bg-warning": fields["showBreakpoints"] !== "0"
															},
															selectOptions: selectOptions["showBreakpoints"],
														})}
														values={fields}
														onChange={onChange}
														includeLabel={true}
													/>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
							</div>
						</Tab.Pane>
						<Tab.Pane eventKey="tokens">
							<div className="row mb-3">
								<Col md={12}>
									<Card className={classNames("w-100")}>
										<Card.Body>
											<Row>
												<Col lg={12}>
													<StaticCell
														cellProps={new StaticCellProps({
															label: "JWT Token",
															tag: "jwtToken",
														})}
														values={fields}
														includeLabel={true}
													/>
												</Col>
												<Col lg={4}>
													<StaticCell
														cellProps={new StaticCellProps({
															label: "Refresh Token",
															tag: "refreshToken",
														})}
														values={fields}
														includeLabel={true}
													/>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
							</div>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</Modal.Body>
			<Modal.Footer>
				<StatusBlock id={3} errorMessage={errorMessage} disabled={changed === 0} onReset={onReset} saveLabel="Update" onSave={onSave} />
			</Modal.Footer>
		</Modal>
	);
}

export default Debug;
