
import React, { useEffect } from 'react';
import TableCard from 'TableCard';
import LocationsTableConfig from './locationsTableConfig';

function Locations({getDescription}:{getDescription:any}) {
	useEffect(() => { getDescription("Locations")}, [getDescription])
	return (
		<TableCard tableConfig={LocationsTableConfig} />
	);
}

export default Locations;
