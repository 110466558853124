
import Alert from 'react-bootstrap/Alert';
import DebugStorage from 'debugStorage';
const classNames = require('classnames');

interface FormButtonProps {
	role: string,
	label: string,
	onClick?: React.MouseEventHandler<HTMLButtonElement>,
	disabled?: boolean,
	isSubmit?: boolean,
}

function FormButton({role, label, onClick, disabled, isSubmit}:FormButtonProps) {
	return (
		<div className="form-group my-auto ms-2">
			<button
				className={classNames("btn", "btn-sm", "text-nowrap", role)}
				disabled={disabled}
				onClick={onClick}
				type={isSubmit ? "submit" : "button"}
			>
				{label}
			</button>
		</div>
	)
}

interface StatusBlockProps {
	id?: number;
	errorMessage?: string;
	infoMessage?: string | false;
	successMessage?: string;
	onRetry?: React.MouseEventHandler<HTMLButtonElement>;
	onReset?: React.MouseEventHandler<HTMLButtonElement>;
	onSave?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	saveLabel?: string;
	classes?: any;
	loadingMessage?: string;
}

function StatusBlock({id, errorMessage, infoMessage, successMessage, onRetry, onReset, onSave, disabled, saveLabel, classes, loadingMessage}:StatusBlockProps) {
	return (
		<div className={classNames("d-flex", "flex-row", "flex-grow-1", "justify-content-end", classes)}>
			{DebugStorage.hasStatusBlockDiagnostics() &&
				id}
			{errorMessage &&
				<div className="w-100">
					<Alert className="p-1 ps-3 my-auto" variant="danger">
						{errorMessage}
					</Alert>
				</div>}
			{infoMessage && !errorMessage &&
				<div className="w-100">
					<Alert className="p-1 ps-3 my-auto" variant="info">
						{infoMessage}
					</Alert>
				</div>}
			{successMessage && !loadingMessage && !errorMessage &&
				<div className="w-100">
					<Alert className="p-1 ps-3 my-auto" variant="success">
						{successMessage}
					</Alert>
				</div>}
			{onRetry &&
				<FormButton
					onClick={onRetry}
					role="btn-primary"
					label="Retry"
				/>}
			{onReset && !onRetry &&
				<FormButton
					onClick={onReset}
					role="btn-secondary"
					label="Abandon Changes"
					disabled={disabled}
				/>}
			{onSave && !onRetry &&
				<FormButton
					onClick={onSave}
					role="btn-primary"
					label={saveLabel || "Save"}
					disabled={disabled}
					isSubmit={true}
				/>}
		</div>
	);
}

export default StatusBlock;
