
import React, { useState } from 'react';
import TableCard from 'TableCard/TableCard';
import 'bootstrap-daterangepicker/daterangepicker.css';
import InvoiceItemListTableConfig from './invoiceItemListTableConfig';
const Schema = require('portal-schema');

interface InvoiceItemListProps {
	serviceOrderId: string,
	onFetched: any;
	onSaved: any;
	refetchCounter: number;
}
function InvoiceItemList({serviceOrderId, onFetched, onSaved, refetchCounter}:InvoiceItemListProps) {
	const [query, ] = useState({
		[Schema.InvoiceItem.SERVICE_ORDER]: serviceOrderId,
	});

	////useEffect(() => { console.log('invoiceitemlist mounted')}, [])
	////console.log('InvoiceItemList render');

	return (
		<div className="search-results bordered">
			{query[Schema.InvoiceItem.SERVICE_ORDER] &&
				<TableCard
					tableConfig={InvoiceItemListTableConfig}
					query={query}
					onFetched={onFetched}
					onSaved={onSaved}
					refetchCounter={refetchCounter}
					cardless={true}
				/>
			}
		</div>
	);
}

export default InvoiceItemList;
