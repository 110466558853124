
import { Fragment } from 'react';
import IElementsCellProps from 'TableCard/interfaces/IElementsCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import ITagLabelElement from 'TableCard/interfaces/ITagLabelElement';
import IImageCellProps from 'TableCard/interfaces/IImageCellProps';
import ContentCellProps from 'TableCard/classes/contentCellProps'
const classNames = require('classnames');

interface IStaticCellPreProps extends IContentCellProps, IImageCellProps, IElementsCellProps {
}

class StaticPreCellProps extends ContentCellProps implements IStaticCellPreProps {
	elements: Array<ITagLabelElement>;

	constructor(options:IStaticCellPreProps) {
		super(options);
		this.component = StaticPre;
		this.isStatic = true;
		this.elements = options.elements ?? [];
	}
}

interface StaticCellProps {
	values?: any;
	cellProps: StaticPreCellProps;
	includeLabel: boolean;
}

function StaticPre({values, cellProps, includeLabel}:StaticCellProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				{cellProps.tag && values[cellProps.tag]}
				{cellProps.elements.filter((element:{tag:string, label:string}) => values[element.tag]).map((element:{tag:string, label:string}, index:number) => {
					return (
						<Fragment key={index}>
							{(index !== 0 || cellProps.tag) &&
								<span>;&nbsp;</span>
							}
							<span className="p-heavy">{element.label}</span>
							<span>:&nbsp;</span>
							<span>{values[element.tag]}</span>
						</Fragment>
					);
				})}
			</div>
		</div>
	);
}

export default StaticPreCellProps;
