
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import ServerRoutes from 'serverRoutes';
import LabelCellProps from 'TableCard/cells/LabelCell';
import InputCellProps from 'TableCard/cells/InputCell';
import { ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "invoice-static-two",
	hasPagination: false,
	serverRoute: ServerRoutes.EMPLOYEES,
	schema: {},
	readOnly: false,
	canAdd: false,
	loadingBlockPosition: TableConfig.LoadingBlockPosition.BOTTOM,
	leftEdgeWidth: TableConfig.WIDE_EDGE_WIDTH,
	primaryKey: "",
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return {};
	},
	payloadTags: [
	],
	toImportFormatter: {
	},
	sortKey: "",
	sortDirection: "ASC",
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new LabelCellProps({
							tag: "",
							label: "Subtotal:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Discount:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.DISCOUNT,
							label: "",
							fixedWidth: 140,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "w/Discount:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL_AFTER_DISCOUNT,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Tax Rate:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX_RATE,
							label: "",
							fixedWidth: 140,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Tax:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Due:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
				]),
			"sm": new Breakpoint(
				[
					[
						new LabelCellProps({
							tag: "",
							label: "Subtotal Including Line-Item Discounts:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Whole Order Discount:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.DISCOUNT,
							label: "",
							fixedWidth: 140,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Subtotal With Whole-Order Discount:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL_AFTER_DISCOUNT,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Sales Tax Rate:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX_RATE,
							label: "",
							fixedWidth: 140,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Sales Tax:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Total Due:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new LabelCellProps({
							tag: "",
							label: "Subtotal Including Line-Item Discounts:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Whole Order Discount:",
							percentWidth: 35,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.DISCOUNT,
							label: "",
							percentWidth: 25,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
						new LabelCellProps({
							tag: "",
							label: "Subtotal With Whole-Order Discount:",
							percentWidth: 40,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.SUBTOTAL_AFTER_DISCOUNT,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Sales Tax Rate:",
							percentWidth: 35,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX_RATE,
							label: "",
							percentWidth: 25,
							postfix: "%",
							contentClasses: {
								"align-right": true,
							},
						}),
						new LabelCellProps({
							tag: "",
							label: "Sales Tax:",
							percentWidth: 40,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TAX,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Total Due:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.TOTAL,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
				]),
		},
});

export default TABLE_CONFIG;
