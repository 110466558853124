
import { useState, useEffect, useRef } from 'react';
import api from 'api';
import EventBus from 'eventBus';
import DebugStorage from 'debugStorage';

export interface FetchOptions {
	url: string;
	method?: "get" | "put" | "post" | "delete";
	query?: Record<string, any>;
	data?: Record<string, any>;
	useMultiPart?: boolean;
}

export type FetchedSelectOptions = Record<string, { tag: any, value: any}[]>;

export interface FetchedResponse {
	data: any;
}

interface ApiFetchConfig {
	url: string;
	method: "get" | "put" | "post" | "delete";
	data?: Record<string, any>;
	params?: Record<string, any>;
	headers?: any;
}

export enum FetchMode {
	PENDING,
	NO_PENDING,
	WAITING,
}

function getMessage(error:any):string {
	return (error.response && error.response.data) ? error.response.data.message : error.message;
}

// https://www.robinwieruch.de/react-hooks-fetch-data/
// https://balavishnuvj.com/blog/using-callbacks-in-custom-hooks/

//	responseCallback
//		The response data returned by the API call.
//	pendingCallback
//		Allows the caller to react to a "pending" state, which is the time between when the API call is made
//		and when it is determined that the API call is taking a while.
//		The pending callback is called with false either when the API call has returned or when it's determined
//		that it's going to take a while.
//	waitingCallback
//		Allows the caller to react to an API call that is taking a while.
//	errorMessageCallback
//		This is the error message returned if the API call fails.
//	startAsPending
//		If this is false, the pending callback will be called with a value of "false" a few times, which will
//		presumably behave as a no-op.
//		If it's true, the callback will be called with "true" on the initial API call and will be cleared later,
//		when the API call has either returned or it's been determined that the API call will take a long time.

const useFetch = (
	responseCallback: (response:FetchedResponse, config?:any) => void,
	pendingCallback: Function,
	waitingCallback: Function,
	errorMessageCallback: Function,
	fetchMode: FetchMode = FetchMode.PENDING,
	firstFetch: FetchMode = fetchMode,
) => {
	const [fetchState, setFetchState] = useState(firstFetch);
	const [options, setOptions] = useState<FetchOptions | undefined>(undefined);
	const responseRef = useRef<(response:FetchedResponse, config?:any) => void>();
	useEffect(() => {
		//console.log(`useEffect responseCallback`)
		responseRef.current = responseCallback;
	}, [responseCallback]);
	const pendingRef = useRef<Function>();
	useEffect(() => {
		//console.log(`useEffect pendingCallback`)
		pendingRef.current = pendingCallback;
	}, [pendingCallback]);
	const waitingRef = useRef<Function>();
	useEffect(() => {
		//console.log(`useEffect waitingCallback`)
		waitingRef.current = waitingCallback;
	}, [waitingCallback]);
	const errorMessageRef = useRef<Function>();
	useEffect(() => {
		//console.log(`useEffect errorMessageCallback`)
		errorMessageRef.current = errorMessageCallback;
	}, [errorMessageCallback]);
	const debugStorage = DebugStorage.get();

	useEffect(() => {
		let exit = false;
		//console.log(`useFetch options=${JSON.stringify(options)}`)
		const fetchData = async () => {
			console.log(`useFetch fetchData options=${JSON.stringify(options)}`)
			// Loading should already be false here if this is called at mount.
			// Pending should already be true here if this is called at mount.
			// If they aren't, I'll get flicker.
			waitingRef.current && waitingRef.current(fetchState === FetchMode.WAITING);
			pendingRef.current && pendingRef.current(fetchState === FetchMode.PENDING);
			// Pending disables display of *anything*
			// Loading disables display of content and displays a loading message.
			// Error displays a message. This supercedes the loading message.
			// If there is an error during initial fetch I am going to leave loadingMessage on, which
			// will result in no content displayed, and error message displayed.
			let waitingTimeout:NodeJS.Timeout;
			let longDelayPromise:Promise<unknown>;
			if (fetchState === FetchMode.WAITING) {
				longDelayPromise = new Promise(resolve => setTimeout(resolve, 750));
			} else {
				const timeoutOverride = DebugStorage.getFetchPendingTimeoutOverride();
				waitingTimeout = setTimeout(() => {
					// > some short but not instant time.
					// Turning off pending allows the basic screen to draw.
					// But since loadingMessage is on, I just get the loading message.
					errorMessageRef.current && errorMessageRef.current("");
					waitingRef.current && waitingRef.current(true);
					pendingRef.current && pendingRef.current(false);
					longDelayPromise = new Promise(resolve => setTimeout(resolve, 500));
				}, timeoutOverride || 200);
			}
			const method = (options as FetchOptions).method ?? 'get';
			let config:ApiFetchConfig = {
				url: (options as FetchOptions).url,
				method: method,
				params: method === 'get' ? {...(options as FetchOptions).query} : undefined,
				data: method !== 'get' ? {...(options as FetchOptions).data} : undefined,
			}
			if ((options as FetchOptions).useMultiPart) {
				config.headers = {};
				config.headers['content-type'] = `multipart/form-data`;
			}
			api(config)
				.then(async (response:FetchedResponse) => {
					if (exit) {
						return;
					}
					errorMessageRef.current && errorMessageRef.current("");
					if (longDelayPromise && !exit) {
						await longDelayPromise;
					}
					//console.log("useFetch fetchData response=", response);
					responseRef.current && responseRef.current(response, config);
					waitingRef.current && waitingRef.current(false);
				})
				.catch (error => {
					if (exit) {
						return;
					}
					errorMessageRef.current && errorMessageRef.current(getMessage(error));
					if (error.response && error.response.status === 403) {
						console.log('403 logout');
						EventBus.dispatch("logout", () => {});
					}
				})
				.finally(() => {
					if (waitingTimeout) {
						clearTimeout(waitingTimeout);
					}
					if (exit) {
						console.log('interrupted fetch');
						return;
					}
					pendingRef.current && pendingRef.current(false);
					setOptions(undefined);
					setFetchState(fetchMode);
				})
		};
		if (options !== undefined) {
			fetchData();
		}
		return () => {
			exit = true;
		};
	}, [options, fetchMode, fetchState, debugStorage.fetchPendingTimeoutOverride]);
	return [setOptions];
}

export default useFetch;
