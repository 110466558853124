
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import InputGroup from 'react-bootstrap/InputGroup';
const classNames = require('classnames');

interface ILabelCellProps extends IContentCellProps {
	label: string;
}

class LabelCellProps extends ContentCellProps implements IContentCellProps {
	label: string;

	constructor(options:ILabelCellProps) {
		super(options);
		this.component = LabelCell;
		this.isStatic = false;
		this.label = options.label;
	}
}

interface LabelCellReactProps {
	cellProps: LabelCellProps,
}

function LabelCell({cellProps}:LabelCellReactProps) {
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				<InputGroup>
					<p className={classNames(cellProps.contentClasses)}>
						{cellProps.label}
					</p>
				</InputGroup>
			</div>
		</div>
	);
}

export default LabelCellProps;
