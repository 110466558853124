
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
const classNames = require('classnames');

class CheckboxCellProps extends ContentCellProps {
	constructor(options:IContentCellProps) {
		super(options);
		this.component = CheckboxCell;
	}
}

interface ICheckboxCellReactProps extends IContentCellReactProps<IContentCellProps> {
	readOnly?: boolean;
}

export function CheckboxCell({cellProps, values, onChange, includeLabel}:ICheckboxCellReactProps) {
	function onCheckboxChange(event:React.ChangeEvent<HTMLInputElement>) {
		event.target.value = values[cellProps.tag].toString() !== '0' ? "0" : "1";
		onChange(event);
	}
	
	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				<div className="form-check form-check-inline">
					<label className="form-check-label">
						<input
							className="form-check-input"
							type="checkbox"
							value="1"
							checked={values[cellProps.tag] !== '0'}
							name={cellProps.tag}
							onChange={onCheckboxChange}
						/>
						{cellProps.label}
					</label>
				</div>
			</div>
		</div>
	)
}

export default CheckboxCellProps;
