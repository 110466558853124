
class ServerRoute {
	url: string;

	constructor(url:string) {
		this.url = url;
	}

	getUrl():string {
		return this.url;
	}

	//	Strips preceding '/', which must exist.
	getName():string {
		return this.getUrl().slice(1);
	}

	//	Strips preceding '/', converts all '/' to '_'.
	toJavascriptSymbol():string {
		const name:string = this.getName();
		return name.replace(/\//g, '_');
	}

	//	Strips preceding '/', converts all '/' and '_' to '-'.
	toHtmlSymbol():string {
		return this.toJavascriptSymbol().replace(/_/g, '-');
	}

	static ANNOUNCEMENTS = new ServerRoute('/announcements');
	static APPOINTMENTS = new ServerRoute('/appointments');
	static CATEGORIES = new ServerRoute('/categories');
	static CLIENT_VIEW = new ServerRoute('/client_view');
	static CLIENT_VIEW_WITH_ID = new ServerRoute(ServerRoute.CLIENT_VIEW.getUrl() + '/:id([0-9]+)?');
	static CLIENTS = new ServerRoute('/clients');
	static EMPLOYEE_LIST = new ServerRoute('/employee_list');
	static EMPLOYEES = new ServerRoute('/employees');
	static GROUPS = new ServerRoute('/groups');
	static HYBRID_LIST = new ServerRoute('/hybrid_list');
	static INDEX = new ServerRoute('/');
	static IP = new ServerRoute('/ip');
	static ITEMS = new ServerRoute('/items');
	static LOCATIONS = new ServerRoute('/locations');
	static LOG = new ServerRoute('/log');
	static LOG_INFO = new ServerRoute('/log_info');
	static LOGIN = new ServerRoute('/login');
	static SYSTEM_CONFIG = new ServerRoute('/system_config');
	static LOGIN_LOG = new ServerRoute('/login_log');
	static LOGOUT = new ServerRoute('/logout');
	static MY_APPOINTMENTS = new ServerRoute('/my_appointments');
	static MY_INFORMATION = new ServerRoute('/my_information');
	static MY_INFORMATION_UPDATE_ACCOUNT = new ServerRoute(ServerRoute.MY_INFORMATION.getUrl() + '/update_account');
	static MY_INFORMATION_UPDATE_EMPLOYEE = new ServerRoute(ServerRoute.MY_INFORMATION.getUrl() + '/update_employee');
	static MY_TIMESHEET = new ServerRoute('/my_timesheet');
	static MY_TIMESHEET_STATUS_UPDATE = new ServerRoute(ServerRoute.MY_TIMESHEET.getUrl() + '/status_update');
	static PAYMENTS = new ServerRoute('/payments');
	static PHONE_LIST = new ServerRoute('/phone_list');
	static PRIVILEGE_GROUPS = new ServerRoute('/privilege_groups');
	static REFRESH_TOKEN = new ServerRoute('/refresh_token');
	static SERVICE_ORDER = new ServerRoute('/service_order');
	static SERVICE_ORDER_WITH_ID = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/:id([0-9]+)?');
	static SERVICE_ORDER_APPOINTMENT = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/appointment');
	static SERVICE_ORDER_CLIENT_LIST = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/client_list');
	static SERVICE_ORDER_INVOICE = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/invoice');
	static SERVICE_ORDER_INVOICE_ITEM = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/item');
	static SERVICE_ORDER_INVOICE_LOAD_ITEM_FROM_LIST = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/item/load');
	static SERVICE_ORDER_INVOICE_PAYMENT = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/payment');
	static SERVICE_ORDER_INVOICE_PDF = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/pdf');
	static SERVICE_ORDER_INVOICE_PDF_WITH_ID = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE_PDF.getUrl() + '/:id');
	static SERVICE_ORDER_ITEM_LIST = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/item_list');
	static SERVICE_ORDER_ITEM_LIST_INFO = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/item_list/invo');
	static SERVICE_ORDER_LABEL_PDF = new ServerRoute(ServerRoute.SERVICE_ORDER_INVOICE.getUrl() + '/label_pdf');
	static SERVICE_ORDER_LABEL_PDF_WITH_ID = new ServerRoute(ServerRoute.SERVICE_ORDER_LABEL_PDF.getUrl() + '/:id([0-9]+)');
	static SERVICE_ORDER_LIST = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/list');
	static SERVICE_ORDER_LIST_INFO = new ServerRoute(ServerRoute.SERVICE_ORDER_LIST.getUrl() + '/info');
	static SERVICE_ORDER_POPULATE = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/populate'); // Not exposed via UI.
	static SERVICE_ORDER_NOTE = new ServerRoute(ServerRoute.SERVICE_ORDER.getUrl() + '/note');
	static SUBCATEGORIES = new ServerRoute('/subcategories');
	static TIMESHEET = new ServerRoute('/timesheet');
	static TIMESHEET_WITH_ID = new ServerRoute(ServerRoute.TIMESHEET.getUrl() + '/:employeeId([0-9]+)?');
	static TIMESHEET_LIST = new ServerRoute('/timesheet_list');
	static TIMESHEET_STATUS_UPDATE = new ServerRoute(ServerRoute.TIMESHEET.getUrl() + '/status_update');
	static WORKORDER = new ServerRoute('/workorder');
	static WORKORDER_ASSET = new ServerRoute('/workorder_asset');
	static WORKORDER_CALL_LOG = new ServerRoute('/workorder_all_log');
	static WORKORDER_NOTES = new ServerRoute('/workorder_notes');
	static WORKORDER_SERVICE = new ServerRoute('/workorder_service');
	static WORKORDERS = new ServerRoute('/workorders');
	static TEST = new ServerRoute('/test');
}

export default ServerRoute;

