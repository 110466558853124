
import React, { useEffect } from 'react';
import TableCard from 'TableCard';
import CategoriesTableConfig from './categoriesTableConfig';
import SubcategoriesTableConfig from './subcategoriesTableConfig';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Categories({getDescription}:{getDescription:any}) {
	useEffect(() => { getDescription("Categories")}, [getDescription])
	return (
		<Row>
			<Col lg={5}>
				<TableCard tableConfig={CategoriesTableConfig} />
			</Col>
			<Col lg={7}>
				<TableCard tableConfig={SubcategoriesTableConfig} />
			</Col>
		</Row>
	);
}

export default Categories;
