
import { useState, useEffect } from 'react';

export type ExportRow = Record<string, any>;
export type ImportRow = Record<string, string>;
export type ToExportFormatter = Record<string, (value:string) => string | number | null>;
export type ToImportFormatter = Record<string, (value:any) => string>;

interface FieldManagerActions {
	update?: ImportRow;
	overwrite?: ImportRow;
	accept?: boolean;
	revert?: boolean;
}

export function exportRow(row:ImportRow, payload?:string[], formatter?:ToExportFormatter):ExportRow {
	return Object.fromEntries((payload ? payload.map((tag) => [tag, row[tag]]) : Object.entries(row)).map(([tag, value]) => {
		console.assert(value !== undefined, "exportRow: Undefined value (likely something wrong with payload tags)")
		if (value === undefined) {
			console.log(tag);
			console.log(row);
			console.log(payload);
			console.log(formatter);
		}
		value = value.trim();
		if (value === "") {
			return [tag, null];
		}
		if (formatter && formatter[tag]) {
			return [tag, formatter[tag](value)];
		}
		return [tag, isNaN(Number(value)) ? value : Number(value)];
	}));
}

export function exportRows(rows:ImportRow[], payload?:string[], formatter?:ToExportFormatter):ExportRow[] {
	return rows.reduce((acc:ExportRow[], row) => [...acc, exportRow(row, payload, formatter)], []);
}

export function importRow(row:ExportRow, formatter?:ToImportFormatter):ImportRow {
	return Object.fromEntries(Object.entries(row).map(([tag, value]) => {
		if (formatter && formatter[tag]) {
			//console.log(`importRow <- ${value} -> ${formatter[tag](value)}`)
			return [tag, formatter[tag](value)];
		}
		let newValue:string;
		switch (typeof(value)) {
			case 'object':
				newValue = "";
				break;
			case 'string':
				newValue = value;
				break;
			default:
			case 'number':
				newValue = value.toString();
				break;
		}
		return [tag, newValue];
	}));
}

export function importRows(rows:ExportRow[], formatter?:ToImportFormatter):ImportRow[] {
	return rows.reduce((acc:ImportRow[], row) => [...acc, importRow(row, formatter)], []);
}

export function importSelectOptions(selectOptions:Record<string, {tag: any, value: any}[]>):Record<string, {tag: string, value: string}[]> {
	/*return Object.fromEntries(Object.entries(selectOptions).map(([tag, selectOptions]) => {
		return [tag, selectOptions.reduce((acc:{tag: string, value: string}[], element:{tag: string, value: string}) => {
			return [...acc, {tag: element.tag.toString(), value: element.value.toString()}];
		}, [])];
	}))*/
	//console.log('importSelectOptions');
	//console.log(selectOptions);
	return Object.fromEntries(Object.entries(selectOptions).map(([tag, selectOptions]) => 
		[tag, selectOptions.reduce((acc:{tag: string, value: string}[], element:{tag: string, value: string}) => 
			[...acc, {tag: element.tag.toString(), value: element.value.toString()}], [])]))
	//console.log(v);
	//return v;
}

const useFieldManager = (initialFields:ImportRow | undefined) => {
	const [fields, setFields] = useState<ImportRow>(initialFields || {});
	const [prevFields, setPrevFields] = useState<ImportRow>(initialFields || {});
	const [savedFields, setSavedFields] = useState<ImportRow>(initialFields || {});
	const [changed, setChanged] = useState<number>(0);
	const [actions, setActions] = useState<FieldManagerActions | undefined>(undefined);

	useEffect(() => {
		if (actions !== undefined) {
			if (actions.update !== undefined) {
				const update:ImportRow = actions.update;
				////console.log('----');
				////console.log('useFieldManager update');
				////console.log(savedFields);
				////console.log(update);
				let newChanged = changed;
				Object.keys(update).forEach(key => {
					////console.log(`useFieldManager update key=${key}, value=${update[key]}`);
					if (savedFields[key] !== undefined) {
						////console.log(`useFieldManager newChanged=${newChanged}`);
						////console.log(`useFieldManager saved: key=${key}, value=${savedFields[key]}`);
						////console.log(`useFieldManager prev: key=${key}, value=${prevFields[key]}`);
						if (savedFields[key].toString() !== prevFields[key].toString()) {
							newChanged--;
						}
						if (savedFields[key].toString() !== update[key].toString()) {
							newChanged++;
						}
						////console.log(`newChanged = ${newChanged}`)
					} else {
						console.assert(false, `useFieldManager: Didn't find saved value for key=${key}`);
					}
				});
				setPrevFields((prev:ImportRow) => {
					////console.log('Setting saved fields', {...prev, ...update});
					return {...prev, ...update};
				});
				setChanged(newChanged);
			}
			if (actions.overwrite) {
				setSavedFields((prev:ImportRow) => ({...prev, ...actions.overwrite}));
				setPrevFields((prev:ImportRow) => ({...prev, ...actions.overwrite}));
			}
			if (actions.accept) {
				////console.log('useFieldManager accept');
				setSavedFields(() => ({...fields}));
				setPrevFields(() => ({...fields}));
				setChanged(0);
			}
			if (actions.revert) {
				////console.log('useFieldManager revert');
				setFields(() => ({...savedFields}));
				setPrevFields(() => ({...savedFields}));
				setChanged(0);
			}
			setActions(undefined);
		}
	}, [actions, changed, fields, prevFields, savedFields]);

	return [setActions, fields, setFields, changed] as const;
}

export default useFieldManager;
