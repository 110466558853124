
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface AbandonModalProps {
	visible: any,
	setVisible: any,
	onAbandon: any,
}

function AbandonModal({visible, setVisible, onAbandon}:AbandonModalProps) {
	return (
		<Modal centered show={visible} onHide={() => setVisible(false)}>
			<Modal.Header closeButton>
				<Modal.Title>{`Navigating away from changed form`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{`Abandon changes?`}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => setVisible(false)}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						onAbandon();
						setVisible(false)
					}}>
					Abandon
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AbandonModal;
