
import React, { useState, useContext, useEffect } from 'react';
import TableCard from 'TableCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ClientTableConfig from './clientTableConfig';
import ServiceOrderContext from '../ServiceOrderContext';
import AbandonModal from '../AbandonModal';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
const Schema = require('portal-schema');

interface ClientListProps {
	unsaved: boolean;
	refetchCounter: number;
}

function ClientList({unsaved, refetchCounter}:ClientListProps) {
	const [query, setQuery] = useState({
		[Schema.Client.COMPLEX_NAME]: "",
	});
	const {setServiceOrderContext} = useContext(ServiceOrderContext);
	const [proposedRowSelection, setProposedRowSelection] = useState<string | undefined>(undefined);
	const [rowSelection, setRowSelection] = useState<string | undefined>(undefined);
	const [showAbandonModal, setShowAbandonModal] = useState(false);
	const [hasAdvancedOptions, setHasAdvancedOptions] = useState(false);

	useEffect(() => {
		if (rowSelection) {
			setServiceOrderContext((prev:any) => { return {...prev, loadingServiceOrderId: "", loadingClientId: rowSelection}});
			setRowSelection(undefined);
		}
	}, [rowSelection, setServiceOrderContext]);

	function onAbandon() {
		setRowSelection(proposedRowSelection);
		setProposedRowSelection(undefined);
	}

	function onRowClick(e:any, row:Record<string, string>) {
		if (unsaved) {
			setProposedRowSelection(row[Schema.Client.ID]);
			setShowAbandonModal(true);
		} else {
			setRowSelection(row[Schema.Client.ID]);
		}
		e.stopPropagation();
	}

	function onChange(e:any) {
		setQuery((prev:any) => ({...prev, [e.target.name]: e.target.value}));
	}

	function addSelectedRowClasses(row:Record<string, string>):Record<string, boolean> {
		return {"p-selected" : true}
	}

	return (
		<>
			{showAbandonModal &&
				<AbandonModal visible={showAbandonModal} setVisible={setShowAbandonModal} onAbandon={onAbandon}/>
			}
			<Row>
				<Col lg={12}>
					<Card style={{marginBottom: "0.5rem"}}>
						<Card.Body>
							<Row>
								<Col md={12}>
									<div className="d-flex flex-row flex-grow-1">
										<h5>Filter Clients</h5>
										<button className="ms-auto btn btn-primary btn-sm" onClick={() => setHasAdvancedOptions((prev) => !prev)}>{hasAdvancedOptions ? "Hide" : "Show"}</button>
									</div>
								</Col>
							</Row>
							<Collapse in={hasAdvancedOptions}>
								<Row>
									<Col lg={4}>
										<InputCell
											cellProps={new InputCellProps({
												tag: Schema.Client.COMPLEX_NAME,
												label: Schema.Client.COMPLEX_NAME_LABEL + " Contains",
												percentWidth: 100,
											})}
											onChange={onChange}
											values={query}
											includeLabel={true}
										/>
									</Col>
								</Row>
							</Collapse>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<TableCard
				className="arrow-list"
				tableConfig={ClientTableConfig}
				onRowClick={onRowClick}
				query={query}
				getAddedRowClasses={addSelectedRowClasses}
				refetchCounter={refetchCounter}
			/>
		</>
	);
}

export default ClientList;
