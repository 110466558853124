
const classNames = require('classnames');

function toPix(n:number) {
	return `${n}px`;
}

interface PaginationProps {
	pages?: number;
	page?: number;
	onChangePage?: any;
}

function Pagination({pages, page, onChangePage}:PaginationProps) {
	const realPage = page === undefined ? 0 : page;
	const realPages = pages === undefined ? 0 : pages;
	const maxCells = 5;
	const cells = Math.min(maxCells, realPages);
	const start =
		(realPage <= Math.floor(cells / 2))
			? 1
			: (realPages - realPage <= Math.floor(cells / 2))
				? realPages - cells + 1
				: realPage - Math.floor(cells / 2) + 1;
	let elements = [];
	elements.push(0);
	for (let i = 0; i < cells - 2; i++) {
		elements.push(start + i);
	}
	if (realPages > 1) {
		elements.push(realPages - 1);
	}
	return (
		<>
			{realPages > 1 &&
				<div className="col-auto pr-0 my-auto" style={{paddingLeft: toPix(30)}}>
					<ul className="my-0 ms-3 pagination">
						<li className={classNames({"page-item" : true, disabled: realPage === 0})}>
							<button className="page-link p-pointer-cursor" onClick={() => {onChangePage(realPage - 1)}}>
								&laquo;
							</button>
						</li>
						{elements.map((element) => {
							return (
								<li key={element} className={classNames("page-item", {"active": realPage === element})}>
									<button className="page-link p-pointer-cursor" onClick={() => {onChangePage(element)}}>
										{element + 1}
									</button>
								</li>
							)
						})}
						<li className={classNames("page-item", {disabled: realPage === realPages - 1})}>
							<button className="page-link p-pointer-cursor" onClick={() => {onChangePage(realPage + 1)}}>
								&raquo;
							</button>
						</li>
					</ul>
				</div>
			}
		</>
	);
}

export default Pagination;
