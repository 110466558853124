
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import ServerRoutes from 'serverRoutes';
import LabelCellProps from 'TableCard/cells/LabelCell';
import InputCellProps from 'TableCard/cells/InputCell';
import { ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "invoice-static-two",
	hasPagination: false,
	serverRoute: ServerRoutes.EMPLOYEES,
	schema: {},
	readOnly: false,
	canAdd: false,
	loadingBlockPosition: TableConfig.LoadingBlockPosition.BOTTOM,
	leftEdgeWidth: TableConfig.WIDE_EDGE_WIDTH,
	primaryKey: "",
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return {};
	},
	payloadTags: [
	],
	toImportFormatter: {
	},
	sortKey: "",
	sortDirection: "ASC",
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new LabelCellProps({
							tag: "",
							label: "Payments:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.PAYMENTS,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"invoice-rows__transparent-form-control": true,
							},
							readOnly: true,
						}),
					],
					[
						new LabelCellProps({
							tag: "",
							label: "Balance Owed:",
							percentWidth: 100,
							contentClasses: {
								"align-right": true,
								"p-heavy": true,
								"form-control": true,
								"invoice-rows__transparent-form-control": true,
							},
						}),
						new InputCellProps({
							tag: Schema.ServiceOrder.BALANCE,
							label: "",
							fixedWidth: 140,
							prefix: "$",
							contentClasses: {
								"align-right": true,
								"p-outline": true,
							},
							customContentClasses: (row) => ({
								"p-invoice-unpaid": row[Schema.ServiceOrder.BALANCE] !== "0.00",
								"p-invoice-paid": row[Schema.ServiceOrder.BALANCE] === "0.00",
							}),
							readOnly: true,
						}),
					],
				]),
		},
});

export default TABLE_CONFIG;
