
import React, { useState } from 'react';
import TableCard from 'TableCard/TableCard';
import 'bootstrap-daterangepicker/daterangepicker.css';
import InvoicePaymentListTableConfig from './invoicePaymentListTableConfig';
const Schema = require('portal-schema');

interface InvoicePaymentListProps {
	serviceOrderId: string;
	onSaved: any;
	onFetched: any;
}

function InvoicePaymentList({serviceOrderId, onSaved, onFetched}:InvoicePaymentListProps) {
	const [query, ] = useState({
		[Schema.InvoicePayment.SERVICE_ORDER]: serviceOrderId,
	});

	return (
		<div className="search-results bordered">
			{query[Schema.InvoiceItem.SERVICE_ORDER] &&
				<TableCard
					tableConfig={InvoicePaymentListTableConfig}
					query={query}
					onFetched={onFetched}
					onSaved={onSaved}
					cardless={true}
				/>
			}
		</div>
	);
}

export default InvoicePaymentList;
