
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import IdCellProps from 'TableCard/cells/IdCell';
import StaticCellProps from 'TableCard/cells/StaticCell';
import ServerRoutes from 'serverRoutes';
//import SelectCell from 'TableCard/cells/selectCell';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "client-list",
	title: "Select Client",
	serverRoute: ServerRoutes.SERVICE_ORDER_CLIENT_LIST,
	schema: Schema.Client,
	readOnly: true,
	canAdd: false,
	canDelete: false,
	reverse: true,
	leftEdgeWidth: TableConfig.NARROW_EDGE_WIDTH,
	primaryKey: Schema.Client.ID,
	sortKey: Schema.Client.COMPLEX_NAME,
	sortDirection: "ASC",
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Client.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new StaticCellProps({
							tag: Schema.Client.COMPLEX_NAME,
							label: Schema.Client.COMPLEX_NAME_LABEL,
							percentWidth: 75,
						}),
						new StaticCellProps({
							tag: Schema.Client.MAIN_PHONE,
							label: Schema.Client.MAIN_PHONE_LABEL,
							percentWidth: 25,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
