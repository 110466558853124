
import React, { useState, useEffect, useMemo } from 'react';
import TableCard from 'TableCard';
import StatusBlock from 'views/components/StatusBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import DateRangeCellProps, { DateRangeCell } from 'TableCard/cells/DateRangeCell';
import LogTableField from './logTableConfig';
import 'bootstrap-daterangepicker/daterangepicker.css';
import useFetch, { FetchedSelectOptions, FetchOptions, FetchMode } from 'hooks/useFetch';
import ServerRoutes from 'serverRoutes';
import { importSelectOptions } from 'hooks/useFieldManager'

const Schema = require('portal-schema');

function Log({getDescription}:{getDescription:any}) {
	const [setFetchOptions] = useFetch(responseCallback, pendingCallback, waitingCallback, errorMessageCallback, FetchMode.PENDING);
	const [pending, setPending] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [fetchedData, setFetchedData] = useState({});
	const [query, setQuery] = useState({
		[Schema.Log.OPERATION]: "",
		[Schema.Log.NOTES]: "",
		[Schema.Log.CREATED]: "",
		[Schema.Log.AFFECTED_TABLE]: "",
		[Schema.Log.USERNAME]: "",
		[Schema.Log.AFFECTED_ID]: "",
	});
	const [errorMessage, setErrorMessage] = useState("");

	const fetchOptions =  useMemo(():FetchOptions => {
		return {
			url: ServerRoutes.LOG_INFO.getUrl(),
		}
	}, []);

	useEffect(() => {
		setFetchOptions(fetchOptions);
	}, [setFetchOptions, fetchOptions]);
	
	function onRetry(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.preventDefault();
		setFetchOptions(fetchOptions);
	}

	interface FetchedLogResponse {
		data: {
			selectOptions: FetchedSelectOptions;
		}
	}

	function responseCallback(response:FetchedLogResponse) {
		if (!response.data.selectOptions) {
			console.log('Malformed data: ', response);
			throw new Error("Malformed data returned from server.");
		}
		response.data.selectOptions = importSelectOptions(response.data.selectOptions);
		setFetchedData(response.data);
	}

	function pendingCallback(value:boolean) {
		//console.log(`pendingCallback value=${value}`)
		setPending(value);
	}

	function waitingCallback(value:boolean) {
		//console.log(`waitingCallback value=${value}`)
		setLoadingMessage(value ? "Loading..." : "");
	}

	function errorMessageCallback(value:string) {
		setErrorMessage(value);
	}

	function onChange(e:any) {
		setQuery(prevQuery => {
			return {...prevQuery, [e.target.name]: e.target.value};
		});
	}

	useEffect(() => { getDescription("Database Log")}, [getDescription])

	return (
		<>
			{!pending &&
				<Row>
					<Col lg={12}>
						<Card style={{marginBottom: "0.5rem"}}>
							<Card.Body>
								{!loadingMessage &&
									<Row>
										<Col lg={4}>
											<SelectCell
												cellProps={new SelectCellProps({
													label: Schema.Log.OPERATION_LABEL,
													tag: Schema.Log.OPERATION,
													hasAll: true,
												})}
												values={query}
												onChange={onChange}
												fetchedData={fetchedData}
												includeLabel={true}
											/>
										</Col>
										<Col lg={4}>
											<InputCell
												cellProps={new InputCellProps({
													tag: Schema.Log.NOTES,
													label: Schema.Log.NOTES_LABEL + " Contains",
													percentWidth: 100,
												})}
												onChange={onChange}
												values={query}
												includeLabel={true}
											/>
										</Col>
										<Col lg={4}>
											<DateRangeCell
												cellProps={new DateRangeCellProps({
													tag: Schema.Log.CREATED,
													label: Schema.Log.CREATED_LABEL + " Date Range",
													percentWidth: 100,
												})}
												onChange={onChange}
												values={query}
												includeLabel={true}
											/>
										</Col>
										<Col lg={4}>
											<SelectCell
												cellProps={new SelectCellProps({
													label: Schema.Log.AFFECTED_TABLE_LABEL,
													tag: Schema.Log.AFFECTED_TABLE,
													hasAll: true,
												})}
												values={query}
												onChange={onChange}
												fetchedData={fetchedData}
												includeLabel={true}
											/>
										</Col>
										<Col lg={4}>
											<InputCell
												cellProps={new InputCellProps({
													tag: Schema.Log.USERNAME,
													label: Schema.Log.USERNAME_LABEL + " Contains",
													percentWidth: 100,
												})}
												onChange={onChange}
												values={query}
												includeLabel={true}
											/>
										</Col>
										<Col lg={4}>
											<InputCell
												cellProps={new InputCellProps({
													tag: Schema.Log.AFFECTED_ID,
													label: Schema.Log.AFFECTED_ID_LABEL + " Contains",
													percentWidth: 100,
												})}
												onChange={onChange}
												values={query}
												includeLabel={true}
											/>
										</Col>
									</Row>}
								<StatusBlock id={4} errorMessage={errorMessage} infoMessage={loadingMessage} onRetry={errorMessage ? onRetry : undefined} />
							</Card.Body>
						</Card>
					</Col>
				</Row>}
			{true &&
				<TableCard
					tableConfig={LogTableField}
					query={query}
				/>}
		</>
	);
}

//!pending && !loadingMessage &&
export default Log;
