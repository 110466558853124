
import TableConfig, { Breakpoint } from 'TableCard/classes/tableConfig';
import InputCellProps from 'TableCard/cells/InputCell';
import TextareaCellprops from 'TableCard/cells/TextareaCellAutosizing';
import SelectCellProps from 'TableCard/cells/SelectCell';
import IdCellProps from 'TableCard/cells/IdCell';
import ServerRoutes from 'serverRoutes';
import { importRow, ImportRow } from 'hooks/useFieldManager';
const Schema = require('portal-schema')

const TABLE_CONFIG = new TableConfig({
	tag: "subcategories",
	title: "Subcategories",
	serverRoute: ServerRoutes.SUBCATEGORIES,
	schema: Schema.Subcategory,
	readOnly: false,
	canAdd: true,
	primaryKey: Schema.Subcategory.ID,
	getDefaultImportRow: (query?:ImportRow):ImportRow => {
		return importRow({
			[Schema.Subcategory.ID]: null,
			[Schema.Subcategory.NAME]: null,
			[Schema.Subcategory.CATEGORY]: null,
			[Schema.Subcategory.ICON]: null,
			[Schema.Subcategory.ATTRIBUTION]: null,
		}, TABLE_CONFIG.toImportFormatter);
	},
	payloadTags: [
		Schema.Subcategory.ID,
		Schema.Subcategory.NAME,
		Schema.Subcategory.CATEGORY,
		Schema.Subcategory.ICON,
		Schema.Subcategory.ATTRIBUTION,
	],
	sortKey: Schema.Subcategory.NAME,
	sortDirection: "ASC",
	tableRowMultiMode: TableConfig.TableRowMultiMode.STATIC,
	breakpoints:
		{
			"xs": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Subcategory.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.Subcategory.NAME,
							label: Schema.Subcategory.NAME_LABEL,
							required: true,
							percentWidth: 100,
							image: Schema.Subcategory.ICON,
						}),
					],
					[
						new SelectCellProps({
							tag: Schema.Subcategory.CATEGORY,
							label: Schema.Subcategory.CATEGORY_LABEL,
							required: true,
							percentWidth: 50,
						}),
						new InputCellProps({
							tag: Schema.Subcategory.ICON,
							label: Schema.Subcategory.ICON_LABEL,
							percentWidth: 50,
						}),
					],
					[
						new TextareaCellprops({
							tag: Schema.Subcategory.ATTRIBUTION,
							label: Schema.Subcategory.ATTRIBUTION_LABEL,
							percentWidth: 100,
						}),
					],
				]),
			"md": new Breakpoint(
				[
					[
						new IdCellProps({
							tag: Schema.Subcategory.ID,
							fixedWidth: TableConfig.WIDE_ID_WIDTH,
						}),
						new InputCellProps({
							tag: Schema.Subcategory.NAME,
							label: Schema.Subcategory.NAME_LABEL,
							required: true,
							percentWidth: 50,
							image: Schema.Subcategory.ICON,
						}),
						new SelectCellProps({
							tag: Schema.Subcategory.CATEGORY,
							label: Schema.Subcategory.CATEGORY_LABEL,
							required: true,
							percentWidth: 25,
						}),
						new InputCellProps({
							tag: Schema.Subcategory.ICON,
							label: Schema.Subcategory.ICON_LABEL,
							percentWidth: 25,
						}),
					],
					[
						new TextareaCellprops({
							tag: Schema.Subcategory.ATTRIBUTION,
							label: Schema.Subcategory.ATTRIBUTION_LABEL,
							percentWidth: 100,
						}),
					],
				]),
		}
});

export default TABLE_CONFIG;
