
import React, { useState, useEffect } from 'react';
import TableCard from 'TableCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputCellProps, { InputCell } from 'TableCard/cells/InputCell';
import SelectCellProps, { SelectCell } from 'TableCard/cells/SelectCell';
import DateRangeCellProps, { DateRangeCell } from 'TableCard/cells/DateRangeCell';
import LoginLogTableConfig from './loginLogTableConfig';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { importSelectOptions } from 'hooks/useFieldManager'

const Schema = require('portal-schema')

function LoginLog({getDescription}:{getDescription:any}) {
	const [query, setQuery] = useState({
		[Schema.LoginLog.USERNAME]: "",
		[Schema.LoginLog.STATUS]: "",
		[Schema.LoginLog.CREATED]: "",
	});
	const selectOptions = importSelectOptions({
		[Schema.LoginLog.STATUS]: [
			{tag: 0, value: "Success"},
			{tag: 1, value: "Invalid User"},
			{tag: 2, value: "Invalid Password"},
			{tag: 3, value: "Disabled Employee"},
			{tag: 4, value: "Too Many Attempts"},
			{tag: 5, value: "IP Blocked"},
		]
	});

	function onChange(e:any) {
		setQuery(prevQuery => {
			return {...prevQuery, [e.target.name]: e.target.value};
		});
	}

	useEffect(() => { getDescription("Login Log")}, [getDescription])

	return (
		<>
			<Row>
				<Col lg={12}>
					<Card style={{marginBottom: "0.5rem"}}>
						<Card.Body>
							<Row>
								<Col lg={4}>
									<SelectCell
										cellProps={new SelectCellProps({
											label: Schema.LoginLog.STATUS_LABEL,
											tag: Schema.LoginLog.STATUS,
											selectOptions: selectOptions[Schema.LoginLog.STATUS],
											hasAll: true,
										})}
										values={query}
										onChange={onChange}
										includeLabel={true}
									/>
								</Col>
								<Col lg={4}>
									<InputCell
										cellProps={new InputCellProps({
											tag: Schema.LoginLog.USERNAME,
											label: Schema.LoginLog.USERNAME_LABEL + " Contains",
											percentWidth: 100,
										})}
										onChange={onChange}
										values={query}
										includeLabel={true}
									/>
								</Col>
								<Col lg={4}>
									<DateRangeCell
										cellProps={new DateRangeCellProps({
											tag: Schema.Log.CREATED,
											label: Schema.Log.CREATED_LABEL + " Date Range",
											percentWidth: 100,
										})}
										onChange={onChange}
										values={query}
										includeLabel={true}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<TableCard
				tableConfig={LoginLogTableConfig}
				query={query}
			/>
		</>
	);
}

export default LoginLog;
