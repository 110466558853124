
import InputGroup from 'react-bootstrap/InputGroup';
import ContentCellProps from 'TableCard/classes/contentCellProps';
import IContentCellProps from 'TableCard/interfaces/IContentCellProps';
import IContentCellReactProps from 'TableCard/interfaces/IContentCellReactProps';
import DateRangePicker from 'react-bootstrap-daterangepicker';
const classNames = require('classnames');

interface IDateRangeCellProps extends IContentCellProps {
}

class DateRangeCellProps extends ContentCellProps implements IDateRangeCellProps {
	constructor(options:IDateRangeCellProps) {
		super(options);
		this.component = DateRangeCell;
	}
}

interface IDateRangeCellReactProps extends IContentCellReactProps<IDateRangeCellProps> {
}

export function DateRangeCell({cellProps, values, onChange, includeLabel}:IDateRangeCellReactProps) {
	function handleApply(event:any, picker:any) {
		const value = picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY');
		picker.element.val(value);
		onChange({target: {name: cellProps.tag, value: value}})
	};

	function handleCancel(event:any, picker:any) {
		picker.element.val('');
		onChange({target: {name: cellProps.tag, value: ""}})
	};

	return (
		<div
			className={classNames(
				"opaque",
				"cell-container",
				cellProps.cellContainerClasses,
			)}
			style={cellProps.styles}
		>
			<div className="w-100">
				{cellProps.label && includeLabel &&
					<p>{cellProps.label + ':'}</p>}
				<InputGroup>
					<DateRangePicker
						initialSettings={{
							autoUpdateInput: false,
							locale: {
								cancelLabel: 'Clear',
							},
						}}
						onApply={handleApply}
						onCancel={handleCancel}
					>
						<input type="text" className="form-control col-4" defaultValue="" />
					</DateRangePicker>
				</InputGroup>
			</div>
		</div>
	);
}

export default DateRangeCellProps;
