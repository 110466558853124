
import React, { useEffect } from 'react';

function Root({getDescription}:{getDescription:any}) {
	useEffect(() => { getDescription("AYCH Electronics")}, [getDescription])
	return (
		<div className="image-box">
			<img alt="" className="company-image" src="/images/aych.png" />
		</div>
	);
}

export default Root;
